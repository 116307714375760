import React, { useState, useRef } from 'react';
import styles from './SearchBar.module.css';
import iconLeft from '../../asset/1.png';
import iconRight from '../../asset/2.png';

const SearchBar = ({ stores, onSearch }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const searchInputRef = useRef(null);

  const fetchSuggestions = (input) => {
    if (input.length >= 3) {  // Controlla che ci siano almeno 3 lettere
      const filteredStores = stores.filter(store => 
        store.name.toLowerCase().includes(input.toLowerCase()) || 
        store.address.toLowerCase().includes(input.toLowerCase())
      );
      setSuggestions(filteredStores.slice(0, 4)); // Limita i risultati ai primi 4
    } else {
      setSuggestions([]);
    }
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    fetchSuggestions(event.target.value);
  };

  const handleSelectSuggestion = (suggestion) => {
    const location = {
      lat: suggestion.latitude,
      lng: suggestion.longitude,
    };
    onSearch(location);
    setQuery('');
    setSuggestions([]);
  };

  const clearInput = () => {
    setQuery('');
    setSuggestions([]);
    searchInputRef.current.value = ''; // Assicurati che l'input sia effettivamente cancellato
  };

  return (
    <div className={styles.searchBarContainer}>
      <form onSubmit={(e) => e.preventDefault()} className={styles.searchForm}>
        <img
          src={iconRight}
          alt="Clear Icon"
          className={styles.iconRight}
          onClick={clearInput}
        />
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search..."
          ref={searchInputRef}
          className={styles.searchInput}
        />
        <img src={iconLeft} alt="Search Icon" className={styles.iconLeft} />
      </form>
      {query.length >= 3 && (
        <ul className={styles.suggestionsList}>
          {suggestions.length > 0 ? (
            suggestions.map((suggestion) => (
              <li
                key={suggestion.id}
                className={styles.suggestionItem}
                onClick={() => handleSelectSuggestion(suggestion)}
              >
                {suggestion.name} <p className={styles.suggestionAdrress}>{suggestion.address.replace(/^"|"$/g, '')}</p>
              </li>
            ))
          ) : (
            <li className={styles.suggestionItem}>No results for this search</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
