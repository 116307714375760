import React from 'react';
import styles from './CardContainerTwoC.module.css';

function CardContainerFull({ children, margin }) {
    return (
        <div className={styles["card-container-full"]} style={{ marginTop: margin ? margin : '40%' }}>
            {children}
        </div>
    );
}

export default CardContainerFull;