import {firestore} from 'firebase/app'

class Brand {
    constructor(doc, id){
            this.id = id
           this.data_consumption = doc.data_consumption;
           this.descrizione = doc.descrizione;
           this.descrizione_en = doc.descrizione_en;
           this.listaRefsLinea = doc.listaRefsLinea;
           this.nome_brand = doc.nome_brand;
           this.url_logo = doc.url_logo;
           this.web_url = doc.web_url;  
           this.hdr_environment = doc.hdr_environment;
           this.take_pic_template = doc.take_pic_template
           this.brandContent = null;
           this.no_background_logo = doc.no_background_logo;
           this.cover_image= doc.cover_image
    }
    toString(){
        return `nome Brand ${this.nome_brand}`;    }
  }
  
  //FIrestore Data Converter
 export const brandConverter ={
    toFirestore: (brand) => {
        return {
            
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Brand(data, snapshot.id);
    }
  };


  