import React from 'react';
import styles from './CardContainerTwoC.module.css';

function CardContainerTwoC({ children , margin}) {
    return (
        <div className={styles["card-container"]} style={{marginTop: margin ? margin : '40%'}}>
           {children}
        </div>
    );
}

export default CardContainerTwoC;

