import React, { useEffect, useRef } from 'react';
import CardContainerTwoCFull from '../container/CardContainerTwoCFull';
import MirrorTittle from './MirrorHeader';
import styles from './SotreLocatore.module.css';
import { useTranslation } from 'react-i18next';
import gsap from 'gsap';
import CardContainerBig from './CardFullWidth';
import SearchBar from './SearchBar';
import { stores2 } from '../../data/Mock/StoreMock';

function StoreLocatorPage({ selectedBrand, translate, listOfGlasses, handleCardClick }) {
  const { t } = useTranslation();
  const mirrorTitleRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      mirrorTitleRef.current,
      { x: 600, rotationY: -90, z: 500 },
      { x: 0, rotationY: 0, z: 0, duration: 1, ease: 'power3.out' }
    );
  }, []);

  const handleSearch = (location) => {
    console.log("SEARCH")
    if (mapRef.current) {
      console.log("SEARCH 2")

      // Controlla che lat e lng siano numeri validi
      if (typeof location.lat === 'number' && typeof location.lng === 'number') {
        mapRef.current.panTo(new window.google.maps.LatLng(location.lat, location.lng));
        mapRef.current.setZoom(16);
      } else {
        console.error("Latitudine e longitudine non valide:", location);
      }
    }
  };

  return (
    <>
      <div className={styles['store-locator-index']}>
        <div style={{ marginTop: '5%', perspective: '1874px' }}>
          <MirrorTittle ref={mirrorTitleRef} title={'Find Stores Near you'} subtitle={false} skip={false} />
          <CardContainerTwoCFull margin={10}>
            <SearchBar stores={stores2} onSearch={handleSearch} />
            <CardContainerBig mapRef={mapRef} />
          </CardContainerTwoCFull>
        </div>
      </div>
    </>
  );
}

export default StoreLocatorPage;

