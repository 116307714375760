import { FaceMesh } from "@mediapipe/face_mesh";

const solutionOptions = {
  selfieMode: false,
  // modelComplexity: 1,
  enableFaceGeometry: true,
  smoothLandmarks: true,
  maxNumFaces: 1,
  refineLandmarks: false,
  // minDetectionConfidence: 0.5,
  // minTrackingConfidence: 0.8,
};

class FaceMeshSingleton {
  constructor() {
    this.onLoadCallbacks = [];
    this.isInitialized = false;  // Aggiungi un flag per tenere traccia dello stato di inizializzazione

    if (!FaceMeshSingleton.instance) {
      this.faceMesh = new FaceMesh({
        locateFile: (file) => `./mediapipe/face_mesh/${file}`
      });
      this.faceMesh.setOptions(solutionOptions);

      this.faceMesh.initialize().then(() => {
        // console.log("FaceMesh initialized");
        this.isInitialized = true;  // Imposta il flag su true quando l'inizializzazione è completa
        this.onLoadCallbacks.forEach(callback => callback(true));
      });

      FaceMeshSingleton.instance = this;
    }

    return FaceMeshSingleton.instance;
  }

  getFaceMesh() {
    return this.faceMesh;
  }

  onLoad(callback) {
    if (this.isInitialized) {
      callback(true);  // Attiva immediatamente il callback se FaceMesh è già stato inizializzato
    } else {
      this.onLoadCallbacks.push(callback);  // Altrimenti, aggiungi il callback all'array
    }
  }
}

const instance = new FaceMeshSingleton();
export default instance;

