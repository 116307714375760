import { useEffect, useState } from "react";

function PageNotFound() {

  return (
    <div>
      404
    </div>
  );
}

export default PageNotFound;
