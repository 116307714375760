import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // Carica le traduzioni tramite HTTP
  .use(LanguageDetector) // Rileva la lingua del browser
  .use(initReactI18next) // Passa i18n a react-i18next
  .init({
    fallbackLng: "en", // Lingua di fallback
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },      
  });

export default i18n;
