import React, { useContext, useEffect, useState, forwardRef } from 'react';
import styles from './MirrorGlassesCard.module.css';
import { CatalogDataContext } from '../../data/CatalogueDataContext';
import { ImageCacheContext } from '../../data/ImageCacheContext';

const MirrorGlassesCardHorizontal = forwardRef(({ glasses, onCardClick, isActive }, ref) => {
    const { getCatalogById } = useContext(CatalogDataContext);
    const { cache, addToCache } = useContext(ImageCacheContext);

    const catalog = getCatalogById(glasses.brandId);
    const brandLogo = catalog ? catalog.no_background_logo : 'Marca Sconosciuta';

    const [listOfVariant, setListOfVariant] = useState([]);
    const [coverUrl, setCoverUrl] = useState([]);
    const [listOfColors, setColorsList] = useState([]);

    useEffect(() => {
        // Popola le varianti e le immagini
        const variantList = glasses.listaTaglieOBJ.flatMap((size) => size.listaModelli);
        setListOfVariant(variantList);
        setCoverUrl(variantList[0]?.image3D || '');

        const colors = variantList.map((variant) => ({
            first: variant.esaColorFramePrimary,
            second: variant.esaColorLensesPrimary,
        }));
        setColorsList(colors);
    }, [glasses]);

    const handleClick = (glasses) => {
        // Chiama `onCardClick` solo se la card è attiva
        if (isActive) {
            // console.log("Glaasses Selected", glasses)
            onCardClick(glasses);
        }
    };

    return (
        <div
            className={`${styles['mirror-horizontal-card']} ${isActive ? styles['active-card'] : ''}`}
            ref={ref}
            onClick={()=>handleClick(glasses)}
        >
            <div className={styles['card-glasses-name']}>{glasses.nome_modello}</div>
            <div className={styles['container-card-glasses-img']}>
                <img
                    className={styles['card-glasses-img']}
                    src={coverUrl}
                    alt={glasses.nome_modello}
                />
            </div>
            <div className={styles['brandcontainerLogo']}>
                <img src={brandLogo} className={styles['brandimage']} />
            </div>
            <div className={styles['colorList']}>
                {listOfColors.slice(0, 4).map((color, index) => (
                    <div key={index} className={styles['box']}>
                        <div
                            className={styles['rectangle']}
                            style={{
                                backgroundImage: `linear-gradient(190deg, ${color.first} 0%, ${color.second} 100%)`,
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
});

export default MirrorGlassesCardHorizontal;
