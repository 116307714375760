import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState} from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
// import { BrowserRouter, Redirect, Route, Routes } from "react-router-dom";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import { whitelist } from './Utils/constant';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { isMobile } from "react-device-detect";
import './Utils/LanguageUtils/i18n';

import RouterQuery from './pages/RouterQuery'
import { CatalogDataContext, CatalogDataProvider } from './data/CatalogueDataContext';
import MirrorVto from './pages/MirrorVto';
import { ImageCacheProvider } from './data/ImageCacheContext';
import { StatusBar } from 'react-native-web';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Helmet } from 'react-helmet';
import { isTablet } from 'react-device-detect';

function App() {

  

  return (
    <div >
      <StatusBar hidden={true} />
      <LoadScript
      googleMapsApiKey="AIzaSyA_N-8PiWyqCKOCZJI09zU2q7GrYdcqxaQ"
      libraries={['places']}
      loadingElement={<div>Loading...</div>}
    >      

      {/* <CatalogDataProvider c={["qmDTd8ciaSs31akT3Eqq", "rBf3geYISXtkue0WGgFT", "UPgshXfGThBLfqZyrcxZ", "USamwu7Ywd2oNAU5eL50", "Nqyd5EKyrBb3fufpJyKS","rxnBLnp736gqjFBNLfdS"]}> */}
      <CatalogDataProvider c={["qmDTd8ciaSs31akT3Eqq", ]}>
      {isTablet && (
        <Helmet>
          <meta name="viewport" content="width=1365" />
        </Helmet>
      )}
      <ImageCacheProvider>

      {/* <BrowserRouter basename={`/`}> */}
      <Router basename="/">

        <Routes>
        <Route path="/" element={<RouterQuery></RouterQuery>}/>
        <Route path="/vto" element={<MirrorVto></MirrorVto>}/> 
        </Routes>
      </Router>
           
      {/* </BrowserRouter> */}
      </ImageCacheProvider>

      </CatalogDataProvider>
      </LoadScript>
    </div>
  );
}

export default App;
