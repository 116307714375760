import React from 'react';
import styles from './BrandHeader.module.css'
import IMAGES from '../../data/images';

function BrandHeader({ title, subtitle, text}) {
    return (
        <div className={styles['header-container']}>
            <div className={styles.title}>{title}</div>
            <div >
                <p className={styles.subtitle}>{text}</p>
            </div>

            

        </div>

    );
}

export default BrandHeader;
