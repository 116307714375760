import React , {forwardRef} from 'react';
import style from './CircularButton.module.css'; // Assicurati di importare il tuo file CSS
import IMAGES from '../../data/images';
import { useTranslation } from 'react-i18next';

const CircularButton = forwardRef(({ variation, handleClick, icon, background }, ref) => {
  const { t } = useTranslation();

  const getStyle = () => {
    switch (variation) {
      case 1:
        return style.variation1;
      case 2:
        return style.variation2;
      case 3:
        return style.variation3;
      default:
        return style.variation1;
    }
  }
  const getTextStyle = () => {
    switch (variation) {
      case 1:
        return style.textV1;
      case 2:
        return style.textV2;
      case 3:
        return style.textV3;
      default:
        return style.textV1;
    }
  }


  const getIcon = () => {
    switch (variation) {
      case 1:
        return IMAGES.dice;
      case 2:
        return IMAGES.tryon;
      case 3:
        return IMAGES.dis_more;
      default:
        return icon;
    }
  }


  const getTitle = ()=>{
    switch (variation) {
      case 1:
        return t("roll_the_dice");
      case 2:
        return t("try_it_on");
      case 3:
        return t("discover_more");
      default:
        return icon;
    }
  }

  return (
    <div className={style['container']}>
      <div className={`${getStyle()} ${style["circular_container"]}`} onClick={handleClick} ref={ref} >
        <img src={getIcon()} alt={`${getIcon()} `} className={style["btnImage"]} />
        <div className={`${style["title"]} ${getTextStyle()}`}>{getTitle()}</div>

      </div>

    </div>
  );
});

export default CircularButton;