import React , {useRef, useEffect} from 'react';
import i18n from 'i18next';
import { CSSTransition , TransitionGroup} from 'react-transition-group';
import "../../Utils/Transition.css"
import ButtonWithIcon from './ButtonWithIcon';
import FlexContainer from '../container/FlexContainerVertical';
import IMAGES from '../../data/images';
import gsap from 'gsap';
import MirrorTittle from './MirrorHeader';

function RootSelector({onSelectLanguage}) {
    
  const mirrorTitleRef = useRef(null);
  const buttonRef = useRef([]);


    useEffect(() => {
      // console.log("IN ROOT SELECTOR")
      gsap.fromTo(
        mirrorTitleRef.current,
        { x: 600, rotationY: -90, z: 500 },
        { x: 0, rotationY: 0, z: 0, duration: 1, ease: "power3.out" }
      );
        // Animazione di Ingresso
        buttonRef.current.forEach((el, index) => {
          gsap.fromTo(el, 
            { x: -600, z: 500, rotationY: 140 }, 
            { x: 0, z: 0, rotationY: 0, duration: 0.5, delay: 0.1 * index, ease: "power3.out" }
          );
        });
    
    }, []); // Dipendenza da startSelectionLanguage
    
    function setOnSelectedLanguage(language){
      
      if ( language === "onlineShop"){

      gsap.to(mirrorTitleRef.current, {
        x: -600, 
        rotationY: 90, 
        z:500 ,
      duration: 1,
      ease: "power3.out",
    });
    
      buttonRef.current.forEach((el, index) => {
        gsap.to(el, {
          x: 600, z: 500, rotationY: -140, duration: 0.5, delay: 0.1 * index, ease: "power3.in",
          onComplete: () => {
            if (index === buttonRef.current.length - 1) { // Controlla l'ultimo elemento animato
              onSelectLanguage(language);
            }
          }
        });
      });

      }else{
        onSelectLanguage(language);
      }
    }

    const buttons = [
      { language: 'glassesPage', label: 'try glasses', icon: IMAGES.esp_icon },
      { language: 'storeLocator', label: 'Find A Store', icon: IMAGES.eng_icon} ,
      { language: 'brandPage', label: 'discover the brand', icon: IMAGES.itl_icon },
      { language: 'onlineShop', label: 'online shop', icon: IMAGES.dut_icon} ,
    ];

    return (
   
       <>

       <FlexContainer>
        <img className='mvp-logo-homepage' src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/immagini%2Fbrand_logo%2FCarrera_watermark.png?alt=media&token=accee17d-79bd-49aa-8138-9394416f14ea"></img>
       <MirrorTittle  ref={mirrorTitleRef} title="Your Campaign Name" subtitle="Choose the icon" skip={false} />
        <div className='regular-space'></div>
       {buttons.map((button, index) => (
        <ButtonWithIcon
          ref={el => buttonRef.current[index] = el}
          key={button.language}
          language={button.language}
          label={button.label}
          onSelectLanguage={setOnSelectedLanguage}
        />
      ))}
           
            </FlexContainer>
        </>
    );
}

export default RootSelector;
