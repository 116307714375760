import React, { useState, useRef, useEffect,forwardRef } from 'react';
import styles from './CardContainerTwoC.module.css';
import gsap from 'gsap';


const CardContainerHorizontal = forwardRef(({ children, margin }, ref) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const observerRef = useRef(null);

    useEffect(() => {
        const container = ref.current;
        if (!container) return;

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = parseInt(entry.target.dataset.index, 10);
                    setActiveIndex(index);
                }
            });
        };

        observerRef.current = new IntersectionObserver(callback, {
            root: container,
            rootMargin: '0px',
            threshold: 0.5,
        });

        container.querySelectorAll('[data-index]').forEach((element) => {
            observerRef.current.observe(element);
        });

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, [children]);

    // Funzione che gestisce lo scorrimento e l'attivazione delle card
    const handleCardClick = (index, onCardClick) => {
        const container = ref.current;
        const childWidth = container.firstChild.offsetWidth + parseInt(window.getComputedStyle(container).gap, 10);
        const scrollPosition = childWidth * index - (container.offsetWidth - childWidth) / 2;

        if (index === activeIndex) {
            // Se l'indice è già attivo, chiama la funzione `onCardClick`
            // if (onCardClick) onCardClick();
            
        } else {
            // Altrimenti, sposta la card selezionata al centro

            container.scrollTo({
                left: scrollPosition,
                behavior: 'smooth',
            });

            setActiveIndex(index);
        }
    };

    return (
        <div
            className={styles['card-horizontal-container']}
            ref={ref}
            style={{ marginTop: margin ? margin : '40%' }}
        >
            {React.Children.map(children, (child, index) => (
                <div
                    data-index={index}
                    className={`${styles['card-horizontal']} ${activeIndex === index ? styles['active-card'] : ''}`}
                    onClick={() => handleCardClick(index, child.props.onCardClick)}
                >
                    {React.cloneElement(child, { isActive: activeIndex === index })}
                </div>
            ))}
        </div>
    );
});


export default CardContainerHorizontal;

