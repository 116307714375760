
export class BrandContent {
    constructor(doc, id){
            this.id = id
           this.contentList = doc.contentList;
           this.nome_brand = doc.nome_brand
    }
    toString() {
        let contentStr = this.contentList.map(content => 
            `Descrizione: ${content.descrizione}, Media: ${content.media}`
        ).join('\n');

        return `Nome Brand: ${this.nome_brand}\nContenuti:\n${contentStr}`;
    }
  }
  
  //FIrestore Data Converter
 export const brandContentConverter ={
    toFirestore: (brandContent) => {
        return {
            
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new BrandContent(data, snapshot.id);
    }
  };


export const mockBrandData1 = {
    id: "1",
    contentList: [
        { descrizione: "Are you ready to play with your look? Thanks to our Virtual Try On you can have fun trying out a wide eyewear selection.", 
        media: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2F0001-0210.mp4?alt=media&token=b7dd310d-3d69-4ba0-96d1-4172d54f1381" ,
        media: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FPOLAROID_30sec_16-9.mp4?alt=media&token=7d7dea67-8971-400f-8246-925ad13c06c5" ,
        media2: "" },

    ],
    nome_brand: "POLAROID"
};

export const mockBrandData2 = {
    id: "2",
    contentList: [
        { descrizione: "If you're on the go and want to make your style even more unique, all you have to do is download the Safilo VTO app on your smartphone, select Carrera eyewear and start searching for the perfect model for you. Explore them by scrolling down, adjust the position on your face and share the shot with friends to show off your new look.", 
        media: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCarrera_Mexico_2023_11_02_Story_CUT15sec_2.mp4?alt=media&token=50793094-485a-4bfb-8a0c-d3a1089de1f6",
    media2: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCarrera_Mexico_2023_11_02_Story_CUT15sec_4.mp4?alt=media&token=0e2b38db-008b-42eb-b4a1-4f9bd6e78d43" },
    ],
    nome_brand: "CARRERA"
};

export const mockBrandData3 = {
    id: "3",
    contentList: [
        { descrizione: "Black sunglasses for men are the go-to choice for optimum adaptability across all men's clothing styles, so if you're searching for designer eyewear that'll endure the changing trends and seasons, opt for metal-frame glasses with a black acetate frame. Lightweight titanium structures provide some of the finest comfort available, while adjustable end tips and nose pads will help you find barely there comfort from sun up to sun down.", 
        media: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FHBME_110_SU24_BOSS_Global_Campaign_Licenses_Video_Eyewear_Matteo_30s_16x9.mp4?alt=media&token=021c4ba9-8fe3-40ff-9aee-32655246764c" ,
        media2: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FHBME_110_SU24_BOSS_Global_Campaign_Licenses_Video_Eyewear_Suki_30s_16x9.mp4?alt=media&token=2e4c3344-89d8-4d43-ba31-d3c59bc9592e" 

    },
    ],
    nome_brand: "HugoBoss"
};

export const mockBrandData4 = {
    id: "4",
    contentList: [
        { descrizione: "ARShades Try-on is cutting-edge and convenient solution for trying-on glasses, the simplest, most innovative and one of the most effective on the market. Enhance your company’s image and place you at the forefront of technology and customer service. ARShades Apps are a powerful tool for fidelisation, market insight, testing new frames, and notificate new products and offers.", 
        media: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2F0001-0210.mp4?alt=media&token=b7dd310d-3d69-4ba0-96d1-4172d54f1381" },
    ],
    nome_brand: "ARShades"
};