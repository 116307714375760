import React from 'react';
import styles from './GlassesContainer.module.css';

function GlassesContainer({ children , margin}) {
    return (
        <div className={styles["glasses-container"]} >
           {children}
        </div>
    );
}

export default GlassesContainer;
