
export class GlassesContent {
    constructor(doc, id){
           this.id = id
           this.contentList = doc.contentList;
           this.specialContent = doc.specialContent;
           this.glassesName = doc.glassesName;
    }
    toString() {
        let contentStr = this.contentList.map(content => 
            `Descrizione: ${content.descrizione}, Media: ${content.media}`
        ).join('\n');

        return `GlassesName:${this.glassesName} Special Content: ${this.specialContent}\nContenuti:\n${contentStr}`;
    }
  }
  
  //FIrestore Data Converter
 export const glassesContentConverter ={
    toFirestore: (glassesContent) => {
        return {
            
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new GlassesContent(data, snapshot.id);
    }
  };



  export const mockGlassesData1 = {
    id: "1",
    contentList: [
        { descrizione: "Descrizione contenuto 1", media: "path/to/media1.jpg" },
        { descrizione: "Descrizione contenuto 2", media: "path/to/media2.mp4" }
    ],
    specialContent: {type: "render", media: ""},
    nomeGlasses: "Name"
};

export const mockGlassesData2 = {
    id: "1",
    contentList: [
        { descrizione: "Descrizione contenuto 1", media: "path/to/media1.jpg" },
        { descrizione: "Descrizione contenuto 2", media: "path/to/media2.mp4" }
    ],
    specialContent: {type: "render", media: ""},
    nomeGlasses: "Name"
};

export const mockGlassesData3 = {
    id: "1a5F62umz9Hdqr4Bfnue",
    contentList: [
        { descrizione: "Descrizione contenuto 1", media: "path/to/media1.jpg" },
        { descrizione: "Descrizione contenuto 2", media: "path/to/media2.mp4" }
    ],
    specialContent: {type: "render", media: ""},
    nomeGlasses: "Name"
};