import React, { useState, useEffect } from 'react';
import { LoadScript, GoogleMap, InfoWindow } from '@react-google-maps/api';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import iconMaps from '../../asset/safilo_map_pin.png';
import { stores2 } from '../../data/Mock/StoreMock';
import { customMapStyle } from './customStyleMaps';
import styles from './CardButton.module.css';

const CardContainerBig = ({ mapRef }) => {
  const [center, setCenter] = useState({ lat: 50.0, lng: 20.0 });
  const [selectedStore, setSelectedStore] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);

  const mapContainerStyle = {
    width: '100%',
    height: '80vh',
  };

  const mapOptions = {
    styles: customMapStyle,
    disableDefaultUI: true,
  };

  const handleApiLoaded = (map) => {
    mapRef.current = map;

    const markers = stores2.map((store) => {
      const marker = new window.google.maps.Marker({
        position: { lat: store.latitude, lng: store.longitude },
        title: store.name,
        icon: iconMaps,
      });

      marker.addListener('click', () => {
        setSelectedStore(store);
        setActiveMarker(marker);
        setCenter({ lat: store.latitude, lng: store.longitude });
      });

      return marker;
    });

    new MarkerClusterer(mapRef.current, markers, {
      imagePath: 'https://www.safilo.arshades.it/img/m',
      styles: [
        {
          textColor: 'white',
          fontWeight: 'bold',
          url: 'https://www.safilo.arshades.it/img/m/b1.png',
          height: 96,
          width: 96,
          textSize: 28,
          anchorText: [27, 0],
        },
        {
          textColor: 'white',
          url: 'https://www.safilo.arshades.it/img/m/b3.png',
          height: 96,
          width: 96,
          textSize: 28,
          anchorText: [27, 0],
          fontWeight: 'bold',

        },
        {
          textColor: 'white',
          url: 'https://www.safilo.arshades.it/img/m/b3.png',
          height: 96,
          width: 96,
          textSize: 28,
          anchorText: [27, 0],
          fontWeight: 'bold',

        },
      ],
    });

    mapRef.current.addListener('click', (event) => {
      event.stop();
    });
  };

  //Ofset for Card Info
  const infoWindowOptions = {
    pixelOffset: new window.google.maps.Size(0, -64),
  };

  return (
 
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={5}
        options={mapOptions}
        onLoad={handleApiLoaded}
      >
        {selectedStore && activeMarker && (
          <InfoWindow
            position={activeMarker.getPosition()}
            options={infoWindowOptions}
            onCloseClick={() => {
              setSelectedStore(null);
              setActiveMarker(null);
            }}
          >
            <div className={styles['info-window']}>
              <div className={styles['info-window-content']}>
                <h2>{selectedStore.name}</h2>
                <p>{selectedStore.address}</p>
                <p>{selectedStore.description}</p>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
  );
};

export default CardContainerBig;
