import React, { useEffect, useState } from "react";
 import styles from "./SingleVto.module.css"
// import styles from "../component/SingleVtoSafilo.module.css"

import {faTrash} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function MiniaturePhoto(props) {

return(
    <div className={props.isSingleView ? styles["miniature-photo-div-single"]: styles["miniature-photo-div"]} >
        <div className={styles["trash-container"]} onClick={props.trash}>   <FontAwesomeIcon icon={faTrash} className={styles["icon-trash-photo"]} />
 </div>
         <img className={styles["photo-modal"]} src={props.image} onClick={props.openModalAction}/>

    </div>

)
}
export default MiniaturePhoto