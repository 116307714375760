import React, { forwardRef } from 'react';
import styles from './CardButton.module.css';

const CardButton = forwardRef(({ onClick, children, backgroundCover }, ref) => {
    const backgroundStyle = {
        backgroundImage: `url(${backgroundCover})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    };

    return (
        <div className={styles['card-container']} ref={ref}>
            <div className={styles["card-button"]} onClick={onClick}
                style={backgroundCover ? backgroundStyle : {}}
            // Aggiunta del ref qui
            >
            </div>
            <div className={styles["card-content"]}>
                {children}
            </div>
        </div>
    );
});

export default CardButton;