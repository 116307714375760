import React, { createContext, useState } from 'react';

export const ImageCacheContext = createContext();

export const ImageCacheProvider = ({ children }) => {
    const [cache, setCache] = useState([]);

    const addToCache = (imageUrl) => {
        if (!cache.includes(imageUrl)) {
            setCache([...cache, imageUrl]);
        }
    };

    return (
        <ImageCacheContext.Provider value={{ cache, addToCache }}>
            {children}
        </ImageCacheContext.Provider>
    );
};
