import React , {useRef, useEffect} from 'react';
import i18n from 'i18next';
import { CSSTransition , TransitionGroup} from 'react-transition-group';
import "../../Utils/Transition.css"
import ButtonWithIcon from './ButtonWithIcon';
import FlexContainer from '../container/FlexContainerVertical';
import IMAGES from '../../data/images';
import gsap from 'gsap';
import MirrorTittle from './MirrorHeader';

function LanguageSelector({onSelectLanguage}) {
    
  const mirrorTitleRef = useRef(null);
  const buttonRef = useRef([]);


    useEffect(() => {

      gsap.fromTo(
        mirrorTitleRef.current,
        { x: 600, rotationY: -90, z: 500 },
        { x: 0, rotationY: 0, z: 0, duration: 1, ease: "power3.out" }
      );
        // Animazione di Ingresso
        buttonRef.current.forEach((el, index) => {
          gsap.fromTo(el, 
            { x: -600, z: 500, rotationY: 140 }, 
            { x: 0, z: 0, rotationY: 0, duration: 0.5, delay: 0.1 * index, ease: "power3.out" }
          );
        });
    
    }, []); // Dipendenza da startSelectionLanguage
    
    function setOnSelectedLanguage(language){

      gsap.to(mirrorTitleRef.current, {
        x: -600, 
        rotationY: 90, 
        z:500 ,
      duration: 1,
      ease: "power3.out",
    });
    
      buttonRef.current.forEach((el, index) => {
        gsap.to(el, {
          x: 600, z: 500, rotationY: -140, duration: 0.5, delay: 0.1 * index, ease: "power3.in",
          onComplete: () => {
            if (index === buttonRef.current.length - 1) { // Controlla l'ultimo elemento animato
              onSelectLanguage(language);
            }
          }
        });
      });
    }

    const buttons = [
      { language: 'it', label: 'Italiano', icon: IMAGES.itl_icon },
      { language: 'es', label: 'Español', icon: IMAGES.esp_icon },
      { language: 'en', label: 'English', icon: IMAGES.eng_icon} ,
      { language: 'de', label: 'Deutsch', icon: IMAGES.dut_icon} ,
      { language: 'fr', label: 'Français', icon: IMAGES.fr_icon }
    ];

    return (
   
       <>

       <FlexContainer>
       <MirrorTittle  ref={mirrorTitleRef} title="Select a Language" subtitle="Choose the icon" skip={false} />

       {buttons.map((button, index) => (
        <ButtonWithIcon
          ref={el => buttonRef.current[index] = el}
          key={button.language}
          language={button.language}
          label={button.label}
          onSelectLanguage={setOnSelectedLanguage}
          icon={button.icon}
        />
      ))}
           
            </FlexContainer>
        </>
    );
}

export default LanguageSelector;
