import React from 'react';
 import styles from "./SingleVto.module.css"
// import styles from "../component/SingleVtoSafilo.module.css"

import { faCamera , faShareNodes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function SaveFrameButton({ onClick }) {
  return (
    <div className={styles["photo-container"]}>
    <button className={styles["take-photo"]} onClick={onClick}>
              <FontAwesomeIcon icon={faCamera} className={styles["icon-take-photo"]} />
    </button>
    </div>
  );
}

