class Linea {
    constructor(doc, id){
        this.id = id
           this.nome_linea = doc.nome_linea;
           this.brand = doc.brand;
           this.descrizione = doc.descrizione;
           this.descrizione_en = doc.descrizione_en;
           this.listaRefsOcchiale = doc.listaRefsOcchiale;
           this.url_image = doc.url_image;
           this.listaOcchiali = [];
    }
    toString(){
        return `nome linea: ${this.nome_linea}`;    }
  }
  
  //FIrestore Data Converter
 export const lineaConverter ={
    toFirestore: (linea) => {
        return {
            
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);

        return new Linea(data, snapshot.id);
    }
  };

