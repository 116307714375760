import { useContext, useEffect, useState, useRef } from "react";
import CardContainerTwoC from '../container/CardContainerTwoC';
import CardButton from './CardButton';
import { CatalogDataContext } from "../../data/CatalogueDataContext";
import MirrorTittle from "./MirrorHeader";
import style from './CardButton.module.css'
import { useTranslation } from 'react-i18next';

function BrandsPages({ allBrands, handleSelectedBrand, translate }) {
    const { t } = useTranslation();

       // Filtra i brand in base alla presenza nella lista degli occhiali filtrati
    // const displayedBrands = allBrands.filter(brand => 
    //     filteredGlassesList.some(glasses => glasses.brandId === brand.id)
    // );

    return (
        <>
        <MirrorTittle title={t("looking_for_a_special_brand")} subtitle={t("choose_the_icon")} skip={false} />
        <CardContainerTwoC margin={"0px"}>
        {
  allBrands
    .filter(brand => brand.id !== 'USamwu7Ywd2oNAU5eL50' && brand.id !== 'Nqyd5EKyrBb3fufpJyKS')
    .map((brand, index) => (
      <CardButton key={index} onClick={() => handleSelectedBrand(brand)} backgroundCover={brand.cover_image}>
        <div className={style.bottomContainer}>{brand.nome_brand}</div>
      </CardButton>
    ))
}

         </CardContainerTwoC>
        </>
    );
}

export default BrandsPages;