import React , {useRef, useEffect}from "react";
import CardContainerTwoC from "../container/CardContainerTwoC";
import CardButton from "./CardButton";
import style from "./CardButton.module.css";
import IMAGES from "../../data/images";
import MirrorTittle from "./MirrorHeader";
import gsap from "gsap";

function FilterByGender({ handleFilterByGender, translate ,handleSkip
}) {
  const mirrorTitleRef = useRef(null);
  const cardButtonRefs = useRef([]);

  useEffect(() => {
    // Animazione di ingresso
    gsap.fromTo(
      mirrorTitleRef.current,
      { x: 600, rotationY: -90, z:500 },
      { x: 0, rotationY: 0,z:0, duration: 1, ease: "power3.out" }
    );

    cardButtonRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { x: index % 2 === 0 ? -600 : 600, rotationY: index % 2 === 0 ? 140 : -140 , z: 500 },
        {
            z:0,
          x: 0,
          rotationY: 0,
          duration: 1,
          delay: 0.1 * index,
          ease: "power3.out",
        }
      );
    });

    // ... (aggiungi qui la logica per le animazioni di uscita se necessario)
  }, []);

  function animateOutAndFilter(gender) {
    // Animazione di uscita
    gsap.to(mirrorTitleRef.current, {
        x: -600, 
        rotationY: 90, 
        z:500 ,
      duration: 1,
      ease: "power3.out",
    });
  
    cardButtonRefs.current.forEach((el, index) => {
      gsap.to(el, {
        // Se l'indice è pari, esce verso destra, altrimenti verso sinistra
        x: index % 2 === 0 ? 800 : -800,
        rotationY: index % 2 === 0 ? -140 : 140,
        z: 500,
        duration: 0.5,
        delay: 0.1 * index,
        ease: "power3.in",
        onComplete: () => {
          if (index === cardButtonRefs.current.length - 1) {
            handleFilterByGender(gender);
          }
        },
      });
    });
  }
  
  function animateAndSkip() {
    // Animazione di uscita
    gsap.to(mirrorTitleRef.current, {
        x: -600, 
        rotationY: 90, 
        z:500 ,
      duration: 1,
      ease: "power3.out",
    });
  
    cardButtonRefs.current.forEach((el, index) => {
      gsap.to(el, {
        // Se l'indice è pari, esce verso destra, altrimenti verso sinistra
        x: index % 2 === 0 ? 800 : -800,
        rotationY: index % 2 === 0 ? -140 : 140,
        z: 500,
        duration: 0.5,
        delay: 0.1 * index,
        ease: "power3.in",
        onComplete: () => {
          if (index === cardButtonRefs.current.length - 1) {
            handleSkip();
          }
        },
      });
    });
  }

  const genderToImageMap = {
    Uomo: 'male',
    Donna: 'female',
    Unisex: 'unisex',
    Kids: 'kids'
  };
  
  return (
    <div style={{ marginTop: "5%" , perspective: "1874px"}}>
      <MirrorTittle
        ref={mirrorTitleRef}
        title={translate("who_s_your_glasses_for")}
        subtitle={translate("choose_the_icon")}
        handleSkip={()=>animateAndSkip()}
      />
      <CardContainerTwoC margin={10}>
        {Object.keys(genderToImageMap).map((gender, index) => (
          <CardButton
            ref={(el) => cardButtonRefs.current[index] = el}
            key={gender}
            onClick={() => animateOutAndFilter(gender)}
            backgroundCover={IMAGES[genderToImageMap[gender]]}
          >
            <div className={style.bottomContainer}>{translate(gender.toLowerCase())}</div>
          </CardButton>
        ))}
      </CardContainerTwoC>
    </div>
  );
        }

export default FilterByGender;
