import React, { useRef, useEffect } from "react";
import CardContainerTwoC from "../container/CardContainerTwoC";
import CardButton from "./CardButton";
import style from "./CardButton.module.css";
import IMAGES from "../../data/images";
import MirrorTittle from "./MirrorHeader";
import gsap from "gsap";
import MirrorGlassesCard from "./MirrorGlassesCard";
import styles from '../container/CardContainerTwoC.module.css';
import { useTranslation } from 'react-i18next';
import CardContainerHorizontal from "../container/CardContainerHorizontal";
import MirrorGlassesCardHorizontal from "./MirrorGlassesCardHorizontal";

function GlassesList({ filteredGlassesList, handleSelectedGlasses, translate, type }) {
  const mirrorTitleRef = useRef(null);
  const cardButtonRefs = useRef([]);
  const { t } = useTranslation();
  const containerCard = useRef(null);

  useEffect(() => {
   
    const container = document.querySelector('.' + styles["card-container"]);
    if (container) {
      container.style.maxHeight = '950px';
    }
    gsap.fromTo(
      mirrorTitleRef.current,
      { x: 600, rotationY: -90, z:500 },
      { x: 0, rotationY: 0,z:0, duration: 1, ease: "power3.out" }
    );

    gsap.fromTo(
      containerCard.current,
      { x: 600, rotationY: -90, z:500 },
      { x: 0, rotationY: 0,z:0, duration: 1, ease: "power3.out" }
    )
      // cardButtonRefs.current.forEach((el, index) => {
      //   if(index < 8){
      //   gsap.fromTo(
      //     el,
      //       { x: index % 2 === 0 ? -600 : 600, rotationY: index % 2 === 0 ? 140 : -140, z: 500 },
      //       { z: 0, x: 0, rotationY: 0, duration: 1, delay: 0.1 * index, ease: "power3.out" }
      //   );
      //   }
      // });

      // cardButtonRefs.current.forEach((el, index) => {
      //   if (el && index === 0) {
      //     gsap.fromTo(
      //       el.current,
      //       { x: 600, rotationY: -90, z:500 },
      //       { x: 0, rotationY: 0,z:0, duration: 1, ease: "power3.out" }
      //     )
      //   }
      // });


  }, []);

  function animateOut(glasses) {
           // Animazione di uscita
        gsap.to(mirrorTitleRef.current, {
            x: -600, 
            rotationY: 90, 
            z:500 ,
          duration: 1,
          ease: "power3.out",
        });
      
        gsap.fromTo(
          containerCard.current,
          { x: 0, rotationY: 90, z:500 },
          { x: 600, rotationY: 0,z:0, duration: 1, ease: "power3.out" }
        )

        handleSelectedGlasses(glasses)

    
  }

  return (
    <>
      <MirrorTittle
        title={t("select_your_glasses")}
        subtitle={t("choose_the_icon")}
        skip={false}
        ref={mirrorTitleRef}
      />
       <CardContainerHorizontal margin={"80px"} ref={containerCard}>
        {filteredGlassesList
          .filter(glasses => glasses.listaTaglieOBJ[0]?.listaModelli[0]?.image3D) // Filtro gli occhiali con image3D non vuoto o nullo
          .map((glasses, index) => (
          <MirrorGlassesCardHorizontal
            key={index}
            glasses={glasses}
            onCardClick={animateOut}
            ref={(el) => cardButtonRefs.current[index] = el}          />
        ))}
      </CardContainerHorizontal>
</>
  );
}

export default GlassesList;
