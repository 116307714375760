import React from 'react';
import styles from '../SingleVto.module.css'; // Assicurati di avere questo file CSS con le classi appropriate

const SizeSelector = ({ sizeData, selectedSizeCode, handleSelectedSize }) => {
  return (
    <div className={styles["select_size"]}>
     
        <div className={styles["select_size_title"]}>
          GLASSES SIZE :
        </div>
   
      {sizeData.map((size, index) => (
        <div
          key={index}
          className={
            selectedSizeCode === size.codiceTaglia
              ? styles["item-dot-size"]
              : styles["item-dot-size-unselected"]
          }
          onClick={() => handleSelectedSize(size)}
        >
          {size.size}
        </div>
      ))}
    </div>
  );
};

export default SizeSelector;




















// <div className={styles["select_size"]}>
// {" "}
// {sizeData.length < 2 && (
//   <div className={styles["select_size_title"]}>
//     {" "}
//     SIZE :{" "}
//   </div>
// )}{" "}
// {sizeData.map((size, index) => {
//   return (
//     <div
//       key={index}
//       className={
//         selectedSizeCode === size.codiceTaglia
//           ? styles["item-dot-size"]
//           : styles["item-dot-size-unselected"]
//       }
//       onClick={() => handleSelectedSize(size)}
//     >
//       {size.size}
//     </div>
//   );
// })}
// </div>{" "}