class Taglia {
    constructor(doc){
        this.size = doc.size;
        this.ponte = doc.ponte;
        this.aste = doc.aste;
        this.lentiWidth = doc.lentiWidth;
        this.loadingId = doc.loadingId;
        this.lentiHeight = doc.lentiHeight;
        this.codiceTaglia = doc.codiceTaglia;
        this.frameWidth = doc.frameWidth;
        // estrai i valori delle referenze dalla proprietà `listaRefModels`
        this.listaRefModels = doc.listaRefModels.map(value => value);
        this.listaModelli = [];
    }
    toString(){
        return `size: ${this.size},
                ponte: ${this.ponte},
                aste: ${this.aste},
                lentiWidth: ${this.lentiWidth},
                loadingId: ${this.loadingId},
                lentiHeight: ${this.lentiHeight},
                codiceTaglia: ${this.codiceTaglia},
                frameWidth: ${this.frameWidth},
                listaRefModels: ${this.listaRefModels}`;
    }
}

  //FIrestore Data Converter
 export const tagliaConverter ={
    toFirestore: (taglia) => {
        return {
          
        }
    },
    fromFirestore: (snapshot, options) => {
         const data = snapshot.data(options);
        return new Taglia(data);
    }
  };


  