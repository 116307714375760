
const IMAGES = {
    eng_icon: `./asset/image/flag/en.svg`,
    fr_icon: "./asset/image/flag/fr.svg",
    esp_icon: "./asset/image/flag/es.svg",
    dut_icon: "./asset/image/flag/ge.svg",
    itl_icon: "./asset/image/flag/it.svg",
    
    sunglasses_frames_Uomo:require("../asset/type/Sunglasses_Man.png"),
    sunglasses_frames_Donna:require("../asset/type/Sanglasses_woman.png"),
    sunglasses_frames_Kids:require("../asset/type/Sunglasses_kids.png"),
    optical_frames_Kids:require("../asset/type/Optical_kids.png"),
    optical_frames_Donna:require("../asset/type/Opticals_woman.png"),
    optical_frames_Uomo:require("../asset/type/Opticals_Man.png"),
    opt___clip_on_Uomo:require("../asset/type/Clip_ons_man.png"),
    sunglasses_frames_Unisex: require("../asset/type/Sunglasses_Man.png"),
    optical_frames_Unisex: require("../asset/type/Opticals_woman.png"),

    footer: "./asset/image/footer.png",
    
    
    
    line: require("../asset/line.png"),
    //GENDER SELECTION
    male: require("../asset/gender/man.png"),
    female: require("../asset/gender/woman.png"),
    kids: require("../asset/gender/kids.png"),
    unisex: require("../asset/gender/unisex.png"),
    
    ///button ICON
    dice: "./asset/image/buttonIcon/dice.png",
    tryon: "./asset/image/buttonIcon/tryon.png",
    dis_more: "./asset/image/buttonIcon/play.png",
        
}
export default IMAGES;


