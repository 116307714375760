import React from 'react';
import styles from '../SingleVto.module.css'; // Modifica con il percorso del tuo file CSS
import VariantCard from '../VariantCard';
import SizeSelector from './GlassesSize';

function MirrorVtoMenu({ modelData, handleSelectedGlasses, selectedCode, brandData, listLenght, cardRefs, menuRef, sizeData, selectedSizeCode, handleSelectedSize }) {
  return (
    <div className={styles["menu_vto"]}>
      {modelData && (
        <div className={styles["menu_header"]}>
          <div className={styles["size_selector_container"]}>

<SizeSelector 
  sizeData={sizeData} 
  selectedSizeCode={selectedSizeCode} 
  handleSelectedSize={handleSelectedSize}
/>
</div>
<div className={styles["title_glasses_container"]}>

          <div className={styles["title_glasses"]}>
            {modelData[0]?.nomeOcchiale}
            <div className={styles["title_brand"]}>
              {brandData.nome_brand}
            </div>
          </div>
        </div>
        </div>
      )}
      <div
        className={
          true && listLenght > 3
            ? styles["menu_container"]
            : styles["menu_container-desk"]
        }
        ref={menuRef}
      >
        {modelData && modelData.map((modello, index) => (
          <VariantCard
            key={index}
            modello={modello}
            index={index}
            cardRefs={cardRefs}
            handleSelectedGlasses={handleSelectedGlasses}
            selectedCode={selectedCode}
          />
        ))}
      </div>
    </div>
  );
}

export default MirrorVtoMenu;
