import styles from "../component/SingleVto.module.css"

function VariantCard(props){
    
    const modello = props.modello
    const index = props.index
    const cardRefs = props.cardRefs
    const selectedCode = props.selectedCode

    return (
        <div className={styles["variant_card"]}  key={index} onClick={()=> props.handleSelectedGlasses(modello, index)} ref={(ref) => (cardRefs.current[index] = ref)}>
       <img src={modello.poster} className={index === selectedCode ? styles["variant-image-selected"] : styles["variant-image"]}/>
      
       <p className={styles["model-name"]}>{modello.coloreFrame}</p>
       <div className={index === selectedCode ? styles["selected-indicator"] : styles[""]}></div>
       </div>
       );
}

export default VariantCard;