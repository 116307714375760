
import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import style from './MirrorMenu.module.css'

function MirrorMenu({ onBack, onReset ,isOnBrandPage, isUnselectedFilter, handleOpenBrandPage,isLanguagePage,isOpenStoreLocator }) {
    const { t } = useTranslation();

    function handleGoOnBrand(){
        handleOpenBrandPage()

    }

 
    return (
        <div className={style["menu-container"]}>
        {isLanguagePage && (
          <>
            <div className={style["menu-item"]} onClick={onBack}>
              {isUnselectedFilter && t("back")}
            </div>
            <div className={style["menu-item"]} onClick={handleGoOnBrand}> 
              {!isOnBrandPage && !isOpenStoreLocator && t("discover_our_brands")}
            </div>
            <div className={style["menu-item"]} onClick={onReset}>{t("restart")}</div>
          </>
        )}
      </div>
    );
}

export default MirrorMenu;