import React from 'react';
import styles from '../SingleVto.module.css'; // Modifica con il percorso del tuo file CSS

function FooterVtoMenu({ children }) {
  return (
    <div className={styles["footer-vto-menu"]}>
      {children}
    </div>
  );
}

export default FooterVtoMenu;
