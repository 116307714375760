class Tag {
    constructor(doc){
           this.group = doc?.group ? doc.group: null;
           this.name = doc?.name ? doc.name: null;
           this.groud_order = doc?.groud_order ? doc.name : null;
           this.background_color = doc?.background_color ? doc.background_color : null;
           this.icon = doc?.icon ? doc.icon : null;
           this.short_name = doc?.short_name ? doc.short_name : null;
           this.text_color = doc?.text_color ? doc.text_color : null;
           this.type = doc?.type ? doc.type : null;
           this.urlCover = doc?.cover ? doc.cover : null;
    }
    toString(){
        return `nome tag: ${this.name}
                grouppo: ${this.group}`
                ;    }
  }
  
  //FIrestore Data Converter
 export const tagConverter ={
    toFirestore: (tag) => {
        return {
            
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Tag(data);
    }
  };
