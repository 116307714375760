import { useState, useEffect } from "react";
 import styles from "./SingleVto.module.css"
// import styles from "../component/SingleVtoSafilo.module.css"

import Accordion from "./Accordion";
// import logo from "../asset/arshades_logo_take_pic.png";
// import logo from "../asset/Safilo-logo.png";
import { LOGOPERM, PRIVACY_POLICY_DOC, TERMS_AND_CONDITION_DOC } from "../Utils/constant";
 import Camera from './Camera'
 import { db } from "../data/base";
 import { policyConverte } from "../modello/Policy";
 import {
  doc,
  getDoc,
} from '@firebase/firestore'
import { termconverter } from "../modello/Terms";

function PermissionAndPolicy(props) {

const [openPanel, setOpenPanel] = useState(0);
const [error, setError] = useState(false);
const [isCameraAccepted, setIsCameraAccepted] = useState(false);
const [displayCameraAccept, setIsCameraAcceptedDisplay] = useState(false);
const [privacyPolicy, setPrivacyPolicy] = useState();
const [termsAndCondition, setTermAndCondition] = useState()
const [readPolicy , setRead] = useState(false)

useEffect(()=>{
  const loadData = async () => {
    if(!readPolicy){
    const docInitPolicy= doc(db, "PrivacyPolicy", PRIVACY_POLICY_DOC).withConverter(
      policyConverte
    );
    const initDocData = await getDoc(docInitPolicy);
      const initVariantDoc = initDocData.data();
      setPrivacyPolicy(initVariantDoc);
      setRead(true);
    
      const docInitTerms= doc(db, "TermsAndCondition", TERMS_AND_CONDITION_DOC).withConverter(
        termconverter
      );
      const initDocTerms = await getDoc(docInitTerms);
        const initTermsDoc = initDocTerms.data();
        // console.log(initTermsDoc.description_it);
       
        setTermAndCondition(initTermsDoc);
    }
  }

   loadData();
},[privacyPolicy])

const handlePanelChange = (panel) => {
    if(panel == openPanel){
        setOpenPanel(0);
    }else{
        setOpenPanel(panel);
    }

  }

  function handleCameraAccept() {
    setIsCameraAccepted(true);
  }

  function handlePrivacyAccept() {
    if (error) {
      console.log("Si è verificato un errore durante l'accesso alla fotocamera:", error);
      return;
    }
    if (!isCameraAccepted) {
      setIsCameraAcceptedDisplay(true)
      console.log("Devi prima accettare l'utilizzo della fotocamera");
      return;
    }
    // console.log("click");
    props.accepAction();
  }
  

  function handleCameraError(error) {
    setError(error);
  }
  
    return (
      <>
        <div className={styles["permission_policy"]}> 
            <div className={styles["logo_policy_arshades"]}>
                <img  className={styles["logo_iamge_policy"]} src={LOGOPERM}></img>
            </div>
            <div className={styles["title"]}>
                {/* Ver 1.0 (1001) */}
            </div>
            <div className={styles["subtitle-policy"]}>
                Please read and accept the Legal Terms to proceede
            </div>
            <Accordion
        panel={1}
        openPanel={openPanel}
        handlePanelChange={handlePanelChange}
        title={"Terms and Condition"}
      >
        
        <div className={styles["policy-open"]}>
        {termsAndCondition?.description_en.map((paragraph, index) => {
          return(
          <div key={index}>
          <h3 className={styles["title-policy"]}>{paragraph.titolo}</h3>
          <p className={styles["paragrafo-policy"]}>{paragraph.paragrafo}</p>
          </div>
        )})}
        </div>
      </Accordion>

      <Accordion
        panel={2}
        openPanel={openPanel}
        handlePanelChange={handlePanelChange}
        title={"Privacy Policy"}
      >
      
      <div className={styles["policy-open"]}>
        {privacyPolicy?.description_en.map((paragraph, index) => {
          return(
          <div key={index}>
          <h3 className={styles["title-policy"]}>{paragraph.titolo}</h3>
          <p  className={styles["paragrafo-policy"]}>{paragraph.paragrafo}</p>
          </div>
        )})}
        </div>
      </Accordion>

            <div className={styles["accept-container"]} onClick={handlePrivacyAccept}>Accept</div>
            <div className={styles.poweredby}>Powered by <a href="https://www.arshades.it/it/" className={styles.poweredbyARShades}>ARShades</a></div>
            {!isCameraAccepted && (
        <Camera acceptAction={handleCameraAccept} onError={handleCameraError} message={error} display={displayCameraAccept} />
      )}
            {error && <div><p> UNABLE TO DETECT CAMERA</p><p className={styles["error-display-camera"]}>Allow access to your webcam and check that it is not already in use.</p></div>}

        </div>
        </>

    );


}

export default PermissionAndPolicy;