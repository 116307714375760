import { isMobile } from "react-device-detect";


class TakeFotoTemplate {
    constructor(doc) {

      const isDesktop = window.innerWidth > 1024;
    
      
      this.bb_background_color = doc?.bb_background_color || "#FFFFFF";
      this.bb_background_img = doc?.bb_background_img || "";
      this.bb_background_transparent = doc?.bb_background_transparent || 0;
      this.bb_enabled = doc?.bb_enabled !== undefined ? doc.bb_enabled : true;
      
      
  //     const baseBBHeight = doc?.bb_height || 400;
  // this.bb_height = isDesktop ? baseBBHeight * 0.33 : baseBBHeight;

  // const baseBTHeight = doc?.bt_height || 300;
  // this.bt_height = !isDesktop ? baseBTHeight * 0.33 : baseBTHeight;

  // const baseWatermarkSize = doc?.general_watermark_size || 140;
  // this.general_watermark_size = isDesktop ? baseWatermarkSize * 0.33 : baseWatermarkSize;

  // const baseWatermarkCenterSize = doc?.watermark_center_size || 350;
  // this.watermark_center_size = isDesktop ? baseWatermarkCenterSize * 0.33 : baseWatermarkCenterSize;

  // const baseOffset = doc?.margin_center_watermark || 30;
  // this.margin_center_watermark = isDesktop ? baseOffset * 0.33 :  baseOffset;

  const baseBBHeight = doc?.bb_height || 400;
  this.bb_height = baseBBHeight * 0.33 ;

  const baseBTHeight = doc?.bt_height || 300;
  this.bt_height = baseBTHeight * 0.33 ;

  const baseWatermarkSize = doc?.general_watermark_size || 140;
  this.general_watermark_size =  baseWatermarkSize * 0.33 ;

  const baseWatermarkCenterSize = doc?.watermark_center_size || 350;
  this.watermark_center_size =  baseWatermarkCenterSize * 0.33 ;

  const baseOffset = doc?.margin_center_watermark || 30;
  this.margin_center_watermark =  baseOffset * 0.33;

      this.bb_watermark_img = new Image();
      this.bb_watermark_img.crossOrigin = 'anonymous'; // Consentire il caricamento da un altro dominio
      this.bb_watermark_img.src = doc?.bb_watermark && doc.bb_watermark.trim() !== "" ? doc.bb_watermark : "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/immagini%2Fbrand_logo%2FWatermarkSafilo.png?alt=media&token=8d32f777-46e1-4ac1-91f8-55db3f77d597";

      this.bb_watermark2_img = new Image();
      this.bb_watermark2_img.src = doc?.bb_watermark2 && doc.bb_watermark2.trim() !== "" ? doc.bb_watermark2 : "";
      this.bb_watermark2_img.crossOrigin = 'anonymous'; // Consentire il caricamento da un altro dominio

      this.bb_watermark2_position = doc?.bb_watermark2_position || "CenterBottom";
      this.bb_watermark_position = doc?.bb_watermark_position || "BottomLeft";
      this.bt_background_color = doc?.bt_background_color || "#FFFFFF";
      this.bt_background_img = doc?.bt_background_img || "";
      this.bt_background_transparent = doc?.bt_background_transparent !== undefined ? doc?.bt_background_transparent : 1;
      this.bt_enabled = doc?.bt_enabled !== undefined ? doc?.bt_enabled : true;

      this.bt_watermark_img = new Image();
      this.bt_watermark_img.crossOrigin = 'anonymous'; // Consentire il caricamento da un altro dominio
      this.bt_watermark_img.src = doc?.bt_watermark && doc.bt_watermark.trim() !== "" ? doc.bt_watermark : "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/immagini%2Fbrand_logo%2FPolaroid_watermark.png?alt=media&token=dcfeeb36-000e-4788-a777-e6a6c29d668a";
      
    
      this.bt_watermark2_img = new Image();
      this.bt_watermark2_img.crossOrigin = 'anonymous'; // Consentire il caricamento da un altro dominio
      this.bt_watermark2_img.src = doc?.bt_watermark2 && doc.bt_watermark2.trim() !== "" ? doc.bt_watermark2 : "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/immagini%2Fbrand_logo%2FPolaroid_watermark.png?alt=media&token=dcfeeb36-000e-4788-a777-e6a6c29d668a";
      
      this.bt_watermark2_position = doc?.bt_watermark2_position || "TopRight";
      this.bt_watermark_position = doc?.bt_watermark_position || "CenterTop";
      this.general_margin_watermarks = doc?.general_margin_watermarks || 50;
      this.title = doc?.title || "Default";
    }

    areWatermarksLoaded() {
      return this.bb_watermark_img.complete && this.bb_watermark2_img.complete;
    }
    
    toString() {
      return `TakeFotoTemplate: ${this.title}`;
    }
  }

  export default TakeFotoTemplate;

  
    export const templateConverter ={
      toFirestore: (modello) => {
        return {
          
        }
    },
  
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new TakeFotoTemplate(data);
    }
  }

  