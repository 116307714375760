import React from 'react';
import style from './LoadingScreen.module.css'; // Assicurati che questo file CSS sia aggiornato

const LoadingScreen = ({ loadingProgress }) => {
  return (
    <div className={style["loading-container"]}>
      <h2 className={style["loading-title"]}>Loading</h2>
      <div className={style["progress-bar-container"]}>
        <div className={style["progress-bar"]} style={{ width: `${loadingProgress}%` }}></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
