class Occhiale {
    constructor(doc){
            this.id = doc.id;
            this.codiceOcchiale = doc.codiceOcchiale;
            this.nome_modello = doc.nome_modello;
            this.descrizione = doc.descrizione;
            this.descrizione_en = doc.descrizione_en;
            this.descrizione_es = doc.descrizione_es;
            this.url_product = doc.url_product;
            this.url_shadow = doc.url_shadow;
            this.url_image = doc.url_image;
            this.imgUrl = doc.imgUrl;
            this.lastUpdate = doc.lastUpdate;
            this.stato = doc.stato;
            this.priority = doc.priority;
            this.glasses_inclination_coeff = doc.glasses_inclination_coeff;
            this.glasses_inclination_coeff_ios = doc.glasses_inclination_coeff_ios;
            this.glasses_y_coeff = doc.glasses_y_coeff;
            this.glasses_y_coeff_android = doc.glasses_y_coeff_android;
            this.glasses_z_coeff = doc.glasses_z_coeff;
            this.glasses_z_coeff_android = doc.glasses_z_coeff_android;
            this.lista_categorie = doc.lista_categorie.map(value => value);;
            this.listaCategorieObj = [];
            this.lista_taglie = doc.lista_taglie.map(value => value);
            this.listaTaglieOBJ = [];
            this.lineaRef = doc.lineaRef;
            this.glassesContent = null;
            this.lineaObj = null;
            this.brandId = null;
    }


    isValidGlasses() {
        var isValidGlasses = true
        var validTaglia = false
        this.listaTaglieOBJ.map((taglia) =>{
            validTaglia = false
            taglia.listaModelli.map( (modello) =>{
                if(modello.urlGlb != '') validTaglia = true
            } )
            if(validTaglia == true) isValidGlasses = true
        })
        isValidGlasses = validTaglia
        return isValidGlasses
    }

    toString(){
        return `id: ${this.id},
                codiceOcchiale: ${this.codiceOcchiale},
                nome_modello: ${this.nome_modello},
                descrizione: ${this.descrizione},
                descrizione_en: ${this.descrizione_en},
                descrizione_es: ${this.descrizione_es},
                url_product: ${this.url_product},
                url_shadow: ${this.url_shadow},
                url_image: ${this.url_image},
                imgUrl: ${this.imgUrl},
                lastUpdate: ${this.lastUpdate},
                stato: ${this.stato},
                priority: ${this.priority},
                glasses_inclination_coeff: ${this.glasses_inclination_coeff},
                glasses_inclination_coeff_ios: ${this.glasses_inclination_coeff_ios},
                glasses_y_coeff: ${this.glasses_y_coeff},
                glasses_y_coeff_android: ${this.glasses_y_coeff_android},
                glasses_z_coeff: ${this.glasses_z_coeff},
                glasses_z_coeff_android: ${this.glasses_z_coeff_android},
                lista_categorie: ${this.lista_categorie},
                lista_taglie: ${this.lista_taglie},
                lineaRef: ${this.lineaRef}`;    }
  }
  
  //FIrestore Data Converter
 export const occhialeConverter ={
    toFirestore: (occhiale) => {
        return {
            descrizione: occhiale.descrizione,
            descrizioneEn: occhiale.descrizione_en,
            listaTaglie: occhiale.lista_taglie,
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Occhiale(data);
    }
  };


  