import React , {useRef,useEffect}from 'react';
import CardContainerTwoC from '../container/CardContainerTwoC';
import CardButton from './CardButton';
import style from './CardButton.module.css'
import IMAGES from '../../data/images';
import MirrorTittle from './MirrorHeader';
import gsap from 'gsap';



function FilterByShape({ allShapes, handleShapeFilter, translate, type, handleSkip , selectedGender}) {
    const mirrorTitleRef = useRef(null);
    const cardButtonRefs = useRef([]);

    useEffect(() => {
        gsap.fromTo(
          mirrorTitleRef.current,
          { x: 600, rotationY: -90, z: 500 },
          { x: 0, rotationY: 0, z: 0, duration: 1, ease: "power3.out" }
        );

        cardButtonRefs.current.forEach((el, index) => {
          gsap.fromTo(
            el,
            { x: index % 2 === 0 ? -600 : 600, rotationY: index % 2 === 0 ? 140 : -140, z: 500 },
            { z: 0, x: 0, rotationY: 0, duration: 1, delay: 0.1 * index, ease: "power3.out" }
          );
        });
    }, []);

    function animateOut(shape, type) {

        // Animazione di uscita
    gsap.to(mirrorTitleRef.current, {
        x: -600, 
        rotationY: 90, 
        z:500 ,
      duration: 1,
      ease: "power3.out",
    });
  
    cardButtonRefs.current.forEach((el, index) => {
      gsap.to(el, {
        // Se l'indice è pari, esce verso destra, altrimenti verso sinistra
        x: index % 2 === 0 ? 800 : -800,
        rotationY: index % 2 === 0 ? -140 : 140,
        z: 500,
        duration: 0.5,
        delay: 0.1 * index,
        ease: "power3.in",
        onComplete: () => {
          if (index === cardButtonRefs.current.length - 1) {
            handleShapeFilter(shape, type)
        }
        },
      });
    });

        // Implementa l'animazione di uscita qui
        // Simile a animateOutAndFilter nel componente FilterByGender
    }

    function animateOutAndSkip() {

      // Animazione di uscita
  gsap.to(mirrorTitleRef.current, {
      x: -600, 
      rotationY: 90, 
      z:500 ,
    duration: 1,
    ease: "power3.out",
  });

  cardButtonRefs.current.forEach((el, index) => {
    gsap.to(el, {
      // Se l'indice è pari, esce verso destra, altrimenti verso sinistra
      x: index % 2 === 0 ? 800 : -800,
      rotationY: index % 2 === 0 ? -140 : 140,
      z: 500,
      duration: 0.5,
      delay: 0.1 * index,
      ease: "power3.in",
      onComplete: () => {
        if (index === cardButtonRefs.current.length - 1) {
          handleSkip()
      }
      },
    });
  });
  }

  function convertProperty(property) {
    // Converte la stringa in minuscolo
    let lowerCaseProperty = property.toLowerCase();

    // Sostituisce gli spazi e i caratteri di punteggiatura con underscore
    let formattedProperty = lowerCaseProperty.replace(/[\s\+\-\.]/g, '_');

    return formattedProperty;
}

function getImageProperty(shape) {
  // Converte 'shape' nel formato desiderato
  let formattedShape = convertProperty(shape);

  // console.log(`${formattedShape}_${selectedGender}`)
  // Combina le due stringhe con un underscore
  return `${formattedShape}_${selectedGender}`;
}


    return (
        <>
            <CardContainerTwoC margin={"20px"}>
                <MirrorTittle ref={mirrorTitleRef} title={translate("select_your_frame_type")} subtitle={translate("choose_the_icon")}  handleSkip={()=>animateOutAndSkip()} />
                {allShapes.map((shape, index) => (
                    <CardButton
                        ref={(el) => cardButtonRefs.current[index] = el}
                        key={index}
                        onClick={() => animateOut(shape, type)}
                        backgroundCover={IMAGES[getImageProperty(shape)]} // Assicurati che questo corrisponda alla tua logica di immagini
                    >
                        <div className={`${style.bottomContainer} ${style.smallText}`}>
                            {translate(convertProperty(shape))}
                        </div>
                    </CardButton>
                ))}
            </CardContainerTwoC>
        </>
    );
}

export default FilterByShape;
