// StoreMock.js
export const stores = [
    {
        id: 1,
        name: "Ritual Vision",
        address: "R. Padre Américo 25B, 1600-581 Lisboa, Portogallo",
        latitude: 38.76316,
        longitude: -9.18063,
        description: "ÓPTICA Ritual Vision",
        brand: "Polaroid",
        logo: "ritualvision.png"
    },
    {
        id: 2,
        name: "Alain Afflelou Óptico",
        address: "Centre Commercial Ubbo, Av. Cruzeiro Seixas 5 E 7 Lj 0.041 . Piso 0, 2650-504 Amadora, Portogallo",
        latitude: 38.77753,
        longitude: -9.2186,
        description: "Alain Afflelou Óptico",
        brand: "Polaroid",
        logo: "afflelou.png"
    },
    {
        id: 3,
        name: "Ópticas MultiOpticas Ubbo Tejo Amadora",
        address: "Ubbo Dolce Vita Tejo, Avenida Cruzeiro Seixas 5-7 C. C, Loja 0.069, 2650-504 Amadora, Portogallo",
        latitude: 38.78138,
        longitude: -9.22087,
        description: "Ópticas MultiOpticas Ubbo Tejo Amadora",
        brand: "Polaroid",
        logo: "multiopticas.png"
    },
    {
        id: 4,
        name: "Sunglass Hut Rua Augusta",
        address: "R. Augusta 119, 1100-053 Lisboa, Portogallo",
        latitude: 38.71044,
        longitude: -9.13772,
        description: "Sunglass Hut Rua Augusta",
        brand: "Polaroid",
        logo: "sunglasshut.png"
    },
    {
        id: 5,
        name: "Chiado Eyeglass Factory - Fábrica de Óculos do Chiado",
        address: "R. Garrett Nº33, 1200-309 Lisboa, Portogallo",
        latitude: 38.71081,
        longitude: -9.14038,
        description: "Chiado Eyeglass Factory - Fábrica de Óculos do Chiado",
        brand: "Polaroid",
        logo: "chiado.png"
    },
    {
        id: 6,
        name: "Fora Sunglasses",
        address: "R. da Misericórdia 90, 1200-273 Lisboa, Portogallo",
        latitude: 38.71247,
        longitude: -9.14302,
        description: "Fora Sunglasses",
        brand: "Polaroid",
        logo: "fora.png"
    },
    {
        id: 7,
        name: "Óptica Príncipe Real",
        address: "R. Dom Pedro V Nº 75, 1250-093 Lisboa, Portogallo",
        latitude: 38.71572,
        longitude: -9.14652,
        description: "Óptica Príncipe Real",
        brand: "Polaroid",
        logo: "principereal.png"
    },
    {
        id: 8,
        name: "Óticas OCR Campo de Ourique",
        address: "R. Ferreira Borges 94D, 1350-135 Lisboa, Portogallo",
        latitude: 38.71793,
        longitude: -9.16391,
        description: "Óticas OCR Campo de Ourique",
        brand: "Polaroid",
        logo: "oticasocr.png"
    },
    {
        id: 9,
        name: "Óticas OCR - R. Castilho",
        address: "1250 071, R. Castilho 50 1, 1250-071 Lisboa, Portogallo",
        latitude: 38.72362,
        longitude: -9.15205,
        description: "Óticas OCR - R. Castilho",
        brand: "Polaroid",
        logo: "oticasocr.png"
    },
    {
        id: 10,
        name: "Oculista do Bairro",
        address: "Av. Grão Vasco 45A, 1500-336 Lisboa, Portogallo",
        latitude: 38.74985,
        longitude: -9.20301,
        description: "Oculista do Bairro",
        brand: "Polaroid",
        logo: "odb.png"
    },
    {
        id: 11,
        name: "Opticlasse",
        address: "Estr. de Benfica 759, 1500-089 Lisboa, Portogallo",
        latitude: 38.75210,
        longitude: -9.20574,
        description: "Opticlasse",
        brand: "Polaroid",
        logo: "opticlasse.png"
    }, {
        id: 12,
        name: "Olhar de Prata",
        address: "Av. Fontes Pereira de Melo 21, 1050-116 Lisboa, Portogallo",
        latitude: 38.72971,
        longitude: -9.14758,
        description: "Olhar de Prata",
        brand: "Polaroid",
        logo: "olhar.png"
    },
    // Aggiungi ulteriori negozi
];

export const stores2 =[
  {
    "id": 1,
    "name": "OČNÍ STUDIO ALEŠ ŽEJDL S.R.O.",
    "address": "\"VEVEŘÍ 3163/111, BRNO, 616 00, Czech Republic\"",
    "longitude": 16.581,
    "latitude": 49.213
  },
  {
    "id": 2,
    "name": "OOČI oční optika s.r.o.",
    "address": "\"Žižkova 2964/29, Kroměříž, 767 01, Czech Republic\"",
    "longitude": 17.397,
    "latitude": 49.298
  },
  {
    "id": 3,
    "name": "LUNETTES OPTIKA - KARVINÁ",
    "address": "\"NÁDRAŽNÍ 1939/4A, KARVINÁ, 733 01, Czech Republic\"",
    "longitude": 18.54,
    "latitude": 49.854
  },
  {
    "id": 4,
    "name": "AXIS OPTIK S.R.O.",
    "address": "\"Kostelní 168, Domažlice, 344 01, Czech Republic\"",
    "longitude": 14.4709678,
    "latitude": 49.7660392
  },
  {
    "id": 5,
    "name": "OPTIK CENTRUM PLUS",
    "address": "\"VÍDEŇSKÁ HYPERNOVA ZNOJMO 684/69, ZNOJMO, 669 02, Czech Republic\"",
    "longitude": 16.048,
    "latitude": 48.855
  },
  {
    "id": 6,
    "name": "Oční optika Alena Boháčová",
    "address": "\"Klášterní 11, Vysoké Mýto, 566 01, Czech Republic\"",
    "longitude": 16.157,
    "latitude": 49.954
  },
  {
    "id": 7,
    "name": "DIOPTRA OPTIK S.R.O.-OČNÍ OPTIKA",
    "address": "\"T.G.MASARYKA 12, SUŠICE, 342 01, Czech Republic\"",
    "longitude": 13.519,
    "latitude": 49.231
  },
  {
    "id": 8,
    "name": "LUNETTES OPTIKA - OV-CENTRUM",
    "address": "\"28.ŘÍJNA 49/23, MORAVSKÁ OSTRAVA, 702 00, Czech Republic\"",
    "longitude": 18.292,
    "latitude": 49.834
  },
  {
    "id": 9,
    "name": "LUNETTES OPTIKA - N.KAROLÍNA",
    "address": "\"JANTAROVÁ 3344/4, OSTRAVA 1 - NOVÁ KAROLÍNA, 702 00, Czech Republic\"",
    "longitude": 18.28,
    "latitude": 49.83
  },
  {
    "id": 10,
    "name": "OČNÍ OPTIKA HORNÍ POČERNICE S.R.O.",
    "address": "\"OBCHODNÍ 2694/2, PRAHA-HORNÍ POČERNICE, 193 00, Czech Republic\"",
    "longitude": 14.601,
    "latitude": 50.108
  },
  {
    "id": 11,
    "name": "OPTOPLUS S.R.O.",
    "address": "\"Husova 77, Prachatice - Prachatice I, 383 01, Czech Republic\"",
    "longitude": 14.006,
    "latitude": 49.009
  },
  {
    "id": 12,
    "name": "OPTOMEDIC DD S.R.O.",
    "address": "\"GEN. SVOBODY 3069/19B, ŠUMPERK, 787 01, Czech Republic\"",
    "longitude": 16.976,
    "latitude": 49.965
  },
  {
    "id": 13,
    "name": "Oční studio Optix, s.r.o.",
    "address": "\"Masarykovo náměstí 1219/52, Jihlava, 586 01, Czech Republic\"",
    "longitude": 15.591,
    "latitude": 49.401
  },
  {
    "id": 14,
    "name": "DIOPTRA OPTIK S.R.O.-OČNÍ OPTIKA",
    "address": "\"TŘ.J.P.KOUBKA 86, BLATNÁ, 388 01, Czech Republic\"",
    "longitude": 13.589,
    "latitude": 49.444
  },
  {
    "id": 15,
    "name": "PRISM OPTIC",
    "address": "\"Ječná 6, Praha 2, 120 00, Czech Republic\"",
    "longitude": 14.416,
    "latitude": 50.077
  },
  {
    "id": 16,
    "name": "IRIS oční optika s.r.o.",
    "address": "\"Masarykovo nám. 144/26, Jeseník, 790 01, Czech Republic\"",
    "longitude": 17.221,
    "latitude": 50.223
  },
  {
    "id": 17,
    "name": "COOLOPTICS S.R.O.",
    "address": "\"Hartigova 1626/177, PRAHA 3 - ŽIŽKOV, 130 00, Czech Republic\"",
    "longitude": 14.466,
    "latitude": 50.09
  },
  {
    "id": 18,
    "name": "DIOPTRA OPTIK S.R.O.-OČNÍ OPTIKA",
    "address": "\"ALEJ SVOBODY 659/29, PLZEŇ, 323 00, Czech Republic\"",
    "longitude": 13.371,
    "latitude": 49.747
  },
  {
    "id": 19,
    "name": "OPTIK KROUMAN",
    "address": "\"Josefská 516/1, Brno - město, 602 00, Czech Republic\"",
    "longitude": 16.602,
    "latitude": 49.196
  },
  {
    "id": 20,
    "name": "JOSEF ŠVARC",
    "address": "\"Klatovská třída 131/26, Plzeň - Jižní Předměstí, 301 00, Czech Republic\"",
    "longitude": 13.388,
    "latitude": 49.753
  },
  {
    "id": 21,
    "name": "JOSEF MACHALÍK",
    "address": "\"BÁNOV 508, BÁNOV U UHERSKÉHO BRODU, 687 54, Czech Republic\"",
    "longitude": 17.359,
    "latitude": 48.976
  },
  {
    "id": 22,
    "name": "Optometrista Jiří Bíma s.r.o.",
    "address": "\"HUSOVA 79, JIČÍN-VALDICKÉ PŘEDM., 506 01, Czech Republic\"",
    "longitude": 15.349,
    "latitude": 50.431
  },
  {
    "id": 23,
    "name": "KATEŘINA NOVOTNÁ",
    "address": "\"U NÁDRAŽÍ 1014/1, TEPLICE, 415 01, Czech Republic\"",
    "longitude": 13.833,
    "latitude": 50.648
  },
  {
    "id": 24,
    "name": "OPTIKA RUNGE s.r.o",
    "address": "\"KOMENSKÉHO NÁM. 155, VOTICE, 259 01, Czech Republic\"",
    "longitude": 14.652,
    "latitude": 49.672
  },
  {
    "id": 25,
    "name": "POP OPTIK (SIRIUS S.R.O.)",
    "address": "\"ČECHOVSKÁ 55, PŘÍBRAM, 261 01, Czech Republic\"",
    "longitude": 14.032,
    "latitude": 49.686
  },
  {
    "id": 26,
    "name": "AS OPTIC S.R.O.",
    "address": "\"ÚJEZD 454/46, PRAHA - MALÁ STRANA, 118 00, Czech Republic\"",
    "longitude": 14.406,
    "latitude": 50.088
  },
  {
    "id": 27,
    "name": "OPTIPRO A.S.",
    "address": "\"U DÁLNICE 777, BRNO - MODŘICE, 664 42, Czech Republic\"",
    "longitude": 16.538,
    "latitude": 49.172
  },
  {
    "id": 28,
    "name": "DOKTOR OPTIK Group s.r.o.",
    "address": "\"HORNÍ BRAŠOV 253, TÝN NAD VLTAVOU, 375 01, Czech Republic\"",
    "longitude": 14.438,
    "latitude": 49.224
  },
  {
    "id": 29,
    "name": "APIA CZ, s.r.o. (OČNÍ OPTIKA APIA)",
    "address": "\"Masarykova 18, Brno, 602 00, Czech Republic\"",
    "longitude": 16.608,
    "latitude": 49.196
  },
  {
    "id": 30,
    "name": "Oculista trade s.r.o.",
    "address": "\"NÁMĚSTÍ MÍRU 163/I, JINDŘICHŮV HRADEC, 377 01, Czech Republic\"",
    "longitude": 15.0,
    "latitude": 49.144
  },
  {
    "id": 31,
    "name": "IVETA PLEYEROVÁ (OČNÍ OPTIKA)",
    "address": "\"VELKÁ DOMINIKÁNSKÁ 121/18, LITOMĚŘICE, 412 01, Czech Republic\"",
    "longitude": 14.121,
    "latitude": 50.534
  },
  {
    "id": 32,
    "name": "LUNETTES OPTIKA - BRNO",
    "address": "\"PANSKÁ 2/4, BRNO, BRNO-MĚSTO, 602 00, Czech Republic\"",
    "longitude": 16.606,
    "latitude": 49.202
  },
  {
    "id": 33,
    "name": "MARGITA FILIPOVÁ",
    "address": "\"NÁMĚSTÍ 3333/35A, KRAVÁŘE, 747 21, Czech Republic\"",
    "longitude": 18.208,
    "latitude": 49.754
  },
  {
    "id": 34,
    "name": "OPTIKA CLARITY S.R.O.",
    "address": "\"Masarykovo náměstí 58/33, Kyjov, 697 01, Czech Republic\"",
    "longitude": 17.125,
    "latitude": 49.002
  },
  {
    "id": 35,
    "name": "DIOPTRA OPTIK S.R.O.-OČNÍ OPTIKA",
    "address": "\"SKUPOVA 490/24 - OC LUNA, PLZEŇ, 326 00, Czech Republic\"",
    "longitude": 13.407,
    "latitude": 49.741
  },
  {
    "id": 36,
    "name": "OXA OPTIK S.R.O.",
    "address": "\"MASARYKOVA TŘÍDA 1333, ORLOVÁ LUTYNĚ, 735 14, Czech Republic\"",
    "longitude": 18.356,
    "latitude": 49.829
  },
  {
    "id": 37,
    "name": "POP OPTIK (SIRIUS S.R.O.)",
    "address": "\"Nádražní 29/21, Praha 5 - Anděl, 150 00, Czech Republic\"",
    "longitude": 14.406,
    "latitude": 50.07
  },
  {
    "id": 38,
    "name": "POP OPTIK (SIRIUS S.R.O.)",
    "address": "\"17. listopadu 1188/5, Říčany, 251 01, Czech Republic\"",
    "longitude": 14.669,
    "latitude": 49.958
  },
  {
    "id": 39,
    "name": "STANISLAV PLECHL",
    "address": "\"HUMENNÁ 431/15, BRNO, 625 00, Czech Republic\"",
    "longitude": 16.573,
    "latitude": 49.205
  },
  {
    "id": 40,
    "name": "MGR. MAGDALENA ŠENKEŘÍKOVÁ",
    "address": "\"TEPLICKÁ 893, HRANICE, 753 01, Czech Republic\"",
    "longitude": 17.736,
    "latitude": 49.552
  },
  {
    "id": 41,
    "name": "Oční optika Cornea Lexum s.r.o.",
    "address": "\"TRIDA VÁCLAVA KLEMENTA 1459, MLADÁ BOLESLAV, 293 01, Czech Republic\"",
    "longitude": 14.918,
    "latitude": 50.412
  },
  {
    "id": 42,
    "name": "DR. OPTIK S.R.O.",
    "address": "\"U VOJENSKÉ NEMOCNICE 1200/1, PRAHA, 162 00, Czech Republic\"",
    "longitude": 14.366,
    "latitude": 50.063
  },
  {
    "id": 43,
    "name": "Optika Optometrie(Klára Strnadlová)",
    "address": "\"Masarykovo náměstí 85, Hranice, 753 01, Czech Republic\"",
    "longitude": 17.735,
    "latitude": 49.55
  },
  {
    "id": 44,
    "name": "Eva Martinková",
    "address": "\"Palackého nám. 1600, Rosice, 665 01, Czech Republic\"",
    "longitude": 16.273,
    "latitude": 49.205
  },
  {
    "id": 45,
    "name": "Optiscont s.r.o.",
    "address": "\"Dvorecká 2A, Praha 4, 147 00, Czech Republic\"",
    "longitude": 14.411,
    "latitude": 50.038
  },
  {
    "id": 46,
    "name": "OPTIKA SLAVKOV s.r.o.",
    "address": "\"Palackého nám. 77, Slavkov u Brna, 684 01, Czech Republic\"",
    "longitude": 16.874,
    "latitude": 49.141
  },
  {
    "id": 47,
    "name": "Oční optika DANIELA, s.r.o.",
    "address": "\"Nádražní 398, Frýdlant nad Ostravicí - Frýdlant, 739 11, Czech Republic\"",
    "longitude": 18.356,
    "latitude": 49.571
  },
  {
    "id": 48,
    "name": "OPTIKA VH (Optika Hadamčíková sro)",
    "address": "\"Holasická 1154/2, Opava - Kateřinky, 747 05, Czech Republic\"",
    "longitude": 17.891,
    "latitude": 49.959
  },
  {
    "id": 49,
    "name": "OPTIK SPEKTRUM S.R.O.",
    "address": "\"VINOHRADSKÁ 1292/65, PRAHA 2, 120 00, Czech Republic\"",
    "longitude": 14.443,
    "latitude": 50.076
  },
  {
    "id": 50,
    "name": "DR. OPTIK S.R.O.",
    "address": "\"Řevnická 1/21, Praha 5 - Zličín, 155 21, Czech Republic\"",
    "longitude": 14.274,
    "latitude": 50.033
  },
  {
    "id": 51,
    "name": "VISUS SPOL. S R.O.",
    "address": "\"ZENKLOVA 40, PRAHA 8, 180 00, Czech Republic\"",
    "longitude": 14.471,
    "latitude": 50.112
  },
  {
    "id": 52,
    "name": "Relax OC Chodov",
    "address": "\"Roztylská 2321/19, Praha 4, 148 00, Czech Republic\"",
    "longitude": 14.495,
    "latitude": 50.032
  },
  {
    "id": 53,
    "name": "Lunettes Outlet Arena Moravia",
    "address": "\"Hlučínská 1176/i07, Ostrava, 702 00, Czech Republic\"",
    "longitude": 18.153,
    "latitude": 49.821
  },
  {
    "id": 54,
    "name": "DR. OPTIK S.R.O.",
    "address": "\"Arménská 3277, Kladno, 272 01, Czech Republic\"",
    "longitude": 14.104,
    "latitude": 50.146
  },
  {
    "id": 55,
    "name": "Superswim Čestlice",
    "address": "\"Pražská 138, Čestlice, 251 01, Czech Republic\"",
    "longitude": 14.602,
    "latitude": 49.994
  },
  {
    "id": 56,
    "name": "Oculista trade s.r.o.",
    "address": "\"Karlovo náměstí 201, Pelhřimov, 393 01, Czech Republic\"",
    "longitude": 15.227,
    "latitude": 49.43
  },
  {
    "id": 57,
    "name": "AXEL OPTIK S.R.O.",
    "address": "\"MSGRE. B. STAŠKA 16, DOMAŽLICE, 344 01, Czech Republic\"",
    "longitude": 12.929,
    "latitude": 49.309
  },
  {
    "id": 58,
    "name": "EUROOPTIK - OčNÍ OPTIKA (03)",
    "address": "\"ŽIŽKOVO NÁM. 120/2, PROSTĚJOV, 796 01, Czech Republic\"",
    "longitude": 17.106,
    "latitude": 49.472
  },
  {
    "id": 59,
    "name": "Optic Shop (Bc. Kristýna Drážková)",
    "address": "\"Hartigova 1685/110, Praha 3 - Žižkov, 130 00, Czech Republic\"",
    "longitude": 14.462,
    "latitude": 50.101
  },
  {
    "id": 60,
    "name": "AMBRA PLUS S.R.O.",
    "address": "\"JUGOSLÁVSKÁ 236/13, PRAHA 2, 120 00, Czech Republic\"",
    "longitude": 14.443,
    "latitude": 50.076
  },
  {
    "id": 61,
    "name": "JAN RADA",
    "address": "\"DR. DAVIDA BECHERA 893\\/6, KARLOVY VARY, 360 01, Czech Republic\"",
    "longitude": 12.87537,
    "latitude": 50.22671
  },
  {
    "id": 62,
    "name": "Relax Avion Ostrava",
    "address": "\"Rudná 114\\/3114, Ostrava - Zábřeh, 700 30, Czech Republic\"",
    "longitude": 18.18941,
    "latitude": 49.83402
  },
  {
    "id": 63,
    "name": "LUNETTES OPTIKA - PUA-GLOBUS",
    "address": "\"PODĚBRADSKÁ 293, PARDUBICE, 530 02, Czech Republic\"",
    "longitude": 15.77914,
    "latitude": 50.02882
  },
  {
    "id": 64,
    "name": "EUROOPTIK - OČNÍ OPTIKA (42)",
    "address": "\"HRNČÍŘSKÁ 121\\/3, OPAVA, 746 01, Czech Republic\"",
    "longitude": 17.89334,
    "latitude": 49.93155
  },
  {
    "id": 65,
    "name": "OČNÍ OPTIKA (Vít Křepela)",
    "address": "\"Náměstí Míru 2, Hrušovany nad Jevišovkou, 671 67, Czech Republic\"",
    "longitude": 16.36518,
    "latitude": 48.88165
  },
  {
    "id": 66,
    "name": "OPTIKA JAKEŠOVÁ, S.R.O.",
    "address": "\"NÁM.SVOBODY 527, TŘINEC -LYŽBICE, 739 61, Czech Republic\"",
    "longitude": 18.67525,
    "latitude": 49.68851
  },
  {
    "id": 67,
    "name": "OPTIKA REVISUS s.r.o.",
    "address": "\"třída Tomáše Bati 508, Zlín, 760 01, Czech Republic\"",
    "longitude": 17.67166,
    "latitude": 49.22488
  },
  {
    "id": 68,
    "name": "OČNÍ OPTIKA Moudrá - Šedivá, s.r.o.",
    "address": "\"ŘEZNICKÁ 235, BENEŠOV, 256 01, Czech Republic\"",
    "longitude": 14.6867,
    "latitude": 49.78149
  },
  {
    "id": 69,
    "name": "OPTIK PANENKA (DIOPTRA OČNÍ OPTIKA)",
    "address": "\"STŘELECKÁ 839\\/27, HRADEC KRÁLOVÉ, 500 02, Czech Republic\"",
    "longitude": 15.84152,
    "latitude": 50.20966
  },
  {
    "id": 70,
    "name": "OPTIKA KAČMAŘ S.R.O.",
    "address": "\"ČS. ARMÁDY 1402\\/6A, HLUČÍN, 748 01, Czech Republic\"",
    "longitude": 18.01658,
    "latitude": 49.91123
  },
  {
    "id": 71,
    "name": "Mgr.Tereza Pučálková s.r.o.",
    "address": "\"Ostružnická 323\\/4, Olomouc, 779 00, Czech Republic\"",
    "longitude": 17.23456,
    "latitude": 49.59114
  },
  {
    "id": 72,
    "name": "MGR. LADISLAVA KURUCOVÁ",
    "address": "\"ÚJEZD 439, STRÁŽNICE, 696 62, Czech Republic\"",
    "longitude": 17.46478,
    "latitude": 48.85714
  },
  {
    "id": 73,
    "name": "OXA OPTIK S.R.O.",
    "address": "\"HLAVNÍ TŘÍDA 1024\\/51, OSTRAVA - PORUBA, 702 00, Czech Republic\"",
    "longitude": 18.28223,
    "latitude": 49.82322
  },
  {
    "id": 74,
    "name": "OPTILUX s.r.o.",
    "address": "\"Nové sady 996\\/25 (Titanium), Brno, 602 00, Czech Republic\"",
    "longitude": 16.60572,
    "latitude": 49.20959
  },
  {
    "id": 75,
    "name": "OPTIKA - ŠIMŮNKOVÁ Teplice, s.r.o.",
    "address": "\"Benešovo náměstí 422\\/3, Teplice, 415 01, Czech Republic\"",
    "longitude": 13.83734,
    "latitude": 50.63673
  },
  {
    "id": 76,
    "name": "MGR. PAVEL DIENSTBIER",
    "address": "\"SMETANOVA 764, KOLÍN, 280 02, Czech Republic\"",
    "longitude": 15.20425,
    "latitude": 50.02816
  },
  {
    "id": 77,
    "name": "EUROOPTIK - OČNÍ OPTIKA (32)",
    "address": "\"Riegrova 384\\/28, OLOMOUC, 779 00, Czech Republic\"",
    "longitude": 17.2576,
    "latitude": 49.59912
  },
  {
    "id": 78,
    "name": "ZDENĚK NOVOTNÝ",
    "address": "\"OKRUŽNÍ 188\\/7, MOHELNICE, 789 85, Czech Republic\"",
    "longitude": 16.97397,
    "latitude": 49.56555
  },
  {
    "id": 79,
    "name": "JIRÍ JÍCHA",
    "address": "\"DEJVICKÁ 687\\/25, PRAHA - DEJVICE, 160 00, Czech Republic\"",
    "longitude": 14.37844,
    "latitude": 50.10199
  },
  {
    "id": 80,
    "name": "OČNÍ OPTIKA Cornea Lexum s.r.o.",
    "address": "\"Antala Staška 1670\\/80, Praha 4, 144 00, Czech Republic\"",
    "longitude": 14.42263,
    "latitude": 50.00275
  },
  {
    "id": 81,
    "name": "OPTIKA CLARITY S.R.O.",
    "address": "\"ZNOJEMSKÁ 1383, TŘEBÍČ, 674 01, Czech Republic\"",
    "longitude": 15.86893,
    "latitude": 49.21722
  },
  {
    "id": 82,
    "name": "ING. LEOPOLD KOŠŤÁL",
    "address": "\"LIBUŠINA TŘÍDA 580\\/4, BRNO, 623 00, Czech Republic\"",
    "longitude": 16.5738,
    "latitude": 49.19568
  },
  {
    "id": 83,
    "name": "Relax OC Futurum",
    "address": "\"VÍDEŇSKÁ 100, Brno, 619 00, Czech Republic\"",
    "longitude": 16.58074,
    "latitude": 49.17841
  },
  {
    "id": 84,
    "name": "HANA KROUMANOVÁ OČNÍ OPTIKA",
    "address": "\"MERHAUTOVA 955\\/77, BRNO - BRNO MĚSTO, 613 00, Czech Republic\"",
    "longitude": 16.62694,
    "latitude": 49.22545
  },
  {
    "id": 85,
    "name": "OČNÍ OPTIKA Cornea Lexum s.r.o.",
    "address": "\"Soukalova 3355, Poliklinika, PRAHA 4 - MODŘANY, 143 01, Czech Republic\"",
    "longitude": 14.4507,
    "latitude": 50.03266
  },
  {
    "id": 86,
    "name": "AXEL OPTIK S.R.O.",
    "address": "\"MOSKEVSKÁ 911\\/6, KARLOVY VARY, 360 01, Czech Republic\"",
    "longitude": 12.88323,
    "latitude": 50.22359
  },
  {
    "id": 87,
    "name": "Martin Zemánek - Optik Zemánek",
    "address": "\"Dolní Náměstí 7, Jablonec nad Nisou, 466 01, Czech Republic\"",
    "longitude": 15.17792,
    "latitude": 50.7247
  },
  {
    "id": 88,
    "name": "DR. OPTIK S.R.O.",
    "address": "\"HUSOVA 92, SLANÝ, 274 01, Czech Republic\"",
    "longitude": 14.08997,
    "latitude": 50.22932
  },
  {
    "id": 89,
    "name": "DR. OPTIK S.R.O.",
    "address": "\"Husovo náměstí 39\\/26, Beroun, 266 01, Czech Republic\"",
    "longitude": 14.06865,
    "latitude": 49.96396
  },
  {
    "id": 90,
    "name": "Sport and Style Optic s.r.o.",
    "address": "\"ČESKOMORAVSKÁ 2420\\/15a (Galerie Har, Praha 9 - Libeň, 190 00, Czech Republic\"",
    "longitude": 14.51625,
    "latitude": 50.11035
  },
  {
    "id": 91,
    "name": "DIOPTRA OPTIK S.R.O.-OČNÍ OPTIKA",
    "address": "\"KLATOVSKÁ TŘÍDA 348\\/10, PLZEŇ, 301 26, Czech Republic\"",
    "longitude": 13.36818,
    "latitude": 49.75721
  },
  {
    "id": 92,
    "name": "Optik Skryjová s.r.o.",
    "address": "\"Masarykova třída 23, Opava, 746 01, Czech Republic\"",
    "longitude": 17.8975,
    "latitude": 49.94077
  },
  {
    "id": 93,
    "name": "OPTIKA ŠUŠ & ZUSKOVÁ, S.R.O.",
    "address": "\"Kanovnická 376\\/5, České Budějovice, 370 01, Czech Republic\"",
    "longitude": 14.47547,
    "latitude": 48.9741
  },
  {
    "id": 94,
    "name": "LUNETTES OPTIKA (CENTRUM GEČKO)",
    "address": "\"Spojovací 396, Ostrava, 725 27, Czech Republic\"",
    "longitude": 18.29632,
    "latitude": 49.84455
  },
  {
    "id": 95,
    "name": "OČNÍ centrum Janoušek, s.r.o.",
    "address": "\"Komenského 128, Chropyně, 768 11, Czech Republic\"",
    "longitude": 17.16364,
    "latitude": 49.29927
  },
  {
    "id": 96,
    "name": "JITKA NECHANICKÁ",
    "address": "\"LETNÍ 341, BRADLEC, 293 06, Czech Republic\"",
    "longitude": 14.40425,
    "latitude": 49.4658
  },
  {
    "id": 97,
    "name": "Relax OC Šantovka Olomouc",
    "address": "\"Polská 1201\\/1, Olomouc, 772 00, Czech Republic\"",
    "longitude": 17.25374,
    "latitude": 49.60022
  },
  {
    "id": 98,
    "name": "AXEL OPTIK S.R.O.",
    "address": "\"Sokolovská 447, Holýšov, 345 62, Czech Republic\"",
    "longitude": 12.929,
    "latitude": 49.343
  },
  {
    "id": 99,
    "name": "DIOPTRA OPTIK S.R.O.-OČNÍ OPTIKA",
    "address": "\"SOBOTECKÁ 1660, TURNOV, 511 21, Czech Republic\"",
    "longitude": 15.16382,
    "latitude": 50.59136
  },
  {
    "id": 100,
    "name": "Relax OC Haná Olomouc",
    "address": "\"Kafkova 8, Olomouc, 779 00, Czech Republic\"",
    "longitude": 17.25374,
    "latitude": 49.60022
  },
  {
    "id": 101,
    "name": "PÉCSI EMENTROP-INVESTMENT KFT.",
    "address": "\"PÉCS PLÁZA MEGYERI ÚT 76., PÉCS, 7631, Hungary\"",
    "longitude": 18.22263,
    "latitude": 46.07245
  },
  {
    "id": 102,
    "name": "HONTI OPTIKA KFT.",
    "address": "\"APPONYI TÉR 7., JÁSZBERÉNY, 5100, Hungary\"",
    "longitude": 19.92766,
    "latitude": 47.49782
  },
  {
    "id": 103,
    "name": "MINION TRADE KFT.",
    "address": "\"JÓZSEF ATTILA U. 43, FÓT, 2151, Hungary\"",
    "longitude": 19.31422,
    "latitude": 47.63142
  },
  {
    "id": 104,
    "name": "TIAGAM KFT.",
    "address": "\"ZÁKONYI FERENC U. 6, BALATONFÜRED, 8230, Hungary\"",
    "longitude": 17.88338,
    "latitude": 46.95344
  },
  {
    "id": 105,
    "name": "SÉRTZ OPTIKAI BT.",
    "address": "\"ÜLLŐI ÚT 461., BUDAPEST, 1181, Hungary\"",
    "longitude": 19.13972,
    "latitude": 47.42667
  },
  {
    "id": 106,
    "name": "OPTIKA MAXIMA KFT.",
    "address": "\"CSIGEKERT U. 13., DEBRECEN, 4027, Hungary\"",
    "longitude": 21.63102,
    "latitude": 47.52548
  },
  {
    "id": 107,
    "name": "VICAI OPTIKA KFT.",
    "address": "\"SZENT ISTVÁN KIRÁLY ÚT 37., KALOCSA, 6300, Hungary\"",
    "longitude": 18.97871,
    "latitude": 46.52618
  },
  {
    "id": 108,
    "name": "MIKLÓSI KONTALUX KFT.",
    "address": "\"FŐ TÉR 7., SZOMBATHELY, 9700, Hungary\"",
    "longitude": 16.62778,
    "latitude": 47.22694
  },
  {
    "id": 109,
    "name": "TÁNCZOS RÉKA E.V.",
    "address": "\"DÉVÉNYI UTCA 3., SZÉKESFEHÉRVÁR, 8000, Hungary\"",
    "longitude": 18.40414,
    "latitude": 47.18876
  },
  {
    "id": 110,
    "name": "ALEX OPTIKA KFT",
    "address": "\"LACKNER KRISTÓF U. 35. (PLÁZA), SOPRON, 9400, Hungary\"",
    "longitude": 16.58014,
    "latitude": 47.68607
  },
  {
    "id": 111,
    "name": "Medicover Optika Kft- Eiffel Optika",
    "address": "\"Teréz körút 55-57., Budapest, 1062, Hungary\"",
    "longitude": 19.05926,
    "latitude": 47.50645
  },
  {
    "id": 112,
    "name": "GALKER OPTIKA KFT.",
    "address": "\"KERTVÁROS U. 3., FEHÉRGYARMAT, 4900, Hungary\"",
    "longitude": 22.31854,
    "latitude": 48.45919
  },
  {
    "id": 113,
    "name": "LIPO OPTIKA KFT",
    "address": "\"CZIRFUSZ F U. 20., BAJA, 6500, Hungary\"",
    "longitude": 19.2309,
    "latitude": 46.18168
  },
  {
    "id": 114,
    "name": "POLAR VISION KFT.",
    "address": "\"VÍGH U. 8. 1. EM. 3., BUDAPEST, 1084, Hungary\"",
    "longitude": 19.08509,
    "latitude": 47.48855
  },
  {
    "id": 115,
    "name": "EURO-OPTIC-MISKOLC KFT.",
    "address": "\"SZENT ISTVÁN ÚT 13., TISZAÚJVÁROS, 3580, Hungary\"",
    "longitude": 20.53286,
    "latitude": 48.10189
  },
  {
    "id": 116,
    "name": "CENTER OPTIKA LÁTÁSCENTRUM",
    "address": "\"KOSSUTH L. 17-19., ZALAEGERSZEG, 8900, Hungary\"",
    "longitude": 16.84124,
    "latitude": 46.83852
  },
  {
    "id": 117,
    "name": "TREND-OPTIK KFT.",
    "address": "\"TÖLGYFA SOR 11., SOPRON, 9400, Hungary\"",
    "longitude": 16.58014,
    "latitude": 47.68607
  },
  {
    "id": 118,
    "name": "ZSOLDOS TAMÁS",
    "address": "\"VÁROSHÁZ U. 4, KŐSZEG, 9730, Hungary\"",
    "longitude": 16.54077,
    "latitude": 47.38691
  },
  {
    "id": 119,
    "name": "RÁBABRILL KFT.",
    "address": "\"MÁRTÍROK TERE 1., CSORNA, 9300, Hungary\"",
    "longitude": 17.05744,
    "latitude": 47.63222
  },
  {
    "id": 120,
    "name": "KOVÁCS VERONIKA",
    "address": "\"SZÉCHENYI U. 52, SZEGHALOM, 5520, Hungary\"",
    "longitude": 21.09762,
    "latitude": 47.33996
  },
  {
    "id": 121,
    "name": "O3 OPTIKA KFT.",
    "address": "\"SZÉPVÖLGYI ÚT 49-61. C. ÉP. 5., BUDAPEST, 1037, Hungary\"",
    "longitude": 19.03928,
    "latitude": 47.55144
  },
  {
    "id": 122,
    "name": "Optic World Exclusive- Soroksár A.",
    "address": "\"Bevásárló utca 2. Auchan, Budapest, 1239, Hungary\"",
    "longitude": 19.10715,
    "latitude": 47.39199
  },
  {
    "id": 123,
    "name": "Sifra Kft  (OWE)",
    "address": "\"Liliom u. 49/A., Nyíregyháza, 4400, Hungary\"",
    "longitude": 21.72367,
    "latitude": 47.95947
  },
  {
    "id": 124,
    "name": "SIMÁK GYÖRGYNÉ E.V.",
    "address": "\"SZENT LÁSZLÓ U. 24., KISVÁRDA, 4600, Hungary\"",
    "longitude": 21.56432,
    "latitude": 48.10704
  },
  {
    "id": 125,
    "name": "SUN and EYE Kft.",
    "address": "\"Váci utca 9. fsz. 7, Budapest, 1052, Hungary\"",
    "longitude": 19.05056,
    "latitude": 47.49465
  },
  {
    "id": 126,
    "name": "WOLF'S EYE KFT",
    "address": "\"FELSŐ SZANDAI RÉT 1., SZOLNOK, 5000, Hungary\"",
    "longitude": 20.18588,
    "latitude": 47.18055
  },
  {
    "id": 127,
    "name": "VOONZ KFT",
    "address": "\"KOSSUTH U. 4., KESZTHELY, 8360, Hungary\"",
    "longitude": 17.23814,
    "latitude": 46.77034
  },
  {
    "id": 128,
    "name": "Optic World Exclusive-Árkád Plaza",
    "address": "\"Örs vezér tere 25/A Árkád Plaza, Budapest, 1106, Hungary\"",
    "longitude": 19.1254,
    "latitude": 47.51492
  },
  {
    "id": 129,
    "name": "HATALYÁK ÉS TÁRSA KFT",
    "address": "\"KOSSUTH LAJOS UTCA 34., ASZÓD, 2170, Hungary\"",
    "longitude": 19.31015,
    "latitude": 47.62431
  },
  {
    "id": 130,
    "name": "PG ÍRISZ KFT.",
    "address": "\"KOSSUTH UTCA 4., HOSSZÚVÖLGY, 8777, Hungary\"",
    "longitude": 20.07645,
    "latitude": 47.52001
  },
  {
    "id": 131,
    "name": "Optic World Exclusive- Győr Árkád",
    "address": "\"Budai út 1. Győr Árkád, Győr, 9027, Hungary\"",
    "longitude": 17.62815,
    "latitude": 47.6744
  },
  {
    "id": 132,
    "name": "KONTAKT BT",
    "address": "\"KOSSUTH L. U. 30, KESZTHELY, 8360, Hungary\"",
    "longitude": 17.23814,
    "latitude": 46.77034
  },
  {
    "id": 133,
    "name": "OPTIKA SOLE KFT",
    "address": "\"OLCSAY TÉR 2. 3. EM. 14., KŐRMEND, 9900, Hungary\"",
    "longitude": 16.99752,
    "latitude": 47.17872
  },
  {
    "id": 134,
    "name": "Optic World Exclusive- Allee",
    "address": "\"Október 23. utca 8-10. Allee, Budapest, 1117, Hungary\"",
    "longitude": 19.04005,
    "latitude": 47.4856
  },
  {
    "id": 135,
    "name": "ANDROVER 2000 BT.",
    "address": "\"ANDRÁSSY GYULA U. 59, KEREKEGYHÁZA, 6041, Hungary\"",
    "longitude": 17.65416,
    "latitude": 47.62762
  },
  {
    "id": 136,
    "name": "KRAFT PÉTER",
    "address": "\"KÁLVIN TÉR 1., BUDAPEST, 1053, Hungary\"",
    "longitude": 17.23814,
    "latitude": 46.77034
  },
  {
    "id": 137,
    "name": "PAN-OPTIKA KFT.",
    "address": "\"HÁTSÓKAPU 8., SOPRON, 9400, Hungary\"",
    "longitude": 16.58014,
    "latitude": 47.68607
  },
  {
    "id": 138,
    "name": "KÓBOR OPTIKA BT.",
    "address": "\"Szent István út 17, Gyömérő, 2230, Hungary\"",
    "longitude": 17.52827,
    "latitude": 47.60159
  },
  {
    "id": 139,
    "name": "FÜLÖP NÁNDOR",
    "address": "\"LUCSONY UTAK 5., MOSONMAGYARÓVÁR, 9200, Hungary\"",
    "longitude": 17.23922,
    "latitude": 47.8747
  },
  {
    "id": 140,
    "name": "Optic World Exclusive-Dunakeszi A.",
    "address": "\"Nádas utca 6. Auchan, Dunakeszi, 2120, Hungary\"",
    "longitude": 19.10715,
    "latitude": 47.39199
  },
  {
    "id": 141,
    "name": "VINTAGE ONE KFT.",
    "address": "\"KÁRPÁT UTCA 37. 3. EM. 11, BUDAPEST, 1133, Hungary\"",
    "longitude": 19.04912,
    "latitude": 47.51224
  },
  {
    "id": 142,
    "name": "PETŐ OPTIKA KFT.",
    "address": "\"SZABADSÁG TÉR 20., AJKA, 8400, Hungary\"",
    "longitude": 17.56715,
    "latitude": 47.10008
  },
  {
    "id": 143,
    "name": "Optic World Exclusive-Szeged Árkád",
    "address": "\"Londoni körút 3. Árkád, Szeged, 6724, Hungary\"",
    "longitude": 20.14568,
    "latitude": 46.24891
  },
  {
    "id": 144,
    "name": "Optic World Exclusive- Pécs Árkád",
    "address": "\"Bajcsy-Zsilinszky utca 11. Árkád, Pécs, 7622, Hungary\"",
    "longitude": 18.23714,
    "latitude": 46.08223
  },
  {
    "id": 145,
    "name": "SIGMA 9 KFT.",
    "address": "\"HOCK JÁNOS KÖZ  2., SIÓFOK, 8600, Hungary\"",
    "longitude": 18.0403,
    "latitude": 46.9091
  },
  {
    "id": 146,
    "name": "GYÖNGYSZEM OPTIKA KFT.",
    "address": "\"Bajnok utca 4/B, SZOLNOK, 5000, Hungary\"",
    "longitude": 20.15169,
    "latitude": 46.28504
  },
  {
    "id": 147,
    "name": "MÁTÉ FOTÓ-OPTIKA KFT.",
    "address": "\"SZTARAVODAI ÚT 54., SZENTENDRE, 2000, Hungary\"",
    "longitude": 19.06316,
    "latitude": 47.6658
  },
  {
    "id": 148,
    "name": "JUNOPTI93 KFT. JUNIOR OPTIKA",
    "address": "\"SZÉCHENYI U. 2., BÉKÉSCSABA, 5600, Hungary\"",
    "longitude": 21.10161,
    "latitude": 46.6847
  },
  {
    "id": 149,
    "name": "Optic World Exclusive-Fehér.AlbaPla",
    "address": "\"Palotai út 1., Székesfehérvár, 8000, Hungary\"",
    "longitude": 18.42018,
    "latitude": 47.19901
  },
  {
    "id": 150,
    "name": "TITANOVA KFT. TITÁN OPTIKA",
    "address": "\"József A u. 18., Tiszalök, 4450, Hungary\"",
    "longitude": 21.22584,
    "latitude": 47.87608
  },
  {
    "id": 151,
    "name": "Optic World Exclusive- Deb.Tesco",
    "address": "\"Kishegyesi út 1-11. Tesco, Debrecen, 4031, Hungary\"",
    "longitude": 21.64452,
    "latitude": 47.54085
  },
  {
    "id": 152,
    "name": "FENYVESSY OPTIKA BT.",
    "address": "\"Rákóczi Ferenc út 312., Balatonlelle, 8638, Hungary\"",
    "longitude": 17.67926,
    "latitude": 46.77634
  },
  {
    "id": 153,
    "name": "Optic World Exclusive- Corvin Pláza",
    "address": "\"Futó utca 37. Corvin Pláza, Budapest, 1083, Hungary\"",
    "longitude": 19.09023,
    "latitude": 47.48845
  },
  {
    "id": 154,
    "name": "ARANYSZEM OPTIKA BT.",
    "address": "\"TÓVÁROSI KÖRZŐ 2., TATA, 2890, Hungary\"",
    "longitude": 18.3692,
    "latitude": 47.6481
  },
  {
    "id": 155,
    "name": "BÖHMNÉ KOVÁCS MAGDOLNA",
    "address": "\"BERZSENYI DÁNIEL ÚT 23., SÁRBOGÁRD, 7000, Hungary\"",
    "longitude": 17.56609,
    "latitude": 46.72068
  },
  {
    "id": 156,
    "name": "FEJÉR ANIKÓ",
    "address": "\"IGMÁNDI ÚT 23., KOMÁROM, 2900, Hungary\"",
    "longitude": 18.13993,
    "latitude": 47.7519
  },
  {
    "id": 157,
    "name": "Optic World Exclusive-KaposvárTesco",
    "address": "\"Berzsenyi utca 13., Kaposvár, 7400, Hungary\"",
    "longitude": 17.7973,
    "latitude": 46.36702
  },
  {
    "id": 158,
    "name": "CVIKKER OPTIKA KFT.",
    "address": "\"SZAPÁRY ÚT. 18, SZOLNOK, 5000, Hungary\"",
    "longitude": 20.18826,
    "latitude": 47.17952
  },
  {
    "id": 159,
    "name": "GRE-KO HORIZON BT.",
    "address": "\"CIGÁNY UTCA 75., NAGYKANIZSA, 8800, Hungary\"",
    "longitude": 16.984,
    "latitude": 46.4486
  },
  {
    "id": 160,
    "name": "F.M. OPTIKA KFT.",
    "address": "\"BATTHYÁNY U. 9., CELLDÖMÖLK, 9500, Hungary\"",
    "longitude": 16.9285,
    "latitude": 47.2264
  },
  {
    "id": 161,
    "name": "KURGYISNÉ PECZNYIK GYÖRGYI",
    "address": "\"RÁKOS U. 2., PÉCEL, 2119, Hungary\"",
    "longitude": 19.314547,
    "latitude": 47.585337
  },
  {
    "id": 162,
    "name": "Zoom Optika Kft.",
    "address": "\"BÉCSI ÚT 55., DOROG, 2510, Hungary\"",
    "longitude": 18.759897,
    "latitude": 47.702957
  },
  {
    "id": 163,
    "name": "Optic World Exclusive- Arena Mall",
    "address": "\"Kerepesi út 9. Arena Mall, Budapest, 1087, Hungary\"",
    "longitude": 19.116103,
    "latitude": 47.502392
  },
  {
    "id": 164,
    "name": "SZINVA OPTIKA KFT.",
    "address": "\"VÁROSHÁZ TÉR 20., MISKOLC, 3530, Hungary\"",
    "longitude": 20.790031,
    "latitude": 48.106265
  },
  {
    "id": 165,
    "name": "Optic World Exclusive-Kecskem.Malom",
    "address": "\"Korona utca 2., Kecskemét, 6000, Hungary\"",
    "longitude": 19.68865,
    "latitude": 46.9023
  },
  {
    "id": 166,
    "name": "PRÁGAI LÁTSZERÉSZETI KFT.",
    "address": "\"BARTÓK B. TÉR 11., SZEGED, 6722, Hungary\"",
    "longitude": 20.148897,
    "latitude": 46.257354
  },
  {
    "id": 167,
    "name": "Optic World Exclusive-Debr.Auchan",
    "address": "\"Kishatár utca 7, Debrecen, 4031, Hungary\"",
    "longitude": 21.6636,
    "latitude": 47.5392
  },
  {
    "id": 168,
    "name": "Optic World Exclusive-Fehérv.Auchan",
    "address": "\"Holland fasor 2., Székesfehérvár, 8000, Hungary\"",
    "longitude": 18.42588,
    "latitude": 47.18746
  },
  {
    "id": 169,
    "name": "SZŐKE-OPTIKA KFT.",
    "address": "\"RÉTKÖZ U. 7. I/EM, BUDAPEST, 1118, Hungary\"",
    "longitude": 19.0115,
    "latitude": 47.4583
  },
  {
    "id": 170,
    "name": "ESZIK OPTIKA KFT.",
    "address": "\"TISZA LAJOS KÖRÚT 75., SZEGED, 6720, Hungary\"",
    "longitude": 20.150774,
    "latitude": 46.250038
  },
  {
    "id": 171,
    "name": "OPTIK-TRADE KFT.",
    "address": "\"BAROSS G. U. 33., BALATONALMÁDI, 8220, Hungary\"",
    "longitude": 17.940551,
    "latitude": 46.925571
  },
  {
    "id": 172,
    "name": "SASVÁRI KFT",
    "address": "\"Hadak útja 1, Budapest, 1119, Hungary\"",
    "longitude": 19.50029,
    "latitude": 47.42011
  },
  {
    "id": 173,
    "name": "Optic World Exclusive-Sió Pláza",
    "address": "\"Fő tér 6. Sió Pláza, Siófok, 8600, Hungary\"",
    "longitude": 18.049772,
    "latitude": 46.905004
  },
  {
    "id": 174,
    "name": "SZATMÁRI RÓBERT",
    "address": "\"BOCSKAI U. 33, SOLTVADKERT, 6230, Hungary\"",
    "longitude": 18.698291,
    "latitude": 46.461574
  },
  {
    "id": 175,
    "name": "OPTOPONT KFT.",
    "address": "\"KASSÁK L U. 72., BUDAPEST, 1134, Hungary\"",
    "longitude": 19.090562,
    "latitude": 47.515159
  },
  {
    "id": 176,
    "name": "MOLNÁR PÉTER EV.",
    "address": "\"BUDAI U. 19., BÁTASZÉK, 7140, Hungary\"",
    "longitude": 18.718575,
    "latitude": 46.263007
  },
  {
    "id": 177,
    "name": "Optic World Exclusive-Szolnok",
    "address": "\"Ady Endre út 28/A, Szolnok, 5000, Hungary\"",
    "longitude": 20.18922,
    "latitude": 47.17974
  },
  {
    "id": 178,
    "name": "Optic World Exclusive-SolymárAuchan",
    "address": "\"Szent Flórián utca 2-4., Solymár, 2083, Hungary\"",
    "longitude": 18.9803,
    "latitude": 47.5792
  },
  {
    "id": 179,
    "name": "MOROPEX Trade Kft",
    "address": "\"Szekszárdi u. 17. Ú-3., Budapest, 1138, Hungary\"",
    "longitude": 19.064102,
    "latitude": 47.555576
  },
  {
    "id": 180,
    "name": "MATÓK IPARI ÉS KERESK.KFT",
    "address": "\"SZÉCHÉNYI U. 28., SZEKSZÁRD, 7100, Hungary\"",
    "longitude": 18.698833,
    "latitude": 46.345436
  },
  {
    "id": 181,
    "name": "VOJTH OPTIKA KFT.",
    "address": "\"RÁKÓCZI ÚT 25., SÁROSPATAK, 3950, Hungary\"",
    "longitude": 21.5687308,
    "latitude": 48.3196929
  },
  {
    "id": 182,
    "name": "MARGOPTIK KFT.",
    "address": "\"ZRÍNYI U. 8-10., NYÍREGYHÁZA, 4400, Hungary\"",
    "longitude": 21.757045,
    "latitude": 47.950201
  },
  {
    "id": 183,
    "name": "Tisza Látáscentrum (Almádi-",
    "address": "\"Batthyány u.35., Szeged, 6722, Hungary\"",
    "longitude": 20.150978,
    "latitude": 46.255797
  },
  {
    "id": 184,
    "name": "SZIVÁRVÁNY OPTIKA CENTRUM KFT",
    "address": "\"DÓZSA GYÖRGY ÚT 26., NAGYKÁTA, 2760, Hungary\"",
    "longitude": 19.73378,
    "latitude": 47.29961
  },
  {
    "id": 185,
    "name": "SOMOS OPTIKA KFT.",
    "address": "\"VARASD ÚT 1., SZOMBATHELY, 9700, Hungary\"",
    "longitude": 16.628493,
    "latitude": 47.237936
  },
  {
    "id": 186,
    "name": "SASVÁRI KFT",
    "address": "\"Deres u. 2., Vác, 2600, Hungary\"",
    "longitude": 19.123292,
    "latitude": 47.784108
  },
  {
    "id": 187,
    "name": "Optic World Exclusive-Eger Tesco",
    "address": "\"II. Rákóczi Ferenc utca 100. Tesco, Eger, 3300, Hungary\"",
    "longitude": 20.370119,
    "latitude": 47.900637
  },
  {
    "id": 188,
    "name": "TŐRÖSNÉ PUSKEL KRISZTINA",
    "address": "\"VASÚT U. 7., ALBERTIRSA, 2730, Hungary\"",
    "longitude": 20.60156,
    "latitude": 47.1806
  },
  {
    "id": 189,
    "name": "Optic World Exclusive- KöKi",
    "address": "\"Vak Bottyán utca 75/C KöKi Terminál, Budapest, 1191, Hungary\"",
    "longitude": 19.168378,
    "latitude": 47.450591
  },
  {
    "id": 190,
    "name": "Optic World Exclusive-Óbuda Auchan",
    "address": "\"Szentendrei út 115., Budapest, 1033, Hungary\"",
    "longitude": 19.060001,
    "latitude": 47.5546
  },
  {
    "id": 191,
    "name": "Smartoptika - web",
    "address": "\"Csányi László utca 34., Budapest, 1043, Hungary\"",
    "longitude": 19.139956,
    "latitude": 47.568604
  },
  {
    "id": 192,
    "name": "SZ-G Rapidus Kft.",
    "address": "\"Vasvári Pál utca 27., Budapest, 1155, Hungary\"",
    "longitude": 19.176075,
    "latitude": 47.487875
  },
  {
    "id": 193,
    "name": "SASVÁRI KFT",
    "address": "\"Deák Ferenc tér 1., Zalaegerszeg, 8900, Hungary\"",
    "longitude": 16.83727,
    "latitude": 46.84088
  },
  {
    "id": 194,
    "name": "Optic World Exclusive- Lurdy Ház",
    "address": "\"Könyves Kálmán körút 12-14. Lurdy, Budapest, 1097, Hungary\"",
    "longitude": 19.1048,
    "latitude": 47.4637
  },
  {
    "id": 195,
    "name": "OPTIKSTUDIO KFT.",
    "address": "\"KORÁNYI-MORGÓ U. 29268 HRSZ., NYÍREGYHÁZA, 4400, Hungary\"",
    "longitude": 21.725357,
    "latitude": 47.97453
  },
  {
    "id": 196,
    "name": "SZEMFÉNY CSALÁDI SZEMÉSZETI KFT.",
    "address": "\"HOLENDA BARNABÁS UTCA 1. FSZ 12, GYŐR, 9024, Hungary\"",
    "longitude": 17.64326,
    "latitude": 47.67692
  },
  {
    "id": 197,
    "name": "FONTANA OPTIKA KFT.",
    "address": "\"ADY ENDRE ÚT 5., HÓDMEZŐVÁSÁRHELY, 6800, Hungary\"",
    "longitude": 20.145454,
    "latitude": 46.406078
  },
  {
    "id": 198,
    "name": "PRZECIWSLONECZNE.PL",
    "address": "\"UL. SIENKIEWICZA 22, BIAŁYSTOK, 15-092, Poland\"",
    "longitude": 23.168733,
    "latitude": 53.138281
  },
  {
    "id": 199,
    "name": "OPTYK \"KAROLINA\" S.C.",
    "address": "\"UL. DOBRAWY 6, PSZCZYNA, 43-200, Poland\"",
    "longitude": 18.94815,
    "latitude": 49.9925
  },
  {
    "id": 200,
    "name": "SALON OPTYCZNY OPTIMISTIC OPTYK",
    "address": "\"UL. ZAKOPIAŃSKA 62, KRAKÓW, 31-418, Poland\"",
    "longitude": 19.9418,
    "latitude": 50.0541
  },
  {
    "id": 201,
    "name": "STUDIO OPTYCZNE 44 DESIGNER OUTLET",
    "address": "\"UL. ORLAT LWOWSKICH 138, SOSNOWIEC, 41-208, Poland\"",
    "longitude": 19.099459,
    "latitude": 50.291722
  },
  {
    "id": 202,
    "name": "ABRETE SP. Z O.O.",
    "address": "\"4/1, PANCERZYN, 14-106, Poland\"",
    "longitude": 23.268617,
    "latitude": 53.666264
  },
  {
    "id": 203,
    "name": "CENTRUM OPTYCZNE KRZYSZTOF",
    "address": "\"Grunwaldzka, nr 31, Płońsk, 09-100, Poland\"",
    "longitude": 20.376425,
    "latitude": 52.617484
  },
  {
    "id": 204,
    "name": "PRACOWNIA OPTYCZNA RAFAŁ STARSKI",
    "address": "\"UL. KOŚCIUSZKI 32, CZARNKÓW, 64-700, Poland\"",
    "longitude": 16.558463,
    "latitude": 52.898318
  },
  {
    "id": 205,
    "name": "SPÓŁKA CYWILNA RENATA KARAŚ",
    "address": "\"UL. TADEUSZA KOŚCIUSZKI 1, TARNÓW, 33-100, Poland\"",
    "longitude": 20.988454,
    "latitude": 50.010035
  },
  {
    "id": 206,
    "name": "SALONY OPTYCZNE FRAS S.C.",
    "address": "\"Ul. Stary Rynek 11, Bydgoszcz, 85-105, Poland\"",
    "longitude": 18.000251,
    "latitude": 53.123661
  },
  {
    "id": 207,
    "name": "ZAKŁAD OPTYCZNY MAREK RYFA",
    "address": "\"UL. GRUNWALDZKA 82, GDAŃSK, 80-254, Poland\"",
    "longitude": 18.63427,
    "latitude": 54.384216
  },
  {
    "id": 208,
    "name": "LISZEWSKI PATRYK MAARCO",
    "address": "\"UL. LĘDZIŃSKA 14, LĘDZINY, 43-143, Poland\"",
    "longitude": 18.962118,
    "latitude": 50.135222
  },
  {
    "id": 209,
    "name": "KRYSTYNA POKORNA-STAFIN ZAKŁAD",
    "address": "\"UL. KOLEJOWA 30 C, DĘBICA, 39-200, Poland\"",
    "longitude": 21.415162,
    "latitude": 50.052787
  },
  {
    "id": 210,
    "name": "OPTYK OKULISTA SIGNUM KUSTRZĘPA",
    "address": "\"UL. BAŻANTÓW 6C, KATOWICE, 40-668, Poland\"",
    "longitude": 18.982539,
    "latitude": 50.260472
  },
  {
    "id": 211,
    "name": "ZAKŁAD OPTYKI OKULAROWEJ",
    "address": "\"UL. OBROŃCÓW WESTERPLATTE 8, KATOWICE, 40-336, Poland\"",
    "longitude": 19.025427,
    "latitude": 50.264529
  },
  {
    "id": 212,
    "name": "LUXVISION BEATA KWAPISZ  ŁUKASZ",
    "address": "\"UL. SZOSA ZAMBROWSKA 102 LOK.2, ŁOMŻA, 18-400, Poland\"",
    "longitude": 22.057451,
    "latitude": 53.178725
  },
  {
    "id": 213,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Legnicka 5, Kraków, 31-216, Poland\"",
    "longitude": 17.042162,
    "latitude": 50.047383
  },
  {
    "id": 214,
    "name": "OPTYK-DUBLET TOMASZ KOBAN, JOANNA",
    "address": "\"UL. IWASZKIEWICZA 133B, OŁAWA, 55-200, Poland\"",
    "longitude": 17.303256,
    "latitude": 50.943205
  },
  {
    "id": 215,
    "name": "OPTYK - OPTOMETRYSTA S.C.",
    "address": "\"UL. 3 MAJA 11, GORLICE, 38-300, Poland\"",
    "longitude": 21.695283,
    "latitude": 49.728192
  },
  {
    "id": 216,
    "name": "ZAKŁAD OPTYCZNY MIROSŁAW RUSZCZYK",
    "address": "\"UL. E. ORZESZKOWEJ 36/3, EŁK, 19-300, Poland\"",
    "longitude": 22.378574,
    "latitude": 53.834922
  },
  {
    "id": 217,
    "name": "ZAKŁAD OPTYCZNY \"OPTIX-2\" S.C.",
    "address": "\"UL. HIPOTECZNA 1, KIELCE, 25-350, Poland\"",
    "longitude": 20.623665,
    "latitude": 50.875471
  },
  {
    "id": 218,
    "name": "OPTYK ŁÓDKA",
    "address": "\"ul.Piotrkowska 153, Łódź, 90-440, Poland\"",
    "longitude": 19.454746,
    "latitude": 51.768741
  },
  {
    "id": 219,
    "name": "ZIKO OPTYK",
    "address": "\"UL. CZERWONE MAKI 33 /CH ATUT/, KRAKÓW, 30-392, Poland\"",
    "longitude": 19.994002,
    "latitude": 50.028194
  },
  {
    "id": 220,
    "name": "ZIKO OPTYK",
    "address": "\"UL. GRAŻYNY 4, KRAKÓW, 31-217, Poland\"",
    "longitude": 19.973004,
    "latitude": 50.058001
  },
  {
    "id": 221,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Królewska 47, Kraków, 30-040, Poland\"",
    "longitude": 19.937203,
    "latitude": 50.062405
  },
  {
    "id": 222,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Siwińskiego 2A, Legionowo, 05-120, Poland\"",
    "longitude": 20.946676,
    "latitude": 52.378737
  },
  {
    "id": 223,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Wspólna 1, Olkusz, 32-300, Poland\"",
    "longitude": 19.566368,
    "latitude": 50.267946
  },
  {
    "id": 224,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Żeromskiego 15, Mielec, 39-300, Poland\"",
    "longitude": 21.423722,
    "latitude": 50.288268
  },
  {
    "id": 225,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Jesionowa 4a, Myszków, 42-300, Poland\"",
    "longitude": 19.313557,
    "latitude": 50.59207
  },
  {
    "id": 226,
    "name": "ZOOM ARTUR MADEJ",
    "address": "\"UL. NARUTOWICZA 74, LUBLIN, 20-013, Poland\"",
    "longitude": 22.570061,
    "latitude": 51.24634
  },
  {
    "id": 227,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Modrzejowska 16, SOSNOWIEC, 41-200, Poland\"",
    "longitude": 19.110409,
    "latitude": 50.279753
  },
  {
    "id": 228,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Armii Krajowej 43, KATOWICE, 40-698, Poland\"",
    "longitude": 19.004859,
    "latitude": 50.251117
  },
  {
    "id": 229,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Składowa 4, Gniezno, 62-200, Poland\"",
    "longitude": 17.595413,
    "latitude": 52.53089
  },
  {
    "id": 230,
    "name": "ZIKO OPTYK",
    "address": "\"ul. Kolejowa 1, Konin, 62-510, Poland\"",
    "longitude": 18.24621,
    "latitude": 52.218963
  },
  {
    "id": 231,
    "name": "WOJCIECH SKRZATEK F.H.WSKRZAT",
    "address": "\"ZAMIEŚCIE 201, TYMBARK, 34-650, Poland\"",
    "longitude": 20.119083,
    "latitude": 49.473783
  },
  {
    "id": 232,
    "name": "SALON OPTYCZNY TIMAK",
    "address": "\"UL. RZEŹNICKA10-11B, ELBLĄG, 82-300, Poland\"",
    "longitude": 19.427469,
    "latitude": 54.168075
  },
  {
    "id": 233,
    "name": "ZAKŁAD USŁUGOWO-HANDLOWY \"OPTYK\"",
    "address": "\"UL. NIEDURNEGO 53, RUDA ŚLĄSKA, 41-709, Poland\"",
    "longitude": 18.856809,
    "latitude": 50.249263
  },
  {
    "id": 234,
    "name": "Zielonka Optyk sp. z o.o.",
    "address": "\"UL. TADEUSZA KOŚCIUSZKI 16, KARTUZY, 83-300, Poland\"",
    "longitude": 18.196014,
    "latitude": 54.33506
  },
  {
    "id": 235,
    "name": "OPTYK KOSIŃSKI SP. z o.o.",
    "address": "\"ul. SZEROKA 11, TARNOBRZEG, 39-400, Poland\"",
    "longitude": 20.988025,
    "latitude": 50.580668
  },
  {
    "id": 236,
    "name": "MARITA GNAT-JURKOJĘ OPTYK - BIFOCAL",
    "address": "\"UL. 5 STYCZNIA 38, WOLSZTYN, 64-200, Poland\"",
    "longitude": 16.112294,
    "latitude": 52.110839
  },
  {
    "id": 237,
    "name": "ANDŻELIKA MICHALSKA SALON OPTYCZNY",
    "address": "\"UL. STANISŁAWA MONIUSZKI 2 LOK.5, KONIN, 62-510, Poland\"",
    "longitude": 18.248743,
    "latitude": 52.240154
  },
  {
    "id": 238,
    "name": "GABINET OKULISTYCZNY LEK.",
    "address": "\"ul. Jeziorna 7J, Międzylesie, 14-100, Poland\"",
    "longitude": 16.643309,
    "latitude": 52.40645
  },
  {
    "id": 239,
    "name": "ZAKŁAD OPTYCZNY PIOTR SZAJRYCH",
    "address": "\"UL. POZNAŃSKA 11, ŁĘCZYCA, 99-100, Poland\"",
    "longitude": 19.236727,
    "latitude": 51.85552
  },
  {
    "id": 240,
    "name": "KJMH SPÓŁKA Z OGRANICZONĄ",
    "address": "\"AL. JÓZEFA PIŁSUDSKIEGO 104B LOK.8, NOWY SĄCZ, 33-300, Poland\"",
    "longitude": 20.715651,
    "latitude": 49.601758
  },
  {
    "id": 241,
    "name": "\"SALUS - USŁUGI\" SP. Z O.O.",
    "address": "\"Ul. Zawiszy Czarnego 2, Kłodzko, 57-300, Poland\"",
    "longitude": 16.659822,
    "latitude": 50.434943
  },
  {
    "id": 242,
    "name": "OPTYKA OKULAROWA - EWELINA",
    "address": "\"OS. ROBOTNICZE 38/1B, JELENIA GÓRA, 58-506, Poland\"",
    "longitude": 15.742416,
    "latitude": 50.904818
  },
  {
    "id": 243,
    "name": "SALON OPTYCZNY OKO ARTUR PRZĄDKA",
    "address": "\"UL. WYSZYŃSKIEGO 1, ZAMOŚĆ, 22-400, Poland\"",
    "longitude": 23.264891,
    "latitude": 50.719017
  },
  {
    "id": 244,
    "name": "PRAKTYKA LEKARSKA ANDRZEJ NIEMCZUK",
    "address": "\"ul. Wniebowstąpienia 13/L2, Wejherowo, 84-200, Poland\"",
    "longitude": 18.2323764,
    "latitude": 54.6144975
  },
  {
    "id": 245,
    "name": "ZAKŁAD OPTYCZNY MARTA POST- RYBAK",
    "address": "\"UL. ŁASKA 38A, PABIANICE, 95-200, Poland\"",
    "longitude": 19.417624,
    "latitude": 51.67805
  },
  {
    "id": 246,
    "name": "ZAKŁAD OPTYCZNY WŁODZIMIERZ DĘBICKI",
    "address": "\"UL. OPATOWSKA 13, SANDOMIERZ, 27-600, Poland\"",
    "longitude": 21.7547281,
    "latitude": 50.6863658
  },
  {
    "id": 247,
    "name": "Okularowo Centrum Optyczne",
    "address": "\"Ul. św. Antoniego 4/2, Tomaszów Mazowiecki, 97-200, Poland\"",
    "longitude": 20.025892,
    "latitude": 51.52683
  },
  {
    "id": 248,
    "name": "Vision Optyk Monika Rola",
    "address": "\"ul. płk. Władysława Beliny-Prażmows, Nowy Sącz, 33-300, Poland\"",
    "longitude": 20.715651,
    "latitude": 49.601758
  },
  {
    "id": 249,
    "name": "ZAKŁAD OPTYCZNY PIOTR HARATYM",
    "address": "\"UL. POCZTOWA 28A lok. 39, CHEŁM, 22-100, Poland\"",
    "longitude": 23.4605845,
    "latitude": 51.1407962
  },
  {
    "id": 250,
    "name": "SALON OPTYCZNY \"INTER-OPTICA II\"",
    "address": "\"UL. KOŚCIUSZKI 6, GRYBÓW, 33-330, Poland\"",
    "longitude": 20.877235,
    "latitude": 49.651782
  },
  {
    "id": 251,
    "name": "LYNX CH PORT RUMIA AUCHAN",
    "address": "\"UL. GRUNWALDZKA 108, RUMIA, 84-230, Poland\"",
    "longitude": 18.4203171,
    "latitude": 54.5852843
  },
  {
    "id": 252,
    "name": "RED OPTIK IWONA WŁOCH",
    "address": "\"ul. Zdrojowa 8, Świeradów-Zdrój, 59-850, Poland\"",
    "longitude": 15.3970944,
    "latitude": 50.9020561
  },
  {
    "id": 253,
    "name": "ZAKŁAD OPTYCZNY ANNA GROEHLICH",
    "address": "\"UL. KOŚCIELNA 28B, SIEMIANOWICE ŚLĄSKIE, 41-103, Poland\"",
    "longitude": 19.0270199,
    "latitude": 50.3277663
  },
  {
    "id": 254,
    "name": "SALON OPTYCZNY SFERIS-OPTYK S.C.",
    "address": "\"UL. PIŁSUDSKIEGO 26/2, KWIDZYN, 82-500, Poland\"",
    "longitude": 18.929962,
    "latitude": 53.732032
  },
  {
    "id": 255,
    "name": "GOMEZ SPÓŁKA AKCYJNA",
    "address": "\"UL. TOMASZA DROBNIKA 2, POZNAŃ, 60-693, Poland\"",
    "longitude": 16.8791193,
    "latitude": 52.3958256
  },
  {
    "id": 256,
    "name": "P.M.WYDRYCH SP. Z O.O.",
    "address": "\"ul. Bodzętyńska 24 lok. 26, KIELCE, 25-308, Poland\"",
    "longitude": 20.6284451,
    "latitude": 50.8743624
  },
  {
    "id": 257,
    "name": "PRZEDSIĘBIORSTWO \"CISZEWSKI\" S.C",
    "address": "\"UL. STAROMIEJSKA 41, LĘBORK, 84-300, Poland\"",
    "longitude": 17.7473136,
    "latitude": 54.5518893
  },
  {
    "id": 258,
    "name": "BEATA BRZEGOWY-NĘDZA FIRMA HANDLOWO",
    "address": "\"UL. BIEŻANOWSKA 23, KRAKÓW, 30-812, Poland\"",
    "longitude": 19.9384335,
    "latitude": 50.0176481
  },
  {
    "id": 259,
    "name": "OPTYK OKULARNIK KAROLINA",
    "address": "\"UL. A. GRZYMAŁY-SIEDLECKIEGO 26, BYDGOSZCZ, 85-868, Poland\"",
    "longitude": 18.0070376,
    "latitude": 53.115479
  },
  {
    "id": 260,
    "name": "DOBRY OPTYK MONIKA KRAWCZYK",
    "address": "\"UL. KOLEJOWA 17, ŚWIEBODZIN, 66-200, Poland\"",
    "longitude": 15.537761,
    "latitude": 52.239626
  },
  {
    "id": 261,
    "name": "USŁUGOWY ZAKŁAD OPTYCZNY",
    "address": "\"UL.MARSZAŁKOWSKA 55/73, WARSZAWA, 00-676, Poland\"",
    "longitude": 21.012414,
    "latitude": 52.22977
  },
  {
    "id": 262,
    "name": "BENIAMIN SZEWCZYK HURTOWNIA",
    "address": "\"UL. POR. BOLESŁAWA SZPUNARA 14, WIELICZKA, 32-020, Poland\"",
    "longitude": 20.0663811,
    "latitude": 49.9920367
  },
  {
    "id": 263,
    "name": "PRACOWNIA OPTYCZNA MACIEJ LISZKA",
    "address": "\"UL. WALOŃSKA 19/U2, WROCŁAW, 50-413, Poland\"",
    "longitude": 17.0571572,
    "latitude": 51.120697
  },
  {
    "id": 264,
    "name": "OPTYKA VICTORIA S.C.",
    "address": "\"UL. PIŁSUDSKIEGO 12, ZAWIERCIE, 42-400, Poland\"",
    "longitude": 19.419946,
    "latitude": 50.487446
  },
  {
    "id": 265,
    "name": "CENTRUM OPTYCZNE AGNIESZKA",
    "address": "\"UL. BARWNA 22 LOK.1, MIERZYN, 72-006, Poland\"",
    "longitude": 15.6941174,
    "latitude": 53.171762
  },
  {
    "id": 266,
    "name": "EYEHOLIC SP. Z.O.O.",
    "address": "\"UL. CZEKOLADOWA 7-9, BIELANY WROCŁAWSKIE, 55-040, Poland\"",
    "longitude": 16.891916,
    "latitude": 51.123896
  },
  {
    "id": 267,
    "name": "CENTRUM OPTYCZNO OKULISTYCZNE",
    "address": "\"UL. ŚWIĘTOJAŃSKA 73, GDYNIA, 81-389, Poland\"",
    "longitude": 18.5400125,
    "latitude": 54.5187378
  },
  {
    "id": 268,
    "name": "OPTYK na Letniej",
    "address": "\"UL. LETNIA 20 LOK.5, KŁODZKO, 57-300, Poland\"",
    "longitude": 16.659822,
    "latitude": 50.434943
  },
  {
    "id": 269,
    "name": "OŚRODEK KOREKCJI WZROKU SP. Z O.O.",
    "address": "\"UL. PRÓCHNIKA 1, ŁÓDŹ, 90-408, Poland\"",
    "longitude": 19.432012,
    "latitude": 51.759248
  },
  {
    "id": 270,
    "name": "OPTYK W RYNKU BARTOSZ BYTNER",
    "address": "\"UL. RYNEK 7, GŁOGÓWEK, 48-250, Poland\"",
    "longitude": 18.975804,
    "latitude": 50.2940537
  },
  {
    "id": 271,
    "name": "OPTICOS S.C.",
    "address": "\"UL. MŁYŃSKA 9, CHOJNICE, 89-600, Poland\"",
    "longitude": 17.5626864,
    "latitude": 53.7136363
  },
  {
    "id": 272,
    "name": "RADOSŁAW PRZYBYLSKI \"OPTYK\"",
    "address": "\"UL. CIESZKOWSKIEGO 38, SWARZĘDZ, 62-020, Poland\"",
    "longitude": 17.0938571,
    "latitude": 52.3075764
  },
  {
    "id": 273,
    "name": "OCULAR CANADA 2 SP. Z O.O.",
    "address": "\"UL. PIŁSUDSKIEGO 44 / GAL. RZESZÓW, RZESZÓW, 35-001, Poland\"",
    "longitude": 22.0050643,
    "latitude": 50.0395864
  },
  {
    "id": 274,
    "name": "MARIUSZ GRONOWSKI OPTYK",
    "address": "\"OS. ZGODY 7, KRAKÓW, 31-949, Poland\"",
    "longitude": 19.9578927,
    "latitude": 50.041987
  },
  {
    "id": 275,
    "name": "VISIONZ SP. Z O.O.",
    "address": "\"Ul. Powstańców 22, Ząbki, 05-091, Poland\"",
    "longitude": 21.0989538,
    "latitude": 52.2927232
  },
  {
    "id": 276,
    "name": "MAŁGORZATA ANZEL PRAKTYKA LEKARSKA",
    "address": "\"UL. ZIELONA 34, NOWY SĄCZ, 33-300, Poland\"",
    "longitude": 20.7037714,
    "latitude": 49.6265317
  },
  {
    "id": 277,
    "name": "Jerzy Flak Zakład Optyczny",
    "address": "\"ul. 3 Maja 90, Proszowice, 32-100, Poland\"",
    "longitude": 20.3564193,
    "latitude": 50.1655538
  },
  {
    "id": 278,
    "name": "ANSWEAR.COM SPÓŁKA AKCYJNA",
    "address": "\"ALEJA POKOJU 18, KRAKÓW, 31-564, Poland\"",
    "longitude": 19.9456674,
    "latitude": 50.0470708
  },
  {
    "id": 279,
    "name": "OPTALUX HONORATA CZEKAŁA",
    "address": "\"UL. WOJCIECHA KOSSAKA 8/2, PRUSZCZ GDAŃSKI, 83-000, Poland\"",
    "longitude": 18.6430528,
    "latitude": 54.2622174
  },
  {
    "id": 280,
    "name": "CH AVENIDA (FIRMA OPTYCZNA WIEŃCZYS",
    "address": "\"ul.Matyi 2, Poznań, 61-586, Poland\"",
    "longitude": 16.8863426,
    "latitude": 52.3933612
  },
  {
    "id": 281,
    "name": "OPTYK POLAR SPÓŁKA JAWNA",
    "address": "\"UL. DŁUGA 75A, BIŁGORAJ, 23-400, Poland\"",
    "longitude": 22.722227,
    "latitude": 50.539915
  },
  {
    "id": 282,
    "name": "ZAKŁAD OPTYCZNY MILENA LEWALSKA",
    "address": "\"UL. ŻEROMSKIEGO 37C, SZCZYTNO, 12-100, Poland\"",
    "longitude": 20.991655,
    "latitude": 53.589986
  },
  {
    "id": 283,
    "name": "MAD MAX - MAKSYMILIAN CHIREK",
    "address": "\"UL. CHYLOŃSKA 27, GDYNIA, 81-064, Poland\"",
    "longitude": 18.550485,
    "latitude": 54.517284
  },
  {
    "id": 284,
    "name": "OPTYK BRYLE MAŁGORZATA JATCZAK",
    "address": "\"UL. BRZEZIŃSKA 16, KOLUSZKI, 95-040, Poland\"",
    "longitude": 19.838954,
    "latitude": 51.756728
  },
  {
    "id": 285,
    "name": "OPTYK LUCYNA TONDRYK",
    "address": "\"UL. SIENKIEWICZA 2, PIASECZNO, 05-500, Poland\"",
    "longitude": 21.02153,
    "latitude": 52.077024
  },
  {
    "id": 286,
    "name": "WALDEMAR GORĄCZKO CENTRUM OPTYKI",
    "address": "\"UL. 3-GO MAJA 9, SANOK, 38-500, Poland\"",
    "longitude": 22.212857,
    "latitude": 49.567171
  },
  {
    "id": 287,
    "name": "OPTYK CENTER SALON OPTYCZNO",
    "address": "\"UL. ODRODZENIA 35/17, SZCZYTNO, 12-100, Poland\"",
    "longitude": 20.991826,
    "latitude": 53.565076
  },
  {
    "id": 288,
    "name": "FIRMA HANDLOWA \"K.E.J.\" K. KULIK",
    "address": "\"UL. MAZURSKA 39, LĘBORK, 84-300, Poland\"",
    "longitude": 17.753536,
    "latitude": 54.53724
  },
  {
    "id": 289,
    "name": "JOLANTA SIEMBAB OPTYK OKULISTA",
    "address": "\"UL. ZAWIERCIAŃSKA 4/2, KLUCZE, 32-310, Poland\"",
    "longitude": 19.703198,
    "latitude": 50.221906
  },
  {
    "id": 290,
    "name": "WOJCIECH KASPRZAK OPTYKA I",
    "address": "\"UL. RYNEK 6, OSTRÓW WIELKOPOLSKI, 63-400, Poland\"",
    "longitude": 17.80327,
    "latitude": 51.654622
  },
  {
    "id": 291,
    "name": "OPTYKA OKULAROWA M.GUTOWSKA,",
    "address": "\"UL. BATALIONÓW CHŁOPSKICH 74 LOK. 4, WARSZAWA, 01-308, Poland\"",
    "longitude": 20.953907,
    "latitude": 52.245872
  },
  {
    "id": 292,
    "name": "ZAKŁAD OPTYCZNY ROBERT POLAK",
    "address": "\"UL. WYCZOŁKOWSKIEGO 8, RYKI, 08-500, Poland\"",
    "longitude": 22.28979,
    "latitude": 51.639673
  },
  {
    "id": 293,
    "name": "OPTYK Z PASJĄ by Magdalena",
    "address": "\"UL. JÓZEFA PIŁSUDSKIEGO 18, LOK. 7,, SIEDLCE, 08-110, Poland\"",
    "longitude": 22.291196,
    "latitude": 52.164279
  },
  {
    "id": 294,
    "name": "MONIKA DOMINIAK SALON OPTYCZNO-",
    "address": "\"UL. KURCZAKI 24B LOK.21, ŁÓDŹ, 93-322, Poland\"",
    "longitude": 19.412344,
    "latitude": 51.785195
  },
  {
    "id": 295,
    "name": "LYNX WESTFILED ARKADIA",
    "address": "\"AL. JANA PAWŁA II 82, WARSZAWA, 00-175, Poland\"",
    "longitude": 21.010102,
    "latitude": 52.243202
  },
  {
    "id": 296,
    "name": "Optika Fröhlich - RCS",
    "address": "\"NC Bory Mall Lamač 6780, Bratislava, 841 03, Slovak Republic\"",
    "longitude": 17.1077477,
    "latitude": 48.1485965
  },
  {
    "id": 297,
    "name": "Optic s.r.o. - OPTIKA CRYSTAL",
    "address": "\"Nitrianska cesta č.21, Nové Zámky, 940 02, Slovak Republic\"",
    "longitude": 18.1508197,
    "latitude": 47.9953975
  },
  {
    "id": 298,
    "name": "ZOOM S.R.O.",
    "address": "\"PODZÁMSKA 41, HLOHOVEC, 920 01, Slovak Republic\"",
    "longitude": 18.443049,
    "latitude": 48.833398
  },
  {
    "id": 299,
    "name": "MÁRIA KUBAČKOVÁ OPTIKA MK",
    "address": "\"S. CHALUPKU 4449/3, TOPOĽČANY, 955 01, Slovak Republic\"",
    "longitude": 18.0837023,
    "latitude": 48.4364553
  },
  {
    "id": 300,
    "name": "JOZEF ZELIESKA OČNÁ OPTIKA",
    "address": "\"Štúrova 296, Ilava, 019 01, Slovak Republic\"",
    "longitude": 18.324385,
    "latitude": 49.2245078
  },
  {
    "id": 301,
    "name": "MAURO SIMON, SPOL. S R.O.",
    "address": "\"HRNČIARSKA 34, PEZINOK, 902 01, Slovak Republic\"",
    "longitude": 17.2582835,
    "latitude": 48.2900437
  },
  {
    "id": 302,
    "name": "URUK S.R.O.",
    "address": "\"KUTUZOVOVA 6475/25, PREŠOV, 080 05, Slovak Republic\"",
    "longitude": 21.246416,
    "latitude": 49.000237
  },
  {
    "id": 303,
    "name": "OPTIKA KLAUDIA, S.R.O.",
    "address": "\"Hlavná ulica 918/2, Galanta, 924 01, Slovak Republic\"",
    "longitude": 17.596637,
    "latitude": 48.2059396
  },
  {
    "id": 304,
    "name": "MANIA OPTIKA-AVION BA",
    "address": "\"IVÁNSKA CESTA 16 - AVION, BRATISLAVA, 821 04, Slovak Republic\"",
    "longitude": 17.145259,
    "latitude": 48.165497
  },
  {
    "id": 305,
    "name": "MANIA OPTIKA-AUPARK KE",
    "address": "\"NÁM.OSLOBODITEĽOV 3377/1-AUPARK, KOŠICE, 040 01, Slovak Republic\"",
    "longitude": 21.234981,
    "latitude": 48.720928
  },
  {
    "id": 306,
    "name": "OM-OPTIK",
    "address": "\"Kupeckého 2, Pezinok, 902 01, Slovak Republic\"",
    "longitude": 17.2582835,
    "latitude": 48.2900437
  },
  {
    "id": 307,
    "name": "OPTICKÉ CENTRUM EVERGREEN,",
    "address": "\"SASINKOVA 1/A, MALACKY, 901 01, Slovak Republic\"",
    "longitude": 17.1207775,
    "latitude": 48.424112
  },
  {
    "id": 308,
    "name": "OPTIKA CENTRUM S.R.O.",
    "address": "\"ŠTÚROVA 2, KOŠICE, 040 11, Slovak Republic\"",
    "longitude": 21.242938,
    "latitude": 48.720986
  },
  {
    "id": 309,
    "name": "ING. ZDENKO GERBOC",
    "address": "\"ŠTUDENTSKÁ 2997/44, SNINA, 069 01, Slovak Republic\"",
    "longitude": 22.1583022,
    "latitude": 48.987657
  },
  {
    "id": 310,
    "name": "Očná optika MANIA Original Nivy",
    "address": "\"Mlynské Nivy 16, Bratislava, 821 09, Slovak Republic\"",
    "longitude": 17.119061,
    "latitude": 48.147473
  },
  {
    "id": 311,
    "name": "CSABA OROS - OROS OPTIK",
    "address": "\"HLAVNÁ 49, DOLNÝ ŠTÁL, 930 10, Slovak Republic\"",
    "longitude": 21.647371,
    "latitude": 48.682639
  },
  {
    "id": 312,
    "name": "Neovízia s.r.o.",
    "address": "\"Röntgenova 26, Bratislava - Petržalka, 851 01, Slovak Republic\"",
    "longitude": 17.113638,
    "latitude": 48.090849
  },
  {
    "id": 313,
    "name": "JOZEF ZELIESKA OČNÁ OPTIKA",
    "address": "\"1. MÁJA 870, PÚCHOV, 020 01, Slovak Republic\"",
    "longitude": 18.315341,
    "latitude": 49.135099
  },
  {
    "id": 314,
    "name": "Peter Vokel - OPTIKA VOKEL",
    "address": "\"K. Salvu 1, Ružomberok, 034 01, Slovak Republic\"",
    "longitude": 19.309959,
    "latitude": 49.077302
  },
  {
    "id": 315,
    "name": "KOLCUN OPTIK, S.R.O.",
    "address": "\"Hviezdoslavova 3808/14, Bardejov, 085 01, Slovak Republic\"",
    "longitude": 21.275285,
    "latitude": 49.293446
  },
  {
    "id": 316,
    "name": "OPTIK MORVAY, S.R.O.",
    "address": "\"Lichnerova 16, Senec, 903 01, Slovak Republic\"",
    "longitude": 17.4000185,
    "latitude": 48.2193052
  },
  {
    "id": 317,
    "name": "Progres očná optika, s. r. o.",
    "address": "\"Kollárova 4283/32, Martin, 036 01, Slovak Republic\"",
    "longitude": 18.934356,
    "latitude": 49.067664
  },
  {
    "id": 318,
    "name": "MARKUS OPTIK s.r.o.",
    "address": "\"Nám. A.H. Škultétyho 734/6, Veľký Krtíš, 990 01, Slovak Republic\"",
    "longitude": 19.161298,
    "latitude": 48.18913
  },
  {
    "id": 319,
    "name": "Balázs optix s. r. o.",
    "address": "\"Mierová 28, Kolárovo, 946 03, Slovak Republic\"",
    "longitude": 17.314433,
    "latitude": 47.980866
  },
  {
    "id": 320,
    "name": "MANIA OPTIKA-AUPARK ZA",
    "address": "\"VEĽKÁ OKRUŽNÁ 2733/59 - AUPARK, ŽILINA, 010 01, Slovak Republic\"",
    "longitude": 18.743649,
    "latitude": 49.228017
  },
  {
    "id": 321,
    "name": "ADAM OPTIK - očná optika",
    "address": "\"M. Martinečka 4701/2, Liptovský Mikuláš, 031 01, Slovak Republic\"",
    "longitude": 19.503304,
    "latitude": 47.162494
  },
  {
    "id": 322,
    "name": "MANIA OPTIKA-GALÉRIA MLYNY NR",
    "address": "\"ŠTEFÁNIKOVA 61 - OC MLYNY, NITRA, 949 01, Slovak Republic\"",
    "longitude": 18.086274,
    "latitude": 48.313187
  },
  {
    "id": 323,
    "name": "Optique, s.r.o.- OČNÁ OPTIKA",
    "address": "\"Nám. Sv. Michala 16 (Dom služieb), Hlohovec, 920 01, Slovak Republic\"",
    "longitude": 17.7899578,
    "latitude": 48.429068
  },
  {
    "id": 324,
    "name": "Optify Optika",
    "address": "\"A.Hlinku 58, Prievidza, 971 01, Slovak Republic\"",
    "longitude": 18.375751,
    "latitude": 48.779285
  },
  {
    "id": 325,
    "name": "OPTIKA ERIMA S.R.O.",
    "address": "\"P.O.HVIEZDOSLAVA 34, ZVOLEN, 960 01, Slovak Republic\"",
    "longitude": 19.1131153,
    "latitude": 48.5822318
  },
  {
    "id": 326,
    "name": "OPTAL, s.r.o.",
    "address": "\"Ul.Levická 1288/16, Vráble, 952 01, Slovak Republic\"",
    "longitude": 18.374026,
    "latitude": 48.173287
  },
  {
    "id": 327,
    "name": "OPTIKA KLÁTIK (Michal Klátik)",
    "address": "\"Mozartova 1, Trnava, 917 08, Slovak Republic\"",
    "longitude": 17.5790047,
    "latitude": 48.3761371
  },
  {
    "id": 328,
    "name": "Visual, s. r .o.",
    "address": "\"Dlhý rad 30, Bardejov, 085 01, Slovak Republic\"",
    "longitude": 21.271302,
    "latitude": 49.296413
  },
  {
    "id": 329,
    "name": "OPTIKA OLYMPIA S.R.O.",
    "address": "\"PRIBINOVA 31, MALACKY, 901 01, Slovak Republic\"",
    "longitude": 17.1218171,
    "latitude": 48.4577247
  },
  {
    "id": 330,
    "name": "ZSOLT OROS – OPTIKA OROS s.r.o.",
    "address": "\"Bratislavská 100/D, Šamorín, 931 01, Slovak Republic\"",
    "longitude": 17.230469,
    "latitude": 47.968976
  },
  {
    "id": 331,
    "name": "Optika Lux s.r.o.",
    "address": "\"Štefánikova trieda 6, Nitra, 949 01, Slovak Republic\"",
    "longitude": 18.637073,
    "latitude": 48.305602
  },
  {
    "id": 332,
    "name": "LEXUM Slovakia s.r.o.",
    "address": "\"Jilemnického 532/2, Trenčín, 911 01, Slovak Republic\"",
    "longitude": 18.0345263,
    "latitude": 48.8966713
  },
  {
    "id": 333,
    "name": "OPTIKA KOBRA S.R.O.",
    "address": "\"NÁMESTIE REPUBLIKY 10, LUČENEC, 984 01, Slovak Republic\"",
    "longitude": 19.669165,
    "latitude": 48.335251
  },
  {
    "id": 334,
    "name": "ZRAKOVÉ CENTRUM - VISION CARE",
    "address": "\"Staré Grunty 24, Bratislava - Karlova Ves, 841 04, Slovak Republic\"",
    "longitude": 17.0557556,
    "latitude": 48.1580724
  },
  {
    "id": 335,
    "name": "NEREA, S.R.O.",
    "address": "\"AGÁTOVÁ 1929/6, SPIŠSKÁ NOVÁ VES, 052 01, Slovak Republic\"",
    "longitude": 20.726126,
    "latitude": 48.951101
  },
  {
    "id": 336,
    "name": "GOOD OPTIK S.R.O.",
    "address": "\"HLAVNÁ 69, PREŠOV, 080 01, Slovak Republic\"",
    "longitude": 21.2410116,
    "latitude": 49.0024164
  },
  {
    "id": 337,
    "name": "Očná optika V.S.O.P. s.r.o.",
    "address": "\"Námestie Matice Slovenskej 404/0, Žiar nad Hronom, 965 01, Slovak Republic\"",
    "longitude": 18.894692,
    "latitude": 48.635173
  },
  {
    "id": 338,
    "name": "OPTIK MORVAY, S.R.O.",
    "address": "\"RADLINSKÉHO 1, TRNAVA, 917 01, Slovak Republic\"",
    "longitude": 17.5909165,
    "latitude": 48.3670257
  },
  {
    "id": 339,
    "name": "MANIA OPTIKA-BORY MALL BA",
    "address": "\"LAMAČ 6780 - BORY, BRATISLAVA, 840 02, Slovak Republic\"",
    "longitude": 17.0953083,
    "latitude": 48.1541367
  },
  {
    "id": 340,
    "name": "SO STONE OPTIK",
    "address": "\"Osuského 3784/3B, Bratislava-Petržalka, 851 03, Slovak Republic\"",
    "longitude": 17.1077477,
    "latitude": 48.1485965
  },
  {
    "id": 341,
    "name": "Lajos Miklós-oč. optik optometrista",
    "address": "Hlavná 1, Galanta, 924 01, Slovak Republic",
    "longitude": 17.231181,
    "latitude": 48.208067
  },
  {
    "id": 342,
    "name": "ING.MARTIN ŠUPICA - OPTIKA PRIMA",
    "address": "ZLIEVARENSKÁ 24, KOMÁRNO, 945 01, Slovak Republic",
    "longitude": 18.133444,
    "latitude": 47.756175
  },
  {
    "id": 343,
    "name": "RS OPTIMA S.R.O. (očná optika)",
    "address": "BUDOVATEĽSKÁ 546/24, ŠAĽA, 927 01, Slovak Republic",
    "longitude": 17.888735,
    "latitude": 48.148801
  },
  {
    "id": 344,
    "name": "INGRID ŽÁČKA",
    "address": "TRENČIANSKE BOHUSLAVICE 215, TRENČIANSKE BOHUSLAVICE, 913 07, Slovak Republic",
    "longitude": 18.347824,
    "latitude": 48.953309
  },
  {
    "id": 345,
    "name": "OPTIKCENTRUM, S.R.O.",
    "address": "Štefánikova 49, NITRA, 949 01, Slovak Republic",
    "longitude": 18.08633,
    "latitude": 48.306203
  },
  {
    "id": 346,
    "name": "MANIA OPTIKA-CITY ARENA TT",
    "address": "KOLLÁROVA 20 - CITY ARENA, TRNAVA, 917 01, Slovak Republic",
    "longitude": 17.587923,
    "latitude": 48.373721
  },
  {
    "id": 347,
    "name": "MANIA ORIGINAL OPTIKA - NZ TESCO",
    "address": "NITRIANSKA CESTA 111 - HM TESCO, NOVÉ ZÁMKY, 940 67, Slovak Republic",
    "longitude": 18.159352,
    "latitude": 47.992065
  },
  {
    "id": 348,
    "name": "OPTIK MORVAY, S.R.O.",
    "address": "Poštová 13, Sereď, 926 01, Slovak Republic",
    "longitude": 17.367834,
    "latitude": 48.226377
  },
  {
    "id": 349,
    "name": "OPTIK TEO",
    "address": "Mierové nám. 29, Trenčín, 911 01, Slovak Republic",
    "longitude": 18.007143,
    "latitude": 48.896206
  },
  {
    "id": 350,
    "name": "OFTEVA, S.R.O.",
    "address": "Ivana Krasku 2483/2, Sereď, 926 01, Slovak Republic",
    "longitude": 17.394698,
    "latitude": 48.321145
  },
  {
    "id": 351,
    "name": "MANIA OPTIKA-TESCO BB",
    "address": "ZVOLENSKÁ CESTA 23 - TESCO, BANSKÁ BYSTRICA, 974 05, Slovak Republic",
    "longitude": 19.123677,
    "latitude": 48.750578
  },
  {
    "id": 352,
    "name": "MANIA OPTIKA-TESCO/GALÉRIA NR",
    "address": "BRATISLAVSKÁ 5-OD TESCO/GALÉRIA, NITRA, 949 01, Slovak Republic",
    "longitude": 18.084998,
    "latitude": 48.30722
  },
  {
    "id": 353,
    "name": "PF optik",
    "address": "Partizánska 291/17A, Myjava, 907 01, Slovak Republic",
    "longitude": 17.540655,
    "latitude": 48.74984
  },
  {
    "id": 354,
    "name": "MANIA OPTIKA-OC MAX PP",
    "address": "DLHÉ HONY 1 - OC MAX, POPRAD, 058 01, Slovak Republic",
    "longitude": 20.297061,
    "latitude": 49.058374
  },
  {
    "id": 355,
    "name": "MANIA OPTIKA-AUPARK BA",
    "address": "EINSTEINOVA 3541/18-AUPARK, BRATISLAVA-PETRŽALKA, 851 01, Slovak Republic",
    "longitude": 17.11454,
    "latitude": 48.135563
  },
  {
    "id": 356,
    "name": "MANIA OPTIKA-ATRIUM DUBEŇ ZA",
    "address": "UL.VYSOKOŠKOLÁKOV 52-ATRIUM DUBEŇ, ŽILINA, 010 08, Slovak Republic",
    "longitude": 18.736293,
    "latitude": 49.220434
  },
  {
    "id": 357,
    "name": "G&D Optics s. r. o.",
    "address": "Textilná ul. 10/0, Levice, 934 01, Slovak Republic",
    "longitude": 18.622215,
    "latitude": 48.207042
  },
  {
    "id": 358,
    "name": "Optik JaS s.r.o.",
    "address": "Kálov 5, Žilina, 010 01, Slovak Republic",
    "longitude": 18.733609,
    "latitude": 49.220336
  },
  {
    "id": 359,
    "name": "RÓBERT VALENT OPTIKA A.D.",
    "address": "ŠTIAVNICKÁ 20, NITRA, 949 01, Slovak Republic",
    "longitude": 18.080721,
    "latitude": 48.306746
  },
  {
    "id": 360,
    "name": "Optika LENS s.r.o.",
    "address": "Záhradnícka 4/975, Štúrovo, 943 01, Slovak Republic",
    "longitude": 18.71927,
    "latitude": 47.787479
  },
  {
    "id": 361,
    "name": "MANIA OPTIKA-OC MAX TN",
    "address": "M.R. ŠTEFÁNIKA 426 - OC MAX, TRENČÍN, 911 01, Slovak Republic",
    "longitude": 18.043898,
    "latitude": 48.888354
  },
  {
    "id": 362,
    "name": "ZAMAK s. r. o",
    "address": "Mikovíniho 406/14, Nitra, 949 11, Slovak Republic",
    "longitude": 18.074252,
    "latitude": 48.308609
  },
  {
    "id": 363,
    "name": "Neovízia s.r.o.",
    "address": "Dlhé Hony 9, Poprad, 058 01, Slovak Republic",
    "longitude": 20.298769,
    "latitude": 49.059662
  },
  {
    "id": 364,
    "name": "RENÁTA FRAJTKOVÁ - EUROOPTIKA",
    "address": "OSLOBODITEĽOV 59, MICHALOVCE, 071 01, Slovak Republic",
    "longitude": 21.911824,
    "latitude": 48.75758
  },
  {
    "id": 365,
    "name": "Zoltán Oros OPTIKA",
    "address": "Hlavné námestie 20/20, ŠAHY, 936 01, Slovak Republic",
    "longitude": 18.708151,
    "latitude": 47.870703
  },
  {
    "id": 366,
    "name": "OPTICAL S. R. O.",
    "address": "Dolný Šianec 1, TRENČÍN, 911 01, Slovak Republic",
    "longitude": 18.020861,
    "latitude": 48.891579
  },
  {
    "id": 367,
    "name": "MANIA ORIGINAL OPTIKA-OC VIVO BA",
    "address": "VAJNORSKÁ 100 - VIVO!, BRATISLAVA, 831 04, Slovak Republic",
    "longitude": 17.18676,
    "latitude": 48.15564
  },
  {
    "id": 368,
    "name": "PRESSBURG OPTIK, S. R. O.",
    "address": "HERLIANSKA 45, BRATISLAVA - RUŽINOV, 821 03, Slovak Republic",
    "longitude": 17.17712,
    "latitude": 48.16127
  },
  {
    "id": 369,
    "name": "OPTIKA ŽÁČKA S.R.O.",
    "address": "MALINOVSKÉHO 1238/22, NOVÉ MESTO NAD VÁHOM, 915 01, Slovak Republic",
    "longitude": 18.137275,
    "latitude": 48.774439
  },
  {
    "id": 370,
    "name": "Eva Tóthová - OPTIKA LUNETA",
    "address": "Rábska 19, Kolárovo, 946 03, Slovak Republic",
    "longitude": 18.123386,
    "latitude": 47.945671
  },
  {
    "id": 371,
    "name": "Ján Oros - STYLE OPTIK",
    "address": "Poštová 10, Tornaľa, 982 01, Slovak Republic",
    "longitude": 20.260462,
    "latitude": 48.381225
  },
  {
    "id": 372,
    "name": "MUDR.V.A. POPOVÁ, RAINBOW OPTIC",
    "address": "HODSKÁ 3170/111, GALANTA, 924 01, Slovak Republic",
    "longitude": 17.73143,
    "latitude": 48.201586
  },
  {
    "id": 373,
    "name": "IVIOPTIK S.R.O.",
    "address": "CYRILA A METODA 34, MICHALOVCE, 071 01, Slovak Republic",
    "longitude": 21.915913,
    "latitude": 48.753627
  },
  {
    "id": 374,
    "name": "MANIA OPTIKA-OC FORUM PP",
    "address": "NÁM.SV.EGÍDIA 3290/124-OC FORUM, POPRAD, 058 01, Slovak Republic",
    "longitude": 20.298769,
    "latitude": 49.059662
  },
  {
    "id": 375,
    "name": "MANIA ORIGINAL OPTIKA - TESCO DS",
    "address": "Hlavná 75, Dunajská Streda, 929 01, Slovak Republic",
    "longitude": 17.619732,
    "latitude": 47.99502
  },
  {
    "id": 376,
    "name": "ROMAN ZACHAR - OPTIKA POHODA",
    "address": "ŠTÚROVA 5, TOPOĽČANY, 955 01, Slovak Republic",
    "longitude": 18.107145,
    "latitude": 48.318451
  },
  {
    "id": 377,
    "name": "pan-optik s.r.o. - Očná Optika",
    "address": "Námestie SR č.8, Bytča, 014 01, Slovak Republic",
    "longitude": 18.431357,
    "latitude": 49.22129
  },
  {
    "id": 378,
    "name": "Optika Klobúk",
    "address": "Rokošská 7, Bánovce nad Bebravou, 957 01, Slovak Republic",
    "longitude": 18.226203,
    "latitude": 48.796827
  },
  {
    "id": 379,
    "name": "Optika EYEVIL (VILEYE s.r.o.)",
    "address": "Trieda prof. Hlaváča 15-17, Michalovce, 071 01, Slovak Republic",
    "longitude": 21.915913,
    "latitude": 48.753627
  },
  {
    "id": 380,
    "name": "MANIA ORIGINAL OPTIKA-LV TESCO",
    "address": "TURECKÝ RAD 5172/7 - HM TESCO, LEVICE, 934 01, Slovak Republic",
    "longitude": 18.619597,
    "latitude": 48.213729
  },
  {
    "id": 381,
    "name": "pan-optik s.r.o. - Očná Optika",
    "address": "Haškova 10, Nové Mesto nad Váhom, 915 01, Slovak Republic",
    "longitude": 17.822214,
    "latitude": 48.35487
  },
  {
    "id": 382,
    "name": "ANGEL OPTIC M & I s.r.o.",
    "address": "Dvory 1933/17, Púchov, 020 01, Slovak Republic",
    "longitude": 18.323856,
    "latitude": 49.07367
  },
  {
    "id": 383,
    "name": "Anton Hauptman - Dália Optika",
    "address": "Hlavná 459/80, Streda nad Bodrogom, 076 31, Slovak Republic",
    "longitude": 21.686848,
    "latitude": 48.402437
  },
  {
    "id": 384,
    "name": "Mária Varhaníková - OčNÁ OPTIKA, s.",
    "address": "Bernoláková 24, Zlaté Moravce, 953 01, Slovak Republic",
    "longitude": 18.394735,
    "latitude": 48.393526
  },
  {
    "id": 385,
    "name": "Očná optika OPTIQ",
    "address": "Nám. Sv. Egídia 20/45, Poprad, 058 01, Slovak Republic",
    "longitude": 20.29225,
    "latitude": 49.059256
  },
  {
    "id": 386,
    "name": "iOptic s.r.o.",
    "address": "B. Němcovej 28, OC Merkúr, Košice, 040 01, Slovak Republic",
    "longitude": 21.24506,
    "latitude": 48.715733
  },
  {
    "id": 387,
    "name": "KATARÍNA ÁGHOVÁ - OPTIKA-HORIZONT",
    "address": "NEMOCNIČNÁ 1, ŠALA, 927 01, Slovak Republic",
    "longitude": 17.49368,
    "latitude": 48.668755
  },
  {
    "id": 388,
    "name": "Ľ. BEDNÁRIKOVÁ-OčNÁ OPTIKA STIGMA",
    "address": "Nám. Sv. Michala 16   DOM SLUŽIEB, HLOHOVEC, 920 01, Slovak Republic",
    "longitude": 17.806655,
    "latitude": 48.34877
  },
  {
    "id": 389,
    "name": "OPTIKA UHLÁR(JUING SK, s.r.o.Poprad",
    "address": "Francisciho 20, Poprad, 058 01, Slovak Republic",
    "longitude": 20.29922,
    "latitude": 49.06194
  },
  {
    "id": 390,
    "name": "AK OPTIK (AK OPTIK, S.R.O.)",
    "address": "A. HLINKU 27 - POLIKLINIKA, HLOHOVEC, 920 01, Slovak Republic",
    "longitude": 15.186094,
    "latitude": 48.93402
  },
  {
    "id": 391,
    "name": "CRYSTAL OPTIK, S.R.O.",
    "address": "ŠPITÁLSKA 13, NITRA, 949 01, Slovak Republic",
    "longitude": 18.096615,
    "latitude": 48.313716
  },
  {
    "id": 392,
    "name": "OPTIKA VISUS S.R.O.",
    "address": "HLAVNÁ 53, ŠTÚROVO, 943 01, Slovak Republic",
    "longitude": 18.720211,
    "latitude": 47.797746
  },
  {
    "id": 393,
    "name": "SUNSHINE, TRGOVINA D.O.O.",
    "address": "CELARČEVA ULICA 8, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.508354,
    "latitude": 46.052967
  },
  {
    "id": 394,
    "name": "OčESNI CENTER PRESKAR NM",
    "address": "KETTEJEV DREVORED 32, NOVO MESTO, 8000, Slovenia Country",
    "longitude": 15.165595,
    "latitude": 45.801284
  },
  {
    "id": 395,
    "name": "OPTIKA KRSTIČ PE LECLERC",
    "address": "JURČKOVA CESTA 225, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.51907,
    "latitude": 46.04814
  },
  {
    "id": 396,
    "name": "OPTIKA PAKA D.O.O.",
    "address": "RUDARSKA CESTA 1, VELENJE, 3320, Slovenia Country",
    "longitude": 15.114436,
    "latitude": 46.359447
  },
  {
    "id": 397,
    "name": "OPTIKA PLUS INTEROPTI D.O.O.",
    "address": "C. STE MARIE AUX MINES 36, TRŽIČ, 4290, Slovenia Country",
    "longitude": 14.312041,
    "latitude": 46.364276
  },
  {
    "id": 398,
    "name": "O2 MED OPTIKA MORELA",
    "address": "POBREŠKA CESTA 18, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.643632,
    "latitude": 46.550766
  },
  {
    "id": 399,
    "name": "P.E. Optika Smole",
    "address": "Prešernova ul. 3, Celje, 3000, Slovenia Country",
    "longitude": 15.264282,
    "latitude": 46.230944
  },
  {
    "id": 400,
    "name": "OPTIKA KOTNIK, STORITVE D.O.O.",
    "address": "RONKOVA ULICA 4, SLOVENJ GRADEC, 2380, Slovenia Country",
    "longitude": 15.077656,
    "latitude": 46.510264
  },
  {
    "id": 401,
    "name": "OPTIKA FENIKS, OPT.DEJAVNOST,",
    "address": "ZAGREBAŠKA CESTA 034, PTUJ, 2250, Slovenia Country",
    "longitude": 15.872232,
    "latitude": 46.422878
  },
  {
    "id": 402,
    "name": "EXPRESS OPTIKA MIRO KRSTIč S.P.",
    "address": "Tržaška cesta 15, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.511696,
    "latitude": 46.054225
  },
  {
    "id": 403,
    "name": "OPTIKA BRANKO NOVAK S.P.",
    "address": "PREŠERNOVA CESTA 5, GORNJA RADGONA, 9250, Slovenia Country",
    "longitude": 15.992429,
    "latitude": 46.676621
  },
  {
    "id": 404,
    "name": "OčESNA OPTIKA LIKEB",
    "address": "NA TRGU 5, MOZIRJE, 3330, Slovenia Country",
    "longitude": 14.965304,
    "latitude": 46.339694
  },
  {
    "id": 405,
    "name": "VOGRIč IGOR S.P.",
    "address": "PRELOVČEVA ULICA 1, IDRIJA, 5280, Slovenia Country",
    "longitude": 14.019811,
    "latitude": 45.985617
  },
  {
    "id": 406,
    "name": "BRUMEN JANJA S.P.",
    "address": "PREŽIHOVA ULICA 13, RAVNE NA KOROŠKEM, 2390, Slovenia Country",
    "longitude": 15.192823,
    "latitude": 46.548166
  },
  {
    "id": 407,
    "name": "Anja Gorenjak s.p., OPTIKA",
    "address": "Malahorna 26A, Oplotnica, 2317, Slovenia Country",
    "longitude": 15.418408,
    "latitude": 46.388763
  },
  {
    "id": 408,
    "name": "ART OPTIKA PE KRANJ",
    "address": "BLEIWEISOVA 14, KRANJ, 4000, Slovenia Country",
    "longitude": 14.355929,
    "latitude": 46.244091
  },
  {
    "id": 409,
    "name": "OPTIKA FERRY,",
    "address": "Portorož, Obala 33, Portorož, 6320, Slovenia Country",
    "longitude": 13.590138,
    "latitude": 45.51562
  },
  {
    "id": 410,
    "name": "MATEJ NOVAK S.P.",
    "address": "MAISTROVA 32, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.641929,
    "latitude": 46.557548
  },
  {
    "id": 411,
    "name": "OPTIKA24 D.O.O.",
    "address": "POLJSKA ULICA 2, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.676649,
    "latitude": 46.547263
  },
  {
    "id": 412,
    "name": "\"OPTIKA M\" - MARKO BOSILJEVAC S.P.",
    "address": "NASELJE BORISA KIDRIČA 9, METLIKA, 8330, Slovenia Country",
    "longitude": 15.314204,
    "latitude": 45.64275
  },
  {
    "id": 413,
    "name": "OPTIKA IRMAN, D.O.O.",
    "address": "SAVINJSKA CESTA 2, ŽALEC, 3310, Slovenia Country",
    "longitude": 15.164426,
    "latitude": 46.249445
  },
  {
    "id": 414,
    "name": "OPTIKA CLARUS BTC LJ",
    "address": "Trg mladih 9, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.526008,
    "latitude": 46.042834
  },
  {
    "id": 415,
    "name": "MINUS 50, OPTIKA, D.O.O.",
    "address": "TRŽAŠKA 67 A, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.647508,
    "latitude": 46.549633
  },
  {
    "id": 416,
    "name": "OPTIKA VID BENDA MILENA S.P.",
    "address": "CESTA BORCEV 5, RADOMLJE, 1235, Slovenia Country",
    "longitude": 14.595198,
    "latitude": 46.160866
  },
  {
    "id": 417,
    "name": "OPTIKA \"SIMONA\", Simona Kodrin s.p.",
    "address": "RIMSKA CESTA 35, ŠEMPETER V SAV.DOLINI, 3311, Slovenia Country",
    "longitude": 15.158245,
    "latitude": 46.206847
  },
  {
    "id": 418,
    "name": "OPTIKA GLAS D.O.O.",
    "address": "ŠALEŠKA CESTA 19A, VELENJE, 3320, Slovenia Country",
    "longitude": 15.116219,
    "latitude": 46.359652
  },
  {
    "id": 419,
    "name": "ART OPTIKA PE NOVA GORICA",
    "address": "KIDRIČEVA 20, NOVA GORICA, 5000, Slovenia Country",
    "longitude": 13.649043,
    "latitude": 45.956114
  },
  {
    "id": 420,
    "name": "KEBER DAMJAN S.P.",
    "address": "TRDINOVA ULICA 1, BREŽICE, 8250, Slovenia Country",
    "longitude": 15.59616,
    "latitude": 45.900475
  },
  {
    "id": 421,
    "name": "OKOVID OPTIKA D.O.O.",
    "address": "FRANCETOVA 16, SLOVENJ GRADEC, 2380, Slovenia Country",
    "longitude": 15.083988,
    "latitude": 46.509947
  },
  {
    "id": 422,
    "name": "OPTIKA \"D\" DRAGO SEVER S.P.",
    "address": "DUNAJSKA 202, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.509114,
    "latitude": 46.062162
  },
  {
    "id": 423,
    "name": "PLANETA trgovina in storitve, d.o.o",
    "address": "Grška ulica 13, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.511696,
    "latitude": 46.054225
  },
  {
    "id": 424,
    "name": "OPTIKA PETRA",
    "address": "DROFENIKOVA ULICA 16, ŠENTJUR, 3230, Slovenia Country",
    "longitude": 15.394229,
    "latitude": 46.218759
  },
  {
    "id": 425,
    "name": "ART OPTIKA PE CELJE",
    "address": "LJUBLJANSKA CESTA 6, CELJE, 3000, Slovenia Country",
    "longitude": 15.253556,
    "latitude": 46.229276
  },
  {
    "id": 426,
    "name": "VIDIM OPTIKA D.O.O.",
    "address": "MARIBORSKA CESTA 88, CELJE, 3000, Slovenia Country",
    "longitude": 15.264756,
    "latitude": 46.223558
  },
  {
    "id": 427,
    "name": "OPTIKA ŠKOFIC",
    "address": "LJUBLJANSKA 87, DOMŽALE, 1230, Slovenia Country",
    "longitude": 14.597517,
    "latitude": 46.137082
  },
  {
    "id": 428,
    "name": "LJUDSKA OPTIKA GLASMAHER",
    "address": "PLETERŠNIKOVA ULICA 1, BREŽICE, 8250, Slovenia Country",
    "longitude": 15.590458,
    "latitude": 45.901178
  },
  {
    "id": 429,
    "name": "HERGULA DAVORIN S.P.",
    "address": "PTUJSKA CESTA 13, ORMOŽ, 2270, Slovenia Country",
    "longitude": 16.147933,
    "latitude": 46.406263
  },
  {
    "id": 430,
    "name": "OPTIKA OFTALMOS",
    "address": "ZDRAVILIŠKI TRG 25, ROGAŠKA SLATINA, 3250, Slovenia Country",
    "longitude": 15.639798,
    "latitude": 46.236232
  },
  {
    "id": 431,
    "name": "Optika Cornea Mengeš",
    "address": "MURNOVA ULICA 1, MENGEŠ, 1234, Slovenia Country",
    "longitude": 14.579465,
    "latitude": 46.159933
  },
  {
    "id": 432,
    "name": "Očesna Ordinacija in Optika",
    "address": "Tržaška 495, Brezovica, 1351, Slovenia Country",
    "longitude": 14.373022,
    "latitude": 45.977635
  },
  {
    "id": 433,
    "name": "OPTIKA KUHAR TRGOVINA IN",
    "address": "VODNIKOVA ULICA 2, PTUJ, 2250, Slovenia Country",
    "longitude": 15.878998,
    "latitude": 46.419769
  },
  {
    "id": 434,
    "name": "OPTIKA TINA MARTINA DERžEK S.P",
    "address": "TRG ZBORA ODPOSLANCEV 12A, KOčEVJE, 1330, Slovenia Country",
    "longitude": 14.862284,
    "latitude": 45.643916
  },
  {
    "id": 435,
    "name": "Očesna Optika Berce d.o.o.",
    "address": "ŽELEZNIŠKA 7, LESCE, 4248, Slovenia Country",
    "longitude": 14.157708,
    "latitude": 46.365764
  },
  {
    "id": 436,
    "name": "OPTIKA SONJA JUREš D.O.O.",
    "address": "ULICA STANETA ROZMANA 13, MURSKA SOBOTA, 9000, Slovenia Country",
    "longitude": 16.153622,
    "latitude": 46.667415
  },
  {
    "id": 437,
    "name": "OPTOVID D.O.O. LJUBLJANA",
    "address": "DUNAJSKA CESTA 56, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.506953,
    "latitude": 46.07695
  },
  {
    "id": 438,
    "name": "OPTIKA ARENA D.O.O.",
    "address": "POHORSKA ULICA 21, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.644123,
    "latitude": 46.54336
  },
  {
    "id": 439,
    "name": "OPTIKA IBIS D.O.O. AJDOVščINA",
    "address": "ULICA 5. MAJA 1, AJDOVščINA, 5270, Slovenia Country",
    "longitude": 13.910706,
    "latitude": 45.891246
  },
  {
    "id": 440,
    "name": "PRIMOž ROžIč S.P.",
    "address": "GLEDALIšKA ULICA 2, CELJE, 3000, Slovenia Country",
    "longitude": 15.163142,
    "latitude": 46.234916
  },
  {
    "id": 441,
    "name": "ART OPTIKA PE VELENJE",
    "address": "PREŠERNOVA 7B, VELENJE, 3320, Slovenia Country",
    "longitude": 15.111018,
    "latitude": 46.361724
  },
  {
    "id": 442,
    "name": "ART OPTIKA D.O.O.",
    "address": "GORIŠKA CESTA 78, AJDOVŠČINA, 5270, Slovenia Country",
    "longitude": 13.910706,
    "latitude": 45.891246
  },
  {
    "id": 443,
    "name": "OPTIKA KAMELEON CITY",
    "address": "Ulica Vita Kraigherja 5, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.642245,
    "latitude": 46.558863
  },
  {
    "id": 444,
    "name": "OPTIKA CLARUS KOPER - PLANET KOPER",
    "address": "ANKARANSKA CESTA 2, KOPER, 6000, Slovenia Country",
    "longitude": 13.738256,
    "latitude": 45.5415
  },
  {
    "id": 445,
    "name": "OPTIKA CLARUS ALEJA ŠIŠKA",
    "address": "Rakuševa ulica 1, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.51152,
    "latitude": 46.066705
  },
  {
    "id": 446,
    "name": "OPTIKA CLARUS PTUJ SUPERNOVA",
    "address": "ORMOŠKA CESTA 15, PTUJ, 2250, Slovenia Country",
    "longitude": 15.86716,
    "latitude": 46.419307
  },
  {
    "id": 447,
    "name": "OPTIKA CLARUS NOVO MESTO",
    "address": "OTOŠKA CESTA 5, NOVO MESTO, 8000, Slovenia Country",
    "longitude": 15.173091,
    "latitude": 45.804393
  },
  {
    "id": 448,
    "name": "OPTIKA CLARUS KOPER",
    "address": "ANKARANSKA CESTA 3A, KOPER, 6000, Slovenia Country",
    "longitude": 13.738256,
    "latitude": 45.5415
  },
  {
    "id": 449,
    "name": "OPTIKA CLARUS CELJE",
    "address": "MARIBORSKA CESTA 100, CELJE, 3000, Slovenia Country",
    "longitude": 15.257127,
    "latitude": 46.246768
  },
  {
    "id": 450,
    "name": "OPTIKA LANA",
    "address": "PREŠERNOVA 1, SEVNICA, 8290, Slovenia Country",
    "longitude": 15.309893,
    "latitude": 46.008076
  },
  {
    "id": 451,
    "name": "OPTIKA CLARUS KRANJ SUPERNOVA",
    "address": "Cesta 1. maja 77, KRANJ, 4000, Slovenia Country",
    "longitude": 14.35247,
    "latitude": 46.238783
  },
  {
    "id": 452,
    "name": "OPTIKA ALFA PLUS",
    "address": "TRŽAŠKA CESTA 14, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.675681,
    "latitude": 46.557412
  },
  {
    "id": 453,
    "name": "OPTIKA OCULUS PRIOR, D.O.O.",
    "address": "OSOJNIKOVA ULICA 17, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.620406,
    "latitude": 46.562601
  },
  {
    "id": 454,
    "name": "OPTIKA CLARUS RUDNIK",
    "address": "Jurčkova cesta 223, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.540769,
    "latitude": 46.050424
  },
  {
    "id": 455,
    "name": "OPTIKA CLARUS SLOVENSKA",
    "address": "Tavčarjeva ulica 1, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.505808,
    "latitude": 46.05164
  },
  {
    "id": 456,
    "name": "OPTIKA KAMELEON CANKARJEVA UL.",
    "address": "CANKARJEVA ULICA 11, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.641632,
    "latitude": 46.561405
  },
  {
    "id": 457,
    "name": "OPTIKA SONJA JUREŠ D.O.O.",
    "address": "ORMOŠKA CESTA 3/A, LJUTOMER, 9240, Slovenia Country",
    "longitude": 16.146469,
    "latitude": 46.519548
  },
  {
    "id": 458,
    "name": "OPTIKA IBIS D.O.O. SEŽANA",
    "address": "PARTIZANSKA CESTA 44, SEŽANA, 6210, Slovenia Country",
    "longitude": 13.815892,
    "latitude": 45.721737
  },
  {
    "id": 459,
    "name": "OPTIKA KIRALY D.O.O.",
    "address": "MLINSKA ULICA 7, LENDAVA, 9220, Slovenia Country",
    "longitude": 16.462183,
    "latitude": 46.563223
  },
  {
    "id": 460,
    "name": "OPTIKA VIVID,DIJANA NOVAK S.P.",
    "address": "TROJIŠKI TRG 8, SV. TROJICA V SLOV.GORICAH, 2235, Slovenia Country",
    "longitude": 15.826212,
    "latitude": 46.583442
  },
  {
    "id": 461,
    "name": "PARTNER,",
    "address": "KOROŠKA CESTA 14, KRANJ, 4000, Slovenia Country",
    "longitude": 14.35247,
    "latitude": 46.238783
  },
  {
    "id": 462,
    "name": "OČESNA OPTIKA BERCE D.O.O.",
    "address": "CESTA CIRILA TAVČARJA 6, JESENICE, 4270, Slovenia Country",
    "longitude": 13.697792,
    "latitude": 46.437832
  },
  {
    "id": 463,
    "name": "GREGOR MILOŠIČ S.P.",
    "address": "MAISTROVA ULICA 18, LENART V SLOV. GORICAH, 2230, Slovenia Country",
    "longitude": 15.831882,
    "latitude": 46.57668
  },
  {
    "id": 464,
    "name": "RITM d.o.o.",
    "address": "CESTA PRVIH BORCEV 32, BREŽICE, 8250, Slovenia Country",
    "longitude": 15.586165,
    "latitude": 45.901499
  },
  {
    "id": 465,
    "name": "OPTIKA POGLED",
    "address": "CELJSKA CESTA 9, ŠMARJE PRI JELŠAH, 3240, Slovenia Country",
    "longitude": 15.485086,
    "latitude": 46.226404
  },
  {
    "id": 466,
    "name": "TERŽAN D.O.O.",
    "address": "MARIBORSKA CESTA 54, CELJE, 3000, Slovenia Country",
    "longitude": 15.257493,
    "latitude": 46.247522
  },
  {
    "id": 467,
    "name": "VOGRIN ZLATARSTVO IN",
    "address": "MALGAJEVA ULICA 1, RADLJE OB DRAVI, 2360, Slovenia Country",
    "longitude": 15.167006,
    "latitude": 46.614998
  },
  {
    "id": 468,
    "name": "INMED D.O.O. TRŽAŠKA",
    "address": "TRŽAŠKA 19A, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.47889,
    "latitude": 46.061254
  },
  {
    "id": 469,
    "name": "OPTRIS D.O.O.",
    "address": "KOTARJEVA CESTA 8A, ŠENTJERNEJ, 8310, Slovenia Country",
    "longitude": 15.32782,
    "latitude": 45.835637
  },
  {
    "id": 470,
    "name": "ART OPTIKA PE PTUJ",
    "address": "SLOMŠKOVA 24, PTUJ, 2250, Slovenia Country",
    "longitude": 15.869679,
    "latitude": 46.420262
  },
  {
    "id": 471,
    "name": "OPTIKA OFTALMOS CELJE",
    "address": "MARIBORSKA CESTA 69, CELJE, 3000, Slovenia Country",
    "longitude": 15.26713,
    "latitude": 46.236384
  },
  {
    "id": 472,
    "name": "GOBEC, turizem, optika,",
    "address": "JANINA 1B, ROGAŠKA SLATINA, 3250, Slovenia Country",
    "longitude": 15.636938,
    "latitude": 46.241433
  },
  {
    "id": 473,
    "name": "KŠŠ SPROSTITVENI CENTER",
    "address": "DEVINSKA ULICA 1, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.512545,
    "latitude": 46.051273
  },
  {
    "id": 474,
    "name": "MEDVEŠEK MARKO S.P.",
    "address": "RADVANJSKA 99, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.635491,
    "latitude": 46.550597
  },
  {
    "id": 475,
    "name": "OPTIKA ŠIMENC",
    "address": "TRUBARJEVA CESTA 56, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.506846,
    "latitude": 46.058797
  },
  {
    "id": 476,
    "name": "OPTIKA MAESTRO VISION",
    "address": "TRŠKA CESTA 002, PODČETRTEK, 3254, Slovenia Country",
    "longitude": 15.609526,
    "latitude": 46.1598
  },
  {
    "id": 477,
    "name": "OPTIKA ROŽIČ",
    "address": "CELJSKA CESTA 24B, VOJNIK, 3212, Slovenia Country",
    "longitude": 15.310014,
    "latitude": 46.295216
  },
  {
    "id": 478,
    "name": "OPTIKA OFTALMOS Ptuj",
    "address": "PUHOVA ULICA 21, PTUJ, 2250, Slovenia Country",
    "longitude": 15.869679,
    "latitude": 46.420262
  },
  {
    "id": 479,
    "name": "OPTIKA CLARUS VELENJE",
    "address": "CELJSKA CESTA 40, VELENJE, 3320, Slovenia Country",
    "longitude": 15.110658,
    "latitude": 46.362274
  },
  {
    "id": 480,
    "name": "OPTIKA CLARUS NOVA GORICA",
    "address": "Prvomajska ulica 35, NOVA GORICA, 5000, Slovenia Country",
    "longitude": 13.654116,
    "latitude": 45.96392
  },
  {
    "id": 481,
    "name": "VIDERE D.O.O.",
    "address": "VETRINJSKA ULICA 22, MARIBOR, 2000, Slovenia Country",
    "longitude": 15.646137,
    "latitude": 46.557146
  },
  {
    "id": 482,
    "name": "Optika Rimc PE Ljubljana",
    "address": "Vodnikova cesta 157, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.501506,
    "latitude": 46.052996
  },
  {
    "id": 483,
    "name": "OPTIKA CLARUS KRANJ",
    "address": "NAZORJEVA ULICA 3, KRANJ, 4000, Slovenia Country",
    "longitude": 14.356054,
    "latitude": 46.241951
  },
  {
    "id": 484,
    "name": "OPTIKA CLARUS VRHNIKA",
    "address": "LJUBLJANSKA CESTA 29, VRHNIKA, 1360, Slovenia Country",
    "longitude": 14.299594,
    "latitude": 45.96122
  },
  {
    "id": 485,
    "name": "OPTIKA CLARUS MURSKA SOBOTA BTC",
    "address": "Nemčavci 1D, MURSKA SOBOTA, 9000, Slovenia Country",
    "longitude": 16.169371,
    "latitude": 46.656072
  },
  {
    "id": 486,
    "name": "OPTIKA COLATIO",
    "address": "STARI TRG 27, SLOVENJ GRADEC, 2380, Slovenia Country",
    "longitude": 15.080252,
    "latitude": 46.51148
  },
  {
    "id": 487,
    "name": "OPTIKA CLARUS POSTOJNA",
    "address": "TRŽAŠKA CESTA 59, POSTOJNA, 6230, Slovenia Country",
    "longitude": 14.21815,
    "latitude": 45.775315
  },
  {
    "id": 488,
    "name": "OPTIKA CLARUS SLOVENSKA BISTRICA",
    "address": "TRG ALFONSA ŠARHA 10, SLOVENSKA BISTRICA, 2310, Slovenia Country",
    "longitude": 15.5732,
    "latitude": 46.392508
  },
  {
    "id": 489,
    "name": "OPTIKA CLARUS CERKNICA",
    "address": "Cesta 4. maja 17, CERKNICA, 1380, Slovenia Country",
    "longitude": 14.36267,
    "latitude": 45.79125
  },
  {
    "id": 490,
    "name": "OPTIKA CLARUS DOMŽALE",
    "address": "Cesta talcev 4, DOMŽALE, 1230, Slovenia Country",
    "longitude": 14.594853,
    "latitude": 46.139275
  },
  {
    "id": 491,
    "name": "OČESNA OPTIKA KRHLIKAR",
    "address": "ZADOBROVŠKA CESTA 18, LJUBLJANA - POLJE, 1260, Slovenia Country",
    "longitude": 14.564101,
    "latitude": 46.092175
  },
  {
    "id": 492,
    "name": "OFTALMA, D.O.O.",
    "address": "ULICA BRATOV UČAKAR 58, LJUBLJANA, 1000, Slovenia Country",
    "longitude": 14.46942,
    "latitude": 46.060931
  }
]


// export const stores2 = [
//     {
//         "id": 1,
//         "name": "O\u010cN\u00cd STUDIO ALE\u0160 \u017dEJDL S.R.O.",
//         "address": "\"VEVE\u0158\u00cd 3163\/111, BRNO, 616 00, Czech Republic\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 2,
//         "name": "OO\u010cI o\u010dn\u00ed optika s.r.o.",
//         "address": "\"\u017di\u017ekova 2964\/29, Krom\u011b\u0159\u00ed\u017e, 767 01, Czech Republic\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 3,
//         "name": "LUNETTES OPTIKA - KARVIN\u00c1",
//         "address": "\"N\u00c1DRA\u017dN\u00cd 1939\/4A, KARVIN\u00c1, 733 01, Czech Republic\"",
//         "longitude": -76.5314817,
//         "latitude": 38.802157
//     },
//     {
//         "id": 4,
//         "name": "AXIS OPTIK S.R.O.",
//         "address": "\"Kosteln\u00ed 168, Doma\u017elice, 344 01, Czech Republic\"",
//         "longitude": 14.4709678,
//         "latitude": 49.7660392
//     },
//     {
//         "id": 5,
//         "name": "OPTIK CENTRUM PLUS",
//         "address": "\"V\u00cdDE\u0147SK\u00c1   HYPERNOVA ZNOJMO 684\/69, ZNOJMO, 669 02, Czech Republic\"",
//         "longitude": -97.5975927,
//         "latitude": 40.9408013
//     },
//     {
//         "id": 6,
//         "name": "O\u010dn\u00ed optika Alena Boh\u00e1\u010dov\u00e1",
//         "address": "\"Kl\u00e1\u0161tern\u00ed 11, Vysok\u00e9 M\u00fdto, 566 01, Czech Republic\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 7,
//         "name": "DIOPTRA OPTIK S.R.O.-O\u010cN\u00cd OPTIKA",
//         "address": "\"T.G.MASARYKA 12, SU\u0160ICE, 342 01, Czech Republic\"",
//         "longitude": -76.5314817,
//         "latitude": 38.802157
//     },
//     {
//         "id": 8,
//         "name": "LUNETTES OPTIKA - OV-CENTRUM",
//         "address": "\"28.\u0158\u00cdJNA 49\/23, MORAVSK\u00c1 OSTRAVA, 702 00, Czech Republic\"",
//         "longitude": 14.4709678,
//         "latitude": 49.7660392
//     },
//     {
//         "id": 9,
//         "name": "LUNETTES OPTIKA - N.KAROL\u00cdNA",
//         "address": "\"JANTAROV\u00c1 3344\/4, OSTRAVA 1 - NOV\u00c1 KAROL\u00cdNA, 702 00, Czech Republic\"",
//         "longitude": -97.5975927,
//         "latitude": 40.9408013
//     },
//     {
//         "id": 10,
//         "name": "O\u010cN\u00cd OPTIKA HORN\u00cd PO\u010cERNICE S.R.O.",
//         "address": "\"OBCHODN\u00cd 2694\/2, PRAHA-HORN\u00cd PO\u010cERNICE, 193 00, Czech Republic\"",
//         "longitude": 13.4170302,
//         "latitude": 49.6963491
//     },
//     {
//         "id": 11,
//         "name": "OPTOPLUS S.R.O.",
//         "address": "\"Husova 77, Prachatice - Prachatice I, 383 01, Czech Republic\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 12,
//         "name": "OPTOMEDIC DD S.R.O.",
//         "address": "\"GEN. SVOBODY 3069\/19B, \u0160UMPERK, 787 01, Czech Republic\"",
//         "longitude": -76.9800935,
//         "latitude": 38.9898804
//     },
//     {
//         "id": 13,
//         "name": "O\u010dn\u00ed studio Optix, s.r.o.",
//         "address": "\"Masarykovo n\u00e1m\u011bst\u00ed 1219\/52, Jihlava, 586 01, Czech Republic\"",
//         "longitude": 46.7022149,
//         "latitude": 24.6764021
//     },
//     {
//         "id": 14,
//         "name": "DIOPTRA OPTIK S.R.O.-O\u010cN\u00cd OPTIKA",
//         "address": "\"T\u0158.J.P.KOUBKA 86, BLATN\u00c1, 388 01, Czech Republic\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 15,
//         "name": "PRISM OPTIC",
//         "address": "\"Je\u010dn\u00e1 6, Praha 2, 120 00, Czech Republic\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 16,
//         "name": "IRIS o\u010dn\u00ed optika s.r.o.",
//         "address": "\"Masarykovo n\u00e1m. 144\/26, Jesen\u00edk, 790 01, Czech Republic\"",
//         "longitude": -76.8503754,
//         "latitude": 38.8813417
//     },
//     {
//         "id": 17,
//         "name": "COOLOPTICS S.R.O.",
//         "address": "\"Hartigova 1626\/177, PRAHA 3 - \u017dI\u017dKOV, 130 00, Czech Republic\"",
//         "longitude": -76.5314817,
//         "latitude": 38.802157
//     },
//     {
//         "id": 18,
//         "name": "DIOPTRA OPTIK S.R.O.-O\u010cN\u00cd OPTIKA",
//         "address": "\"ALEJ SVOBODY 659\/29, PLZE\u0147, 323 00, Czech Republic\"",
//         "longitude": -76.7105632,
//         "latitude": 38.4157515
//     },
//     {
//         "id": 19,
//         "name": "OPTIK KROUMAN",
//         "address": "\"Josefsk\u00e1 516\/1, Brno - m\u011bsto, 602 00, Czech Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 20,
//         "name": "JOSEF \u0160VARC",
//         "address": "\"Klatovsk\u00e1 t\u0159\u00edda 131\/26, Plze\u0148 - Ji\u017en\u00ed P\u0159edm\u011bst\u00ed, 301 00, Czech Republic\"",
//         "longitude": 14.4709678,
//         "latitude": 49.7660392
//     },
//     {
//         "id": 21,
//         "name": "JOSEF MACHAL\u00cdK",
//         "address": "\"B\u00c1NOV 508, B\u00c1NOV U UHERSK\u00c9HO BRODU, 687 54, Czech Republic\"",
//         "longitude": -77.0401649,
//         "latitude": 38.8976744
//     },
//     {
//         "id": 22,
//         "name": "Optometrista Ji\u0159\u00ed B\u00edma s.r.o.",
//         "address": "\"HUSOVA 79, JI\u010c\u00cdN-VALDICK\u00c9 P\u0158EDM., 506 01, Czech Republic\"",
//         "longitude": -97.5975927,
//         "latitude": 40.9408013
//     },
//     {
//         "id": 23,
//         "name": "KATE\u0158INA NOVOTN\u00c1",
//         "address": "\"U N\u00c1DRA\u017d\u00cd 1014\/1, TEPLICE, 415 01, Czech Republic\"",
//         "longitude": 13.4170302,
//         "latitude": 49.6963491
//     },
//     {
//         "id": 24,
//         "name": "OPTIKA RUNGE s.r.o",
//         "address": "\"KOMENSK\u00c9HO N\u00c1M. 155, VOTICE, 259 01, Czech Republic\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 25,
//         "name": "POP OPTIK (SIRIUS S.R.O.)",
//         "address": "\"\u010cechovsk\u00e1 55, P\u0159\u00edbram, 261 01, Czech Republic\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 26,
//         "name": "AS OPTIC S.R.O.",
//         "address": "\"\u00daJEZD 454\/46, PRAHA - MAL\u00c1 STRANA, 118 00, Czech Republic\"",
//         "longitude": -76.9800935,
//         "latitude": 38.9898804
//     },
//     {
//         "id": 27,
//         "name": "OPTIPRO A.S.",
//         "address": "\"U D\u00c1LNICE 777, BRNO - MOD\u0158ICE, 664 42, Czech Republic\"",
//         "longitude": -76.9758274,
//         "latitude": 39.1762439
//     },
//     {
//         "id": 28,
//         "name": "DOKTOR OPTIK Group s.r.o.",
//         "address": "\"HORN\u00cd BRA\u0160OV 253, T\u00ddN NAD VLTAVOU, 375 01, Czech Republic\"",
//         "longitude": 46.7022149,
//         "latitude": 24.6764021
//     },
//     {
//         "id": 29,
//         "name": "APIA CZ, s.r.o. (O\u010cN\u00cd OPTIKA APIA)",
//         "address": "\"Masarykova 18, Brno, 602 00, Czech Republic\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 30,
//         "name": "Oculista trade s.r.o.",
//         "address": "\"N\u00e1m\u011bst\u00ed M\u00edru 163\/I, Jind\u0159ich\u016fv Hradec, 377 01, Czech Republic\"",
//         "longitude": -77.43,
//         "latitude": 39.01
//     },
//     {
//         "id": 31,
//         "name": "IVETA PLEYEROV\u00c1 (O\u010cN\u00cd OPTIKA)",
//         "address": "\"VELK\u00c1 DOMINIK\u00c1NSK\u00c1 121\/18, LITOM\u011a\u0158ICE, 412 01, Czech Republic\"",
//         "longitude": -77.4587239,
//         "latitude": 38.8193117
//     },
//     {
//         "id": 32,
//         "name": "LUNETTES OPTIKA - BRNO",
//         "address": "\"PANSK\u00c1 2\/4, BRNO, BRNO-M\u011aSTO, 602 00, Czech Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 33,
//         "name": "MARGITA FILIPOV\u00c1",
//         "address": "\"N\u00c1M\u011aST\u00cd 3333\/35A, KRAVA\u0158E, 747 21, Czech Republic\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 34,
//         "name": "OPTIKA CLARITY S.R.O.",
//         "address": "\"Masarykovo n\u00e1m\u011bst\u00ed 58\/33, Kyjov, 697 01, Czech Republic\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 35,
//         "name": "DIOPTRA OPTIK S.R.O.-O\u010cN\u00cd OPTIKA",
//         "address": "\"SKUPOVA 490\/24 - OC LUNA, PLZE\u0147, 326 00, Czech Republic\"",
//         "longitude": -76.8503754,
//         "latitude": 38.8813417
//     },
//     {
//         "id": 36,
//         "name": "OXA OPTIK S.R.O.",
//         "address": "\"MASARYKOVA T\u0158\u00cdDA 1333, ORLOV\u00c1 LUTYN\u011a, 735 14, Czech Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.7579835
//     },
//     {
//         "id": 37,
//         "name": "POP OPTIK (SIRIUS S.R.O.)",
//         "address": "\"N\u00e1dra\u017en\u00ed 29\/21, Praha 5 - And\u011bl, 150 00, Czech Republic\"",
//         "longitude": -76.5314817,
//         "latitude": 38.802157
//     },
//     {
//         "id": 38,
//         "name": "POP OPTIK (SIRIUS S.R.O.)",
//         "address": "\"17. listopadu 1188\/5, \u0158\u00ed\u010dany, 251 01, Czech Republic\"",
//         "longitude": 30.8173157,
//         "latitude": -29.8470354
//     },
//     {
//         "id": 39,
//         "name": "STANISLAV PLECHL",
//         "address": "\"HUMENN\u00c1 431\/15, BRNO, 625 00, Czech Republic\"",
//         "longitude": -76.7105632,
//         "latitude": 38.4157515
//     },
//     {
//         "id": 40,
//         "name": "MGR. MAGDALENA \u0160ENKE\u0158\u00cdKOV\u00c1",
//         "address": "\"TEPLICK\u00c1 893, HRANICE, 753 01, Czech Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 41,
//         "name": "O\u010dn\u00ed optika Cornea Lexum s.r.o.",
//         "address": "\"T\u0158\u00cdDA V\u00c1CLAVA KLEMENTA 1459, MLAD\u00c1 BOLESLAV, 293 01, Czech Republic\"",
//         "longitude": 14.4709678,
//         "latitude": 49.7660392
//     },
//     {
//         "id": 42,
//         "name": "DR. OPTIK S.R.O.",
//         "address": "\"U VOJENSK\u00c9 NEMOCNICE 1200\/1, PRAHA, 162 00, Czech Republic\"",
//         "longitude": 30.817147,
//         "latitude": -29.851338
//     },
//     {
//         "id": 43,
//         "name": "Optika Optometrie(Kl\u00e1ra Strnadlov\u00e1)",
//         "address": "\"Masarykovo n\u00e1m\u011bst\u00ed 85, Hranice, 753 01, Czech Republic\"",
//         "longitude": -77.0401649,
//         "latitude": 38.8976744
//     },
//     {
//         "id": 44,
//         "name": "Eva Martinkov\u00e1",
//         "address": "\"Palack\u00e9ho n\u00e1m. 1600, Rosice, 665 01, Czech Republic\"",
//         "longitude": -97.5975927,
//         "latitude": 40.9408013
//     },
//     {
//         "id": 45,
//         "name": "Optiscont s.r.o.",
//         "address": "\"Dvoreck\u00e1 2A, Praha 4, 147 00, Czech Republic\"",
//         "longitude": -77.4744421,
//         "latitude": 39.0418129
//     },
//     {
//         "id": 46,
//         "name": "OPTIKA SLAVKOV s.r.o.",
//         "address": "\"Palack\u00e9ho n\u00e1m. 77, Slavkov u Brna, 684 01, Czech Republic\"",
//         "longitude": 13.4170302,
//         "latitude": 49.6963491
//     },
//     {
//         "id": 47,
//         "name": "O\u010cN\u00cd OPTIKA DANIELA, s.r.o.",
//         "address": "\"N\u00e1dra\u017en\u00ed 398, Fr\u00fddlant nad Ostravic\u00ed - Fr\u00fddlant, 739 11, Czech Republic\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 48,
//         "name": "OPTIKA VH (Optika Hadam\u010d\u00edkov\u00e1 sro)",
//         "address": "\"Holasick\u00e1 1154\/2, Opava - Kate\u0159inky, 747 05, Czech Republic\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 49,
//         "name": "OPTIK SPEKTRUM S.R.O.",
//         "address": "\"VINOHRADSK\u00c1 1292\/65, PRAHA 2, 120 00, Czech Republic\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 50,
//         "name": "DR. OPTIK S.R.O.",
//         "address": "\"\u0158evnick\u00e1 1\/21, Praha 5 - Zli\u010d\u00edn, 155 21, Czech Republic\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 51,
//         "name": "VISUS SPOL. S R.O.",
//         "address": "\"ZENKLOVA 40, PRAHA 8, 180 00, Czech Republic\"",
//         "longitude": -77.638999,
//         "latitude": 39.2753346
//     },
//     {
//         "id": 52,
//         "name": "Relax OC Chodov",
//         "address": "\"Roztylsk\u00e1 2321\/19, Praha 4, 148 00, Czech Republic\"",
//         "longitude": -77.5340315,
//         "latitude": 38.9867355
//     },
//     {
//         "id": 53,
//         "name": "Lunettes Outlet Arena Moravia",
//         "address": "\"Hlu\u010d\u00ednsk\u00e1 1176\/i07, Ostrava, 702 00, Czech Republic\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 54,
//         "name": "DR. OPTIK S.R.O.",
//         "address": "\"Arm\u00e9nsk\u00e1 3277, Kladno, 272 01, Czech Republic\"",
//         "longitude": -76.9800935,
//         "latitude": 38.9898804
//     },
//     {
//         "id": 55,
//         "name": "Superswim \u010cestlice",
//         "address": "\"Pra\u017esk\u00e1 138, \u010cestlice, 251 01, Czech Republic\"",
//         "longitude": 14.5829803,
//         "latitude": 50.0036532
//     },
//     {
//         "id": 56,
//         "name": "Oculista trade s.r.o.",
//         "address": "\"Karlovo n\u00e1m\u011bst\u00ed 201, Pelh\u0159imov, 393 01, Czech Republic\"",
//         "longitude": -76.9758274,
//         "latitude": 39.1762439
//     },
//     {
//         "id": 57,
//         "name": "AXEL OPTIK S.R.O.",
//         "address": "\"MSGRE. B. STA\u0160KA 16, DOMA\u017dLICE, 344 01, Czech Republic\"",
//         "longitude": 46.7022149,
//         "latitude": 24.6764021
//     },
//     {
//         "id": 58,
//         "name": "EUROOPTIK - O\u010cN\u00cd OPTIKA (03)",
//         "address": "\"\u017dI\u017dKOVO N\u00c1M. 120\/2, PROST\u011aJOV, 796 01, Czech Republic\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 59,
//         "name": "Optic Shop (Bc. Krist\u00fdna Dr\u00e1\u017ekov\u00e1)",
//         "address": "\"Hartigova 1685\/110, Praha 3 - \u017di\u017ekov, 130 00, Czech Republic\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 60,
//         "name": "AMBRA PLUS S.R.O.",
//         "address": "\"JUGOSL\u00c1VSK\u00c1 236\/13, PRAHA 2, 120 00, Czech Republic\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 61,
//         "name": "JAN RADA",
//         "address": "\"DR. DAVIDA BECHERA 893\/6, KARLOVY VARY, 360 01, Czech Republic\"",
//         "longitude": -77.43,
//         "latitude": 39.01
//     },
//     {
//         "id": 62,
//         "name": "Relax Avion Ostrava",
//         "address": "\"Rudn\u00e1 114\/3114, Ostrava - Z\u00e1b\u0159eh, 700 30, Czech Republic\"",
//         "longitude": -77.4587239,
//         "latitude": 38.8193117
//     },
//     {
//         "id": 63,
//         "name": "LUNETTES OPTIKA - PUA-GLOBUS",
//         "address": "\"POD\u011aBRADSK\u00c1 293, PARDUBICE, 530 02, Czech Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 64,
//         "name": "EUROOPTIK - O\u010cN\u00cd OPTIKA (42)",
//         "address": "\"HRN\u010c\u00cd\u0158SK\u00c1 121\/3, OPAVA, 746 01, Czech Republic\"",
//         "longitude": -76.9239727,
//         "latitude": 38.8370963
//     },
//     {
//         "id": 65,
//         "name": "O\u010cN\u00cd OPTIKA (V\u00edt K\u0159epela)",
//         "address": "\"N\u00e1m\u011bst\u00ed M\u00edru 2, Hru\u0161ovany nad Jevi\u0161ovkou, 671 67, Czech Republic\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 66,
//         "name": "OPTIKA JAKE\u0160OV\u00c1, S.R.O.",
//         "address": "\"N\u00c1M.SVOBODY 527, T\u0158INEC -LY\u017dBICE, 739 61, Czech Republic\"",
//         "longitude": -77.0277275,
//         "latitude": 38.9003884
//     },
//     {
//         "id": 67,
//         "name": "OPTIKA REVISUS s.r.o.",
//         "address": "\"t\u0159\u00edda Tom\u00e1\u0161e Bati 508, Zl\u00edn, 760 01, Czech Republic\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 68,
//         "name": "O\u010dn\u00ed optika Moudr\u00e1 - \u0160ediv\u00e1, s.r.o.",
//         "address": "\"\u0158EZNICK\u00c1 235, BENE\u0160OV, 256 01, Czech Republic\"",
//         "longitude": -76.8503754,
//         "latitude": 38.8813417
//     },
//     {
//         "id": 69,
//         "name": "OPTIK PANENKA (DIOPTRA O\u010cN\u00cd OPTIKA)",
//         "address": "\"ST\u0158ELECK\u00c1 839\/27, HRADEC KR\u00c1LOV\u00c9, 500 02, Czech Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.7579835
//     },
//     {
//         "id": 70,
//         "name": "OPTIKA KA\u010cMA\u0158 S.R.O.",
//         "address": "\"\u010cS. ARM\u00c1DY 1402\/6A, HLU\u010c\u00cdN, 748 01, Czech Republic\"",
//         "longitude": -76.9354979,
//         "latitude": 38.8178384
//     },
//     {
//         "id": 71,
//         "name": "Mgr.Tereza Pu\u010d\u00e1lkov\u00e1 s.r.o.",
//         "address": "\"Ostru\u017enick\u00e1 323\/4, Olomouc, 779 00, Czech Republic\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 72,
//         "name": "MGR. LADISLAVA KURUCOV\u00c1",
//         "address": "\"\u00daJEZD 439, STR\u00c1\u017dNICE, 696 62, Czech Republic\"",
//         "longitude": 14.6032241,
//         "latitude": 50.0121978
//     },
//     {
//         "id": 73,
//         "name": "OXA OPTIK S.R.O.",
//         "address": "\"HLAVN\u00cd T\u0158\u00cdDA 1024\/51, OSTRAVA - PORUBA, 702 00, Czech Republic\"",
//         "longitude": -76.5314817,
//         "latitude": 38.802157
//     },
//     {
//         "id": 74,
//         "name": "OPTILUX s.r.o.",
//         "address": "\"Nov\u00e9 sady 996\/25 (Titanium), Brno, 602 00, Czech Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 75,
//         "name": "OPTIKA - \u0160IM\u016eNKOV\u00c1 Teplice, s.r.o.",
//         "address": "\"Bene\u0161ovo n\u00e1m\u011bst\u00ed 422\/3, Teplice, 415 01, Czech Republic\"",
//         "longitude": 30.8173157,
//         "latitude": -29.8470354
//     },
//     {
//         "id": 76,
//         "name": "MGR. PAVEL DIENSTBIER",
//         "address": "\"SMETANOVA 764, KOL\u00cdN, 280 02, Czech Republic\"",
//         "longitude": -76.5083562,
//         "latitude": 38.8302686
//     },
//     {
//         "id": 77,
//         "name": "EUROOPTIK - O\u010cN\u00cd OPTIKA (32)",
//         "address": "\"Riegrova 384\/28, OLOMOUC, 779 00, Czech Republic\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 78,
//         "name": "ZDEN\u011aK NOVOTN\u00dd",
//         "address": "\"OKRU\u017dN\u00cd 188\/7, MOHELNICE, 789 85, Czech Republic\"",
//         "longitude": -77.79,
//         "latitude": 38.71
//     },
//     {
//         "id": 79,
//         "name": "JI\u0158\u00cd J\u00cdCHA",
//         "address": "\"DEJVICK\u00c1 687\/25, PRAHA - DEJVICE, 160 00, Czech Republic\"",
//         "longitude": -76.3405733,
//         "latitude": 37.9994332
//     },
//     {
//         "id": 80,
//         "name": "O\u010dn\u00ed optika Cornea Lexum s.r.o.",
//         "address": "\"Antala Sta\u0161ka 1670\/80, Praha 4, 144 00, Czech Republic\"",
//         "longitude": -77.939597,
//         "latitude": 38.932314
//     },
//     {
//         "id": 81,
//         "name": "OPTIKA CLARITY S.R.O.",
//         "address": "\"ZNOJEMSK\u00c1 1383, T\u0158EB\u00cd\u010c, 674 01, Czech Republic\"",
//         "longitude": -76.7105632,
//         "latitude": 38.4157515
//     },
//     {
//         "id": 82,
//         "name": "ING. LEOPOLD KO\u0160\u0164\u00c1L",
//         "address": "\"LIBU\u0160INA T\u0158\u00cdDA 580\/4, BRNO, 623 00, Czech Republic\"",
//         "longitude": -76.8317325,
//         "latitude": 38.7443023
//     },
//     {
//         "id": 83,
//         "name": "Relax OC Futurum",
//         "address": "\"V\u00edde\u0148sk\u00e1 100, Brno, 619 00, Czech Republic\"",
//         "longitude": -76.5372625,
//         "latitude": 38.2818635
//     },
//     {
//         "id": 84,
//         "name": "HANA KROUMANOV\u00c1 O\u010cN\u00cd OPTIKA",
//         "address": "\"MERHAUTOVA 955\/77, BRNO - BRNO M\u011aSTO, 613 00, Czech Republic\"",
//         "longitude": -76.8028933,
//         "latitude": 38.6740691
//     },
//     {
//         "id": 85,
//         "name": "O\u010dn\u00ed optika Cornea Lexum s.r.o.",
//         "address": "\"Soukalova 3355, Poliklinika, PRAHA 4 - MOD\u0158ANY, 143 01, Czech Republic\"",
//         "longitude": 69.2377724,
//         "latitude": 41.3583036
//     },
//     {
//         "id": 86,
//         "name": "AXEL OPTIK S.R.O.",
//         "address": "\"MOSKEVSK\u00c1 911\/6, KARLOVY VARY, 360 01, Czech Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 87,
//         "name": "Martin Zem\u00e1nek - Optik Zem\u00e1nek",
//         "address": "\"Doln\u00ed N\u00e1m\u011bst\u00ed 7, Jablonec nad Nisou, 466 01, Czech Republic\"",
//         "longitude": 14.4709678,
//         "latitude": 49.7660392
//     },
//     {
//         "id": 88,
//         "name": "DR. OPTIK S.R.O.",
//         "address": "\"HUSOVA 92, SLAN\u00dd, 274 01, Czech Republic\"",
//         "longitude": 30.817147,
//         "latitude": -29.851338
//     },
//     {
//         "id": 89,
//         "name": "DR. OPTIK S.R.O.",
//         "address": "\"Husovo n\u00e1m\u011bst\u00ed 39\/26, Beroun, 266 01, Czech Republic\"",
//         "longitude": -77.0401649,
//         "latitude": 38.8976744
//     },
//     {
//         "id": 90,
//         "name": "Sport and Style Optic s.r.o.",
//         "address": "\"\u010ceskomoravsk\u00e1 2420\/15a (Galerie Har, Praha 9 - Libe\u0148, 190 00, Czech Republic\"",
//         "longitude": -77.3381855,
//         "latitude": 38.9552797
//     },
//     {
//         "id": 91,
//         "name": "DIOPTRA OPTIK S.R.O.-O\u010cN\u00cd OPTIKA",
//         "address": "\"KLATOVSK\u00c1 T\u0158\u00cdDA 348\/10, PLZE\u0147, 301 26, Czech Republic\"",
//         "longitude": -97.5975927,
//         "latitude": 40.9408013
//     },
//     {
//         "id": 92,
//         "name": "Optik Skryjov\u00e1 s.r.o.",
//         "address": "\"Masarykova t\u0159\u00edda 23, Opava, 746 01, Czech Republic\"",
//         "longitude": -76.9239727,
//         "latitude": 38.8370963
//     },
//     {
//         "id": 93,
//         "name": "OPTIKA \u0160\u016eS & ZUSKOV\u00c1, S.R.O.",
//         "address": "\"Kanovnick\u00e1 376\/5, \u010cesk\u00e9 Bud\u011bjovice, 370 01, Czech Republic\"",
//         "longitude": -76.9985069,
//         "latitude": 38.8741167
//     },
//     {
//         "id": 94,
//         "name": "LUNETTES OPTIKA (CENTRUM G\u00c9\u010cKO)",
//         "address": "\"Spojovac\u00ed 396, Ostrava, 725 27, Czech Republic\"",
//         "longitude": -77.4744421,
//         "latitude": 39.0418129
//     },
//     {
//         "id": 95,
//         "name": "O\u010dn\u00ed centrum Janou\u0161ek, s.r.o.",
//         "address": "\"Komensk\u00e9ho 128, Chropyn\u011b, 768 11, Czech Republic\"",
//         "longitude": 13.4170302,
//         "latitude": 49.6963491
//     },
//     {
//         "id": 96,
//         "name": "JITKA NECHANICK\u00c1",
//         "address": "\"LETN\u00cd 341, BRADLEC, 293 06, Czech Republic\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 97,
//         "name": "Relax OC \u0160antovka Olomouc",
//         "address": "\"Polsk\u00e1 1201\/1, Olomouc, 772 00, Czech Republic\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 98,
//         "name": "AXEL OPTIK S.R.O.",
//         "address": "\"Sokolovsk\u00e1 447, Hol\u00fd\u0161ov, 345 62, Czech Republic\"",
//         "longitude": -76.99,
//         "latitude": 38.92
//     },
//     {
//         "id": 99,
//         "name": "DIOPTRA OPTIK S.R.O.-O\u010cN\u00cd OPTIKA",
//         "address": "\"SOBOTECK\u00c1 1660, TURNOV, 511 21, Czech Republic\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 100,
//         "name": "Relax OC Han\u00e1 Olomouc",
//         "address": "\"Kafkova 8, Olomouc, 779 00, Czech Republic\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 101,
//         "name": "P\u00c9CSI EMENTROP-INVESTMENT KFT.",
//         "address": "\"P\u00c9CS PL\u00c1ZA MEGYERI \u00daT 76., P\u00c9CS, 7631, Hungary\"",
//         "longitude": -77.638999,
//         "latitude": 39.2753346
//     },
//     {
//         "id": 102,
//         "name": "HONTI OPTIKA KFT.",
//         "address": "\"APPONYI T\u00c9R 7., J\u00c1SZBER\u00c9NY, 5100, Hungary\"",
//         "longitude": -77.5340315,
//         "latitude": 38.9867355
//     },
//     {
//         "id": 103,
//         "name": "MINION TRADE KFT.",
//         "address": "\"J\u00d3ZSEF ATTILA U. 43, F\u00d3T, 2151, Hungary\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 104,
//         "name": "TIAGAM KFT.",
//         "address": "\"Z\u00c1KONYI FERENC U. 6, BALATONF\u00dcRED, 8230, Hungary\"",
//         "longitude": -76.9800935,
//         "latitude": 38.9898804
//     },
//     {
//         "id": 105,
//         "name": "S\u00c9RTZ OPTIKAI BT.",
//         "address": "\"\u00dcLL\u0150I \u00daT 461., BUDAPEST, 1181, Hungary\"",
//         "longitude": 30.8147473,
//         "latitude": -29.8486293
//     },
//     {
//         "id": 106,
//         "name": "OPTIKA MAXIMA KFT.",
//         "address": "\"CSIGEKERT U. 13., DEBRECEN, 4027, Hungary\"",
//         "longitude": 14.5829803,
//         "latitude": 50.0036532
//     },
//     {
//         "id": 107,
//         "name": "VICAI OPTIKA KFT.",
//         "address": "\"SZENT ISTV\u00c1N KIR\u00c1LY \u00daT 37., KALOCSA, 6300, Hungary\"",
//         "longitude": -76.9758274,
//         "latitude": 39.1762439
//     },
//     {
//         "id": 108,
//         "name": "MIKL\u00d3SI KONTALUX KFT.",
//         "address": "\"F\u0150 T\u00c9R 7., SZOMBATHELY, 9700, Hungary\"",
//         "longitude": 46.7022149,
//         "latitude": 24.6764021
//     },
//     {
//         "id": 109,
//         "name": "T\u00c1NCZOS R\u00c9KA E.V.",
//         "address": "\"D\u00c9V\u00c9NYI UTCA 3., SZ\u00c9KESFEH\u00c9RV\u00c1R, 8000, Hungary\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 110,
//         "name": "ALEX OPTIKA KFT",
//         "address": "\"LACKNER KRIST\u00d3F U. 35. (PL\u00c1ZA), SOPRON, 9400, Hungary\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 111,
//         "name": "Medicover Optika Kft- Eiffel Optika",
//         "address": "\"Ter\u00e9z k\u00f6r\u00fat 55-57., Budapest, 1062, Hungary\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 112,
//         "name": "GALKER OPTIKA KFT.",
//         "address": "\"KERTV\u00c1ROS U. 3., FEH\u00c9RGYARMAT, 4900, Hungary\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 113,
//         "name": "LIPO OPTIKA KFT",
//         "address": "\"CZIRFUSZ F U. 20., BAJA, 6500, Hungary\"",
//         "longitude": -77.43,
//         "latitude": 39.01
//     },
//     {
//         "id": 114,
//         "name": "POLAR VISION KFT.",
//         "address": "\"V\u00cdGH U. 8. 1. EM. 3., BUDAPEST, 1084, Hungary\"",
//         "longitude": -77.4587239,
//         "latitude": 38.8193117
//     },
//     {
//         "id": 115,
//         "name": "EURO-OPTIC-MISKOLC KFT.",
//         "address": "\"SZENT ISTV\u00c1N \u00daT 13., TISZA\u00daJV\u00c1ROS, 3580, Hungary\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 116,
//         "name": "CENTER OPTIKA L\u00c1T\u00c1SCENTRUM",
//         "address": "\"KOSSUTH L. 17-19., ZALAEGERSZEG, 8900, Hungary\"",
//         "longitude": -76.9239727,
//         "latitude": 38.8370963
//     },
//     {
//         "id": 117,
//         "name": "TREND-OPTIK KFT.",
//         "address": "\"T\u00f6lgyfa sor 11., SOPRON, 9400, Hungary\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 118,
//         "name": "ZSOLDOS TAM\u00c1S",
//         "address": "\"V\u00c1ROSH\u00c1Z U. 4, K\u0150SZEG, 9730, Hungary\"",
//         "longitude": 103.852861,
//         "latitude": 1.307018
//     },
//     {
//         "id": 119,
//         "name": "R\u00c1BABRILL KFT.",
//         "address": "\"M\u00c1RT\u00cdROK TERE 1., CSORNA, 9300, Hungary\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 120,
//         "name": "KOV\u00c1CS VERONIKA",
//         "address": "\"SZ\u00c9CHENYI U. 52, SZEGHALOM, 5520, Hungary\"",
//         "longitude": -77.0277275,
//         "latitude": 38.9003884
//     },
//     {
//         "id": 121,
//         "name": "O3 OPTIKA KFT.",
//         "address": "\"SZ\u00c9PV\u00d6LGYI \u00daT 49-61. C. \u00c9P. 5., BUDAPEST, 1037, Hungary\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 122,
//         "name": "Optic World Exclusive- Soroks\u00e1r A.",
//         "address": "\"Bev\u00e1s\u00e1rl\u00f3 utca 2. Auchan, Budapest, 1239, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 123,
//         "name": "Sifra Kft  (OWE)",
//         "address": "\"Liliom u. 49\/A., Ny\u00edregyh\u00e1za, 4400, Hungary\"",
//         "longitude": -76.8503754,
//         "latitude": 38.8813417
//     },
//     {
//         "id": 124,
//         "name": "SIM\u00c1K GY\u00d6RGYN\u00c9 E.V.",
//         "address": "\"SZENT L\u00c1SZL\u00d3 U. 24., KISV\u00c1RDA, 4600, Hungary\"",
//         "longitude": -76.9066829,
//         "latitude": 38.7579835
//     },
//     {
//         "id": 125,
//         "name": "SUN and EYE Kft.",
//         "address": "\"V\u00e1ci utca 9. fsz. 7, Budapest, 1052, Hungary\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 126,
//         "name": "WOLF'S EYE KFT",
//         "address": "\"FELS\u0150 SZANDAI R\u00c9T 1., SZOLNOK, 5000, Hungary\"",
//         "longitude": -76.9354979,
//         "latitude": 38.8178384
//     },
//     {
//         "id": 127,
//         "name": "VOONZ KFT",
//         "address": "\"KOSSUTH U. 4., KESZTHELY, 8360, Hungary\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 128,
//         "name": "Optic World Exclusive-\u00c1rk\u00e1d Pl\u00e1za",
//         "address": "\"\u00d6rs vez\u00e9r tere 25\/A \u00c1rk\u00e1d Pl\u00e1za, Budapest, 1106, Hungary\"",
//         "longitude": 14.6032241,
//         "latitude": 50.0121978
//     },
//     {
//         "id": 129,
//         "name": "HATALY\u00c1K \u00c9S T\u00c1RSA KFT",
//         "address": "\"KOSSUTH LAJOS UTCA 34., ASZ\u00d3D, 2170, Hungary\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 130,
//         "name": "PG \u00cdRISZ KFT.",
//         "address": "\"KOSSUTH UTCA 4., HOSSZ\u00daV\u00d6LGY, 8777, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 131,
//         "name": "Optic World Exclusive- Gy\u0151r \u00c1rk\u00e1d",
//         "address": "\"Budai \u00fat 1. Gy\u0151r \u00c1rk\u00e1d, Gy\u0151r, 9027, Hungary\"",
//         "longitude": -76.5314817,
//         "latitude": 38.802157
//     },
//     {
//         "id": 132,
//         "name": "KONTAKT BT",
//         "address": "\"KOSSUTH L. U. 30, KESZTHELY, 8360, Hungary\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 133,
//         "name": "OPTIKA SOLE KFT",
//         "address": "\"OLCSAY T\u00c9R 2. 3. EM. 14., K\u00d6RMEND, 9900, Hungary\"",
//         "longitude": 30.8173157,
//         "latitude": -29.8470354
//     },
//     {
//         "id": 134,
//         "name": "Optic World Exclusive- Allee",
//         "address": "\"Okt\u00f3ber 23. utca 8-10. Allee, Budapest, 1117, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 135,
//         "name": "ANDROVER 2000 BT.",
//         "address": "\"ANDR\u00c1SSY GYULA U. 59, KEREKEGYH\u00c1ZA, 6041, Hungary\"",
//         "longitude": -76.5083562,
//         "latitude": 38.8302686
//     },
//     {
//         "id": 136,
//         "name": "KRAFT P\u00c9TER",
//         "address": "\"K\u00c1LVIN T\u00c9R 1., BUDAPEST, 1053, Hungary\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 137,
//         "name": "PAN-OPTIKA KFT.",
//         "address": "\"H\u00c1TS\u00d3KAPU 8., SOPRON, 9400, Hungary\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 138,
//         "name": "K\u00d3BOR OPTIKA BT.",
//         "address": "\"Szent Istv\u00e1n \u00fat 17, Gy\u00f6mr\u0151, 2230, Hungary\"",
//         "longitude": -77.79,
//         "latitude": 38.71
//     },
//     {
//         "id": 139,
//         "name": "F\u00dcL\u00d6P N\u00c1NDOR",
//         "address": "\"LUCSONY UTAK 5., MOSONMAGYAR\u00d3V\u00c1R, 9200, Hungary\"",
//         "longitude": 103.861385,
//         "latitude": 1.311704
//     },
//     {
//         "id": 140,
//         "name": "Optic World Exclusive-Dunakeszi A.",
//         "address": "\"N\u00e1das utca 6. Auchan, Dunakeszi, 2120, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 141,
//         "name": "VINTAGE ONE KFT.",
//         "address": "\"K\u00c1RP\u00c1T UTCA 37. 3. EM. 11, BUDAPEST, 1133, Hungary\"",
//         "longitude": 20.1502916,
//         "latitude": 46.2845795
//     },
//     {
//         "id": 142,
//         "name": "PET\u0150 OPTIKA KFT.",
//         "address": "\"SZABADS\u00c1G T\u00c9R 20., AJKA, 8400, Hungary\"",
//         "longitude": -76.3405733,
//         "latitude": 37.9994332
//     },
//     {
//         "id": 143,
//         "name": "Optic World Exclusive-Szeged \u00c1rk\u00e1d",
//         "address": "\"Londoni k\u00f6r\u00fat 3. \u00c1rk\u00e1d, Szeged, 6724, Hungary\"",
//         "longitude": -77.939597,
//         "latitude": 38.932314
//     },
//     {
//         "id": 144,
//         "name": "Optic World Exclusive- P\u00e9cs \u00c1rk\u00e1d",
//         "address": "\"Bajcsy-Zsilinszky utca 11. \u00c1rk\u00e1d, P\u00e9cs, 7622, Hungary\"",
//         "longitude": 72.5612301,
//         "latitude": 23.0365716
//     },
//     {
//         "id": 145,
//         "name": "SIGMA 9 KFT.",
//         "address": "\"HOCK J\u00c1NOS K\u00d6Z  2., SI\u00d3FOK, 8600, Hungary\"",
//         "longitude": -76.7105632,
//         "latitude": 38.4157515
//     },
//     {
//         "id": 146,
//         "name": "GY\u00d6NGYSZEM OPTIKA KFT.",
//         "address": "\"Bajnok utca 4\/B, SZOLNOK, 5000, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 147,
//         "name": "M\u00c1T\u00c9 FOT\u00d3-OPTIKA KFT.",
//         "address": "\"SZTARAVODAI \u00daT 54., SZENTENDRE, 2000, Hungary\"",
//         "longitude": 19.0701771,
//         "latitude": 47.6751122
//     },
//     {
//         "id": 148,
//         "name": "JUNOPTI93 KFT. JUNIOR OPTIKA",
//         "address": "\"SZ\u00c9CHENYI U. 2., B\u00c9K\u00c9SCSABA, 5600, Hungary\"",
//         "longitude": -76.8317325,
//         "latitude": 38.7443023
//     },
//     {
//         "id": 149,
//         "name": "Optic World Exclusive-Feh\u00e9r.AlbaPla",
//         "address": "\"Palotai \u00fat 1., Sz\u00e9kesfeh\u00e9rv\u00e1r, 8000, Hungary\"",
//         "longitude": -76.5372625,
//         "latitude": 38.2818635
//     },
//     {
//         "id": 150,
//         "name": "TITANOVA KFT. TIT\u00c1N OPTIKA",
//         "address": "\"J\u00f3zsef A u. 18., Tiszal\u00f6k, 4450, Hungary\"",
//         "longitude": -76.8028933,
//         "latitude": 38.6740691
//     },
//     {
//         "id": 151,
//         "name": "Optic World Exclusive- Deb.Tesco",
//         "address": "\"Kishegyesi \u00fat 1-11. Tesco, Debrecen, 4031, Hungary\"",
//         "longitude": 69.2377724,
//         "latitude": 41.3583036
//     },
//     {
//         "id": 152,
//         "name": "FENYVESSY OPTIKA BT.",
//         "address": "\"R\u00e1k\u00f3czi Ferenc \u00fat 312., Balatonlelle, 8638, Hungary\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 153,
//         "name": "Optic World Exclusive- Corvin Pl\u00e1za",
//         "address": "\"Fut\u00f3 utca 37. Corvin Pl\u00e1za, Budapest, 1083, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 154,
//         "name": "ARANYSZEM OPTIKA BT.",
//         "address": "\"T\u00d3V\u00c1ROSI K\u00d3RZ\u00d3 2., TATA, 2890, Hungary\"",
//         "longitude": 14.4709678,
//         "latitude": 49.7660392
//     },
//     {
//         "id": 155,
//         "name": "B\u00d6HMN\u00c9 KOV\u00c1CS MAGDOLNA",
//         "address": "\"BERZSENYI D\u00c1NIEL \u00daT 23., S\u00c1RBOG\u00c1RD, 7000, Hungary\"",
//         "longitude": 30.817147,
//         "latitude": -29.851338
//     },
//     {
//         "id": 156,
//         "name": "FEJ\u00c9R ANIK\u00d3",
//         "address": "\"IGM\u00c1NDI \u00daT 23., KOM\u00c1ROM, 2900, Hungary\"",
//         "longitude": -77.0401649,
//         "latitude": 38.8976744
//     },
//     {
//         "id": 157,
//         "name": "Optic World Exclusive-Kaposv\u00e1rTesco",
//         "address": "\"Berzsenyi utca 13., Kaposv\u00e1r, 7400, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 158,
//         "name": "CVIKKER OPTIKA KFT.",
//         "address": "\"SZAP\u00c1RY \u00daT. 18, SZOLNOK, 5000, Hungary\"",
//         "longitude": -77.3381855,
//         "latitude": 38.9552797
//     },
//     {
//         "id": 159,
//         "name": "GRE-KO HORIZON BT.",
//         "address": "\"CIG\u00c1NY UTCA 75., NAGYKANIZSA, 8800, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 160,
//         "name": "F.M. OPTIKA KFT.",
//         "address": "\"BATTHY\u00c1NY U. 9., CELLD\u00d6M\u00d6LK, 9500, Hungary\"",
//         "longitude": -97.5975927,
//         "latitude": 40.9408013
//     },
//     {
//         "id": 161,
//         "name": "KURGYISN\u00c9 PECZNYIK GY\u00d6RGYI",
//         "address": "\"R\u00c1KOS U. 2., P\u00c9CEL, 2119, Hungary\"",
//         "longitude": -76.9239727,
//         "latitude": 38.8370963
//     },
//     {
//         "id": 162,
//         "name": "Zoom Optika Kft.",
//         "address": "\"B\u00e9csi \u00fat 55., Dorog, 2510, Hungary\"",
//         "longitude": -76.9985069,
//         "latitude": 38.8741167
//     },
//     {
//         "id": 163,
//         "name": "Optic World Exclusive- Arena Mall",
//         "address": "\"Kerepesi \u00fat 9. Arena Mall, Budapest, 1087, Hungary\"",
//         "longitude": -77.4744421,
//         "latitude": 39.0418129
//     },
//     {
//         "id": 164,
//         "name": "SZINVA OPTIKA KFT.",
//         "address": "\"V\u00c1ROSH\u00c1Z T\u00c9R 20., MISKOLC, 3530, Hungary\"",
//         "longitude": 13.4170302,
//         "latitude": 49.6963491
//     },
//     {
//         "id": 165,
//         "name": "Optic World Exclusive-Kecskem.Malom",
//         "address": "\"Korona utca 2., Kecskem\u00e9t, 6000, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 166,
//         "name": "PR\u00c1GAI L\u00c1TSZER\u00c9SZETI KFT.",
//         "address": "\"BART\u00d3K B. T\u00c9R 11., SZEGED, 6722, Hungary\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 167,
//         "name": "Optic World Exclusive-Debr.Auchan",
//         "address": "\"Kishat\u00e1r utca 7, Debrecen, 4031, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 168,
//         "name": "Optic World Exclusive-Feh\u00e9rv.Auchan",
//         "address": "\"Holland fasor 2., Sz\u00e9kesfeh\u00e9rv\u00e1r, 8000, Hungary\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 169,
//         "name": "SZ\u0150KE-OPTIKA KFT.",
//         "address": "\"R\u00c9TK\u00d6Z U. 7. I\/EM, BUDAPEST, 1118, Hungary\"",
//         "longitude": -76.99,
//         "latitude": 38.92
//     },
//     {
//         "id": 170,
//         "name": "ESZIK OPTIKA KFT.",
//         "address": "\"TISZA LAJOS K\u00d6R\u00daT 75., SZEGED, 6720, Hungary\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 171,
//         "name": "OPTIK-TRADE KFT.",
//         "address": "\"BAROSS G. U. 33., BALATONALM\u00c1DI, 8220, Hungary\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 172,
//         "name": "SASV\u00c1RI KFT",
//         "address": "\"Hadak \u00fatja 1, Budapest, 1119, Hungary\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 173,
//         "name": "Optic World Exclusive-Si\u00f3 Pl\u00e1za",
//         "address": "\"F\u0151 t\u00e9r 6. Si\u00f3 Pl\u00e1za, Si\u00f3fok, 8600, Hungary\"",
//         "longitude": -77.638999,
//         "latitude": 39.2753346
//     },
//     {
//         "id": 174,
//         "name": "SZATM\u00c1RI R\u00d3BERT",
//         "address": "\"BOCSKAI U. 33, SOLTVADKERT, 6230, Hungary\"",
//         "longitude": -77.5340315,
//         "latitude": 38.9867355
//     },
//     {
//         "id": 175,
//         "name": "OPTOPONT KFT.",
//         "address": "\"KASS\u00c1K L U. 72., BUDAPEST, 1134, Hungary\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 176,
//         "name": "MOLN\u00c1R P\u00c9TER EV.",
//         "address": "\"BUDAI U. 19., B\u00c1TASZ\u00c9K, 7140, Hungary\"",
//         "longitude": -76.9800935,
//         "latitude": 38.9898804
//     },
//     {
//         "id": 177,
//         "name": "Optic World Exclusive-Szolnok",
//         "address": "\"Ady Endre \u00fat 28\/A, Szolnok, 5000, Hungary\"",
//         "longitude": 30.8147473,
//         "latitude": -29.8486293
//     },
//     {
//         "id": 178,
//         "name": "Optic World Exclusive-Solym\u00e1rAuchan",
//         "address": "\"Szent Fl\u00f3ri\u00e1n utca 2-4., Solym\u00e1r, 2083, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 179,
//         "name": "MOROPEX Trade Kft",
//         "address": "\"Szeksz\u00e1rdi u. 17. \u00dc-3., Budapest, 1138, Hungary\"",
//         "longitude": 14.5829803,
//         "latitude": 50.0036532
//     },
//     {
//         "id": 180,
//         "name": "MAT\u00d3K IPARI \u00c9S KERESK.KFT",
//         "address": "\"SZ\u00c9CH\u00c9NYI U. 28., SZEKSZ\u00c1RD, 7100, Hungary\"",
//         "longitude": -76.9758274,
//         "latitude": 39.1762439
//     },
//     {
//         "id": 181,
//         "name": "VOJTH OPTIKA KFT.",
//         "address": "\"R\u00c1K\u00d3CZI \u00daT 25., S\u00c1ROSPATAK, 3950, Hungary\"",
//         "longitude": 21.5687308,
//         "latitude": 48.3196929
//     },
//     {
//         "id": 182,
//         "name": "MARGOPTIK KFT.",
//         "address": "\"ZR\u00cdNYI U. 8-10., NY\u00cdREGYH\u00c1ZA, 4400, Hungary\"",
//         "longitude": 46.7022149,
//         "latitude": 24.6764021
//     },
//     {
//         "id": 183,
//         "name": "Tisza L\u00e1t\u00e1scentrum (Alm\u00e1di-",
//         "address": "\"Batthy\u00e1ny u.35., Szeged, 6722, Hungary\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 184,
//         "name": "SZIV\u00c1RV\u00c1NY OPTIKA CENTRUM KFT",
//         "address": "\"D\u00d3ZSA GY\u00d6RGY \u00daT 26., NAGYK\u00c1TA, 2760, Hungary\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 185,
//         "name": "SOMOS OPTIKA KFT.",
//         "address": "\"VARASD \u00daT 1., SZOMBATHELY, 9700, Hungary\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 186,
//         "name": "SASV\u00c1RI KFT",
//         "address": "\"Deres u. 2., V\u00e1c, 2600, Hungary\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 187,
//         "name": "Optic World Exclusive-Eger Tesco",
//         "address": "\"II. R\u00e1k\u00f3czi Ferenc utca 100. Tesco, Eger, 3300, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 188,
//         "name": "T\u0150R\u00d6SN\u00c9 PUSKEL KRISZTINA",
//         "address": "\"VAS\u00daT U. 7., ALBERTIRSA, 2730, Hungary\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 189,
//         "name": "Optic World Exclusive- K\u00f6Ki",
//         "address": "\"Vak Botty\u00e1n utca 75\/C K\u00f6Ki Termin\u00e1l, Budapest, 1191, Hungary\"",
//         "longitude": 30.8137581,
//         "latitude": -29.8536809
//     },
//     {
//         "id": 190,
//         "name": "Optic World Exclusive-\u00d3buda Auchan",
//         "address": "\"Szentendrei \u00fat 115., Budapest, 1033, Hungary\"",
//         "longitude": -77.43,
//         "latitude": 39.01
//     },
//     {
//         "id": 191,
//         "name": "Smartoptika - web",
//         "address": "\"Cs\u00e1nyi L\u00e1szl\u00f3 utca 34., Budapest, 1043, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 192,
//         "name": "SZ-G Rapidus Kft.",
//         "address": "\"Vasv\u00e1ri P\u00e1l utca 27., Budapest, 1155, Hungary\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 193,
//         "name": "SASV\u00c1RI KFT",
//         "address": "\"De\u00e1k Ferenc t\u00e9r 1., Zalaegerszeg, 8900, Hungary\"",
//         "longitude": 103.85765,
//         "latitude": 1.309114
//     },
//     {
//         "id": 194,
//         "name": "Optic World Exclusive- Lurdy H\u00e1z",
//         "address": "\"K\u00f6nyves K\u00e1lm\u00e1n k\u00f6r\u00fat 12-14. Lurdy, Budapest, 1097, Hungary\"",
//         "longitude": -77.4587239,
//         "latitude": 38.8193117
//     },
//     {
//         "id": 195,
//         "name": "OPTIKSTUDIO KFT.",
//         "address": "\"KOR\u00c1NYI-MORG\u00d3 U. 29268 HRSZ., NY\u00cdREGYH\u00c1ZA, 4400, Hungary\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 196,
//         "name": "SZEMF\u00c9NY CSAL\u00c1DI SZEM\u00c9SZET KFT.",
//         "address": "\"HOLENDA BARNAB\u00c1S UTCA 1. FSZ 12, GY\u0150R, 9024, Hungary\"",
//         "longitude": 20.1502916,
//         "latitude": 46.2845795
//     },
//     {
//         "id": 197,
//         "name": "FONTANA OPTIKA KFT.",
//         "address": "\"ADY ENDRE \u00daT 5., H\u00d3DMEZ\u0150V\u00c1S\u00c1RHELY, 6800, Hungary\"",
//         "longitude": -76.9239727,
//         "latitude": 38.8370963
//     },
//     {
//         "id": 198,
//         "name": "PRZECIWSLONECZNE.PL",
//         "address": "\"UL. SIENKIEWICZA 22, BIA\u0141YSTOK, 15-092, Poland\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 199,
//         "name": "OPTYK \"KAROLINA\" S.C.",
//         "address": "\"UL. DOBRAWY 6, PSZCZYNA, 43-200, Poland\"",
//         "longitude": 103.852861,
//         "latitude": 1.307018
//     },
//     {
//         "id": 200,
//         "name": "SALON OPTYCZNY OPTIMISTIC OPTYK",
//         "address": "\"UL. ZAKOPIA\u0143SKA 62, KRAK\u00d3W, 31-418, Poland\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 201,
//         "name": "STUDIO OPTYCZNE 44 DESIGNER OUTLET",
//         "address": "\"UL. ORL\u0104T LWOWSKICH 138, SOSNOWIEC, 41-208, Poland\"",
//         "longitude": 22.5941816,
//         "latitude": 51.2621626
//     },
//     {
//         "id": 202,
//         "name": "ABRETE SP. Z O.O.",
//         "address": "\"4\/1, PANCERZYN, 14-106, Poland\"",
//         "longitude": -77.0277275,
//         "latitude": 38.9003884
//     },
//     {
//         "id": 203,
//         "name": "CENTRUM OPTYCZNE KRZYSZTOF",
//         "address": "\"Grunwaldzka, nr 31, P\u0142o\u0144sk, 09-100, Poland\"",
//         "longitude": 16.8993461,
//         "latitude": 52.4049097
//     },
//     {
//         "id": 204,
//         "name": "PRACOWNIA OPTYCZNA RAFA\u0141 STARSKI",
//         "address": "\"UL. KO\u015aCIUSZKI 32, CZARNK\u00d3W, 64-700, Poland\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 205,
//         "name": "SP\u00d3\u0141KA CYWILNA RENATA KARA\u015a",
//         "address": "\"UL. TADEUSZA KO\u015aCIUSZKI 1, TARN\u00d3W, 33-100, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 206,
//         "name": "SALONY OPTYCZNE FRAS S.C.",
//         "address": "\"Ul. Stary Rynek 11, Bydgoszcz, 85-105, Poland\"",
//         "longitude": -76.8503754,
//         "latitude": 38.8813417
//     },
//     {
//         "id": 207,
//         "name": "ZAK\u0141AD OPTYCZNY MAREK RYFA",
//         "address": "\"UL. GRUNWALDZKA 82, GDA\u0143SK, 80-254, Poland\"",
//         "longitude": -76.9066829,
//         "latitude": 38.7579835
//     },
//     {
//         "id": 208,
//         "name": "LISZEWSKI PATRYK MAARCO",
//         "address": "\"UL. L\u0118DZI\u0143SKA 14, L\u0118DZINY, 43-143, Poland\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 209,
//         "name": "KRYSTYNA POKORNA-STAFIN ZAK\u0141AD",
//         "address": "\"UL. KOLEJOWA 30 C, D\u0118BICA, 39-200, Poland\"",
//         "longitude": 19.145136,
//         "latitude": 51.919438
//     },
//     {
//         "id": 210,
//         "name": "OPTYK OKULISTA SIGNUM KUSTRZ\u0118PA",
//         "address": "\"UL. BA\u017bANTOW 6C, KATOWICE, 40-668, Poland\"",
//         "longitude": -76.9354979,
//         "latitude": 38.8178384
//     },
//     {
//         "id": 211,
//         "name": "ZAK\u0141AD OPTYKI OKULAROWEJ",
//         "address": "\"UL. OBRO\u0143C\u00d3W WESTERPLATTE 8, KATOWICE, 40-336, Poland\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 212,
//         "name": "LUXVISION BEATA KWAPISZ  \u0141UKASZ",
//         "address": "\"UL. SZOSA ZAMBROWSKA 102 LOK.2, \u0141OM\u017bA, 18-400, Poland\"",
//         "longitude": 14.6032241,
//         "latitude": 50.0121978
//     },
//     {
//         "id": 213,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. Legnicka 5, Krak\u00f3w, 31-216, Poland\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 214,
//         "name": "OPTYK-DUBLET TOMASZ KOBAN, JOANNA",
//         "address": "\"UL. IWASZKIEWICZA 133B, O\u0141AWA, 55-200, Poland\"",
//         "longitude": 17.0355288,
//         "latitude": 51.0559344
//     },
//     {
//         "id": 215,
//         "name": "OPTYK - OPTOMETRYSTA S.C.",
//         "address": "\"UL. 3 MAJA 11, GORLICE, 38-300, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 216,
//         "name": "ZAK\u0141AD OPTYCZNY MIROS\u0141AW RUSZCZYK",
//         "address": "\"UL. E. ORZESZKOWEJ 36\/3, E\u0141K, 19-300, Poland\"",
//         "longitude": -76.5314817,
//         "latitude": 38.802157
//     },
//     {
//         "id": 217,
//         "name": "ZAK\u0141AD OPTYCZNY \"OPTIX-2\" S.C.",
//         "address": "\"UL. HIPOTECZNA 1, KIELCE, 25-350, Poland\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 218,
//         "name": "OPTYK \u0141\u00d3DKA",
//         "address": "\"ul.Piotrkowska 153, \u0141\u00f3d\u017a, 90-440, Poland\"",
//         "longitude": 19.4582535,
//         "latitude": 51.7605683
//     },
//     {
//         "id": 219,
//         "name": "ZIKO OPTYK",
//         "address": "\"UL. CZERWONE MAKI 33 \/CH ATUT\/, KRAK\u00d3W, 30-392, Poland\"",
//         "longitude": 30.8173157,
//         "latitude": -29.8470354
//     },
//     {
//         "id": 220,
//         "name": "ZIKO OPTYK",
//         "address": "\"UL. GRA\u017bYNY 4, KRAK\u00d3W, 31-217, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 221,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. Kr\u00f3lewska 47, Krak\u00f3w, 30-040, Poland\"",
//         "longitude": -76.5083562,
//         "latitude": 38.8302686
//     },
//     {
//         "id": 222,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. Siwi\u0144skiego 2A, Legionowo, 05-120, Poland\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 223,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. Wsp\u00f3lna 1, Olkusz, 32-300, Poland\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 224,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. \u017beromskiego 15, Mielec, 39-300, Poland\"",
//         "longitude": -77.79,
//         "latitude": 38.71
//     },
//     {
//         "id": 225,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. Jesionowa 4a, Myszk\u00f3w, 42-300, Poland\"",
//         "longitude": 103.861385,
//         "latitude": 1.311704
//     },
//     {
//         "id": 226,
//         "name": "ZOOM ARTUR MADEJ",
//         "address": "\"UL. NARUTOWICZA 74, LUBLIN, 20-013, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 227,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. Modrzejowska 16, SOSNOWIEC, 41-200, Poland\"",
//         "longitude": 20.1502916,
//         "latitude": 46.2845795
//     },
//     {
//         "id": 228,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. Armii Krajowej 43, KATOWICE, 40-698, Poland\"",
//         "longitude": -76.3405733,
//         "latitude": 37.9994332
//     },
//     {
//         "id": 229,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. Sk\u0142adowa 4, Gniezno, 62-200, Poland\"",
//         "longitude": -77.939597,
//         "latitude": 38.932314
//     },
//     {
//         "id": 230,
//         "name": "ZIKO OPTYK",
//         "address": "\"ul. Kolejowa 1, Konin, 62-510, Poland\"",
//         "longitude": 18.913334,
//         "latitude": 50.343834
//     },
//     {
//         "id": 231,
//         "name": "WOJCIECH SKRZATEK F.H.WSKRZAT",
//         "address": "\"ZAMIE\u015aCIE 201, TYMBARK, 34-650, Poland\"",
//         "longitude": 72.5612301,
//         "latitude": 23.0365716
//     },
//     {
//         "id": 232,
//         "name": "SALON OPTYCZNY TIMAK",
//         "address": "\"UL. RZE\u0179NICKA10-11B, ELBL\u0104G, 82-300, Poland\"",
//         "longitude": -76.7105632,
//         "latitude": 38.4157515
//     },
//     {
//         "id": 233,
//         "name": "ZAK\u0141AD US\u0141UGOWO-HANDLOWY \"OPTYK\"",
//         "address": "\"UL. NIEDURNEGO 53, RUDA \u015aL\u0104SKA, 41-709, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 234,
//         "name": "Zielonka Optyk sp. z o.o.",
//         "address": "\"UL. TADEUSZA KO\u015aCIUSZKI 16, KARTUZY, 83-300, Poland\"",
//         "longitude": 19.0701771,
//         "latitude": 47.6751122
//     },
//     {
//         "id": 235,
//         "name": "OPTYK KOSI\u0143SKI SP. z o.o.",
//         "address": "\"ul. SZEROKA 11, TARNOBRZEG, 39-400, Poland\"",
//         "longitude": -76.8317325,
//         "latitude": 38.7443023
//     },
//     {
//         "id": 236,
//         "name": "MARITA GNAT-JURKOJ\u0106 OPTYK - BIFOCAL",
//         "address": "\"UL. 5 STYCZNIA 38, WOLSZTYN, 64-200, Poland\"",
//         "longitude": 19.4075666,
//         "latitude": 51.6473799
//     },
//     {
//         "id": 237,
//         "name": "AND\u017bELIKA MICHALSKA SALON OPTYCZNY",
//         "address": "\"UL. STANIS\u0141AWA MONIUSZKI 2 LOK.5, KONIN, 62-510, Poland\"",
//         "longitude": 19.145136,
//         "latitude": 51.919438
//     },
//     {
//         "id": 238,
//         "name": "GABINET OKULISTYCZNY LEK.",
//         "address": "\"ul. Jeziorna 7J, Mi\u0119dzylesie, 14-100, Poland\"",
//         "longitude": -76.5372625,
//         "latitude": 38.2818635
//     },
//     {
//         "id": 239,
//         "name": "ZAK\u0141AD OPTYCZNY PIOTR SZAJRYCH",
//         "address": "\"UL. POZNA\u0143SKA 11, \u0141\u0118CZYCA, 99-100, Poland\"",
//         "longitude": -76.8028933,
//         "latitude": 38.6740691
//     },
//     {
//         "id": 240,
//         "name": "KJMH SP\u00d3\u0141KA Z OGRANICZON\u0104",
//         "address": "\"AL. J\u00d3ZEFA PI\u0141SUDSKIEGO 104B LOK.8, NOWY S\u0104CZ, 33-300, Poland\"",
//         "longitude": 69.2377724,
//         "latitude": 41.3583036
//     },
//     {
//         "id": 241,
//         "name": "\"SALUS - US\u0141UGI\" SP. Z O.O.",
//         "address": "\"Ul. Zawiszy Czarnego 2, K\u0141ODZKO, 57-300, Poland\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 242,
//         "name": "OPTYKA OKULAROWA - EWELINA",
//         "address": "\"OS. ROBOTNICZE 38\/1B, JELENIA G\u00d3RA, 58-506, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 243,
//         "name": "SALON OPTYCZNY OKO ARTUR PRZ\u0104DKA",
//         "address": "\"UL. WYSZY\u0143SKIEGO 1, ZAMO\u015a\u0106, 22-400, Poland\"",
//         "longitude": 19.145136,
//         "latitude": 51.919438
//     },
//     {
//         "id": 244,
//         "name": "PRAKTYKA LEKARSKA ANDRZEJ NIEMCZUK",
//         "address": "\"ul. Wniebowst\u0105pienia 13\/L2, Wejherowo, 84-200, Poland\"",
//         "longitude": 14.4709678,
//         "latitude": 49.7660392
//     },
//     {
//         "id": 245,
//         "name": "ZAK\u0141AD OPTYCZNY MARTA POST- RYBAK",
//         "address": "\"UL. \u0141ASKA 38A, PABIANICE, 95-200, Poland\"",
//         "longitude": 30.817147,
//         "latitude": -29.851338
//     },
//     {
//         "id": 246,
//         "name": "ZAK\u0141AD OPTYCZNY W\u0141ODZIMIERZ D\u0118BICKI",
//         "address": "\"UL. OPATOWSKA 13, SANDOMIERZ, 27-600, Poland\"",
//         "longitude": -77.0401649,
//         "latitude": 38.8976744
//     },
//     {
//         "id": 247,
//         "name": "Okularowo Centrum Optyczne",
//         "address": "\"Ul. \u015aw. Antoniego 4\/2, Tomasz\u00f3w Mazowiecki, 97-200, Poland\"",
//         "longitude": 21.0768288,
//         "latitude": 52.5373228
//     },
//     {
//         "id": 248,
//         "name": "Vision Optyk Monika Rola",
//         "address": "\"ul. p\u0142k. W\u0142adys\u0142awa Beliny-Pra\u017cmows, Nowy S\u0105cz, 33-300, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 249,
//         "name": "ZAK\u0141AD OPTYCZNY PIOTR HARATYM",
//         "address": "\"UL. POCZTOWA 28A lok. 39, CHE\u0141M, 22-100, Poland\"",
//         "longitude": -77.3381855,
//         "latitude": 38.9552797
//     },
//     {
//         "id": 250,
//         "name": "SALON OPTYCZNY \"INTER-OPTICA II\"",
//         "address": "\"UL. KO\u015aCIUSZKI 6, GRYB\u00d3W, 33-330, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 251,
//         "name": "LYNX CH PORT RUMIA AUCHAN",
//         "address": "\"UL. GRUNWALDZKA 108, RUMIA, 84-230, Poland\"",
//         "longitude": 22.5753078,
//         "latitude": 51.2471961
//     },
//     {
//         "id": 252,
//         "name": "RED OPTIK IWONA W\u0141OCH",
//         "address": "\"ul. Zdrojowa 8, \u015awierad\u00f3w-Zdr\u00f3j, 59-850, Poland\"",
//         "longitude": 16.3231119,
//         "latitude": 52.2224818
//     },
//     {
//         "id": 253,
//         "name": "ZAK\u0141AD OPTYCZNY ANNA GROEHLICH",
//         "address": "\"UL. KO\u015aCIELNA 28B, SIEMIANOWICE \u015aL\u0104SKIE, 41-103, Poland\"",
//         "longitude": 80.3306379,
//         "latitude": 26.4741459
//     },
//     {
//         "id": 254,
//         "name": "SALON OPTYCZNY SFERIS-OPTYK S.C.",
//         "address": "\"UL. PI\u0141SUDSKIEGO 26\/2, KWIDZYN, 82-500, Poland\"",
//         "longitude": -97.5975927,
//         "latitude": 40.9408013
//     },
//     {
//         "id": 255,
//         "name": "GOMEZ SP\u00d3\u0141KA AKCYJNA",
//         "address": "\"UL. TOMASZA DROBNIKA 2, POZNA\u0143, 60-693, Poland\"",
//         "longitude": -76.9239727,
//         "latitude": 38.8370963
//     },
//     {
//         "id": 256,
//         "name": " P.M.WYDRYCH SP. Z O.O.",
//         "address": "\"ul. Bodzenty\u0144ska 24 lok. 26, KIELCE, 25-308, Poland\"",
//         "longitude": -76.9985069,
//         "latitude": 38.8741167
//     },
//     {
//         "id": 257,
//         "name": "PRZEDSI\u0118BIORSTWO \"CISZEWSKI\" S.C",
//         "address": "\"UL. STAROMIEJSKA 41, L\u0118BORK, 84-300, Poland\"",
//         "longitude": -77.4744421,
//         "latitude": 39.0418129
//     },
//     {
//         "id": 258,
//         "name": "BEATA BRZEGOWY-N\u0118DZA FIRMA HANDLOWO",
//         "address": "\"UL. BIE\u017bANOWSKA 23, KRAK\u00d3W, 30-812, Poland\"",
//         "longitude": 13.4170302,
//         "latitude": 49.6963491
//     },
//     {
//         "id": 259,
//         "name": "OPTYK OKULARNIK KAROLINA",
//         "address": "\"UL. A. GRZYMA\u0141Y-SIEDLECKIEGO 26, BYDGOSZCZ, 85-868, Poland\"",
//         "longitude": 22.4101581,
//         "latitude": 52.0199321
//     },
//     {
//         "id": 260,
//         "name": "DOBRY OPTYK MONIKA KRAWCZYK",
//         "address": "\"UL. KOLEJOWA 17, \u015aWIEBODZIN, 66-200, Poland\"",
//         "longitude": 18.913334,
//         "latitude": 50.343834
//     },
//     {
//         "id": 261,
//         "name": "US\u0141UGOWY ZAK\u0141AD OPTYCZNY",
//         "address": "\"UL.MARSZA\u0141KOWSKA 55\/73, WARSZAWA, 00-676, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 262,
//         "name": "BENIAMIN SZEWCZYK HURTOWNIA",
//         "address": "\"UL. POR. BOLES\u0141AWA SZPUNARA 14, WIELICZKA, 32-020, Poland\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 263,
//         "name": "PRACOWNIA OPTYCZNA MACIEJ LISZKA",
//         "address": "\"UL. WALO\u0143SKA 19\/U2, WROC\u0141AW, 50-413, Poland\"",
//         "longitude": -8.8864308,
//         "latitude": 39.1137424
//     },
//     {
//         "id": 264,
//         "name": "OPTYKA VICTORIA S.C.",
//         "address": "\"UL. PI\u0141SUDSKIEGO 12, ZAWIERCIE, 42-400, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 265,
//         "name": "CENTRUM OPTYCZNE AGNIESZKA",
//         "address": "\"UL. BARWNA 22 LOK.1, MIERZYN, 72-006, Poland\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 266,
//         "name": "EYEHOLIC SP. Z.O.O.",
//         "address": "\"UL. CZEKOLADOWA 7-9, BIELANY WROC\u0141AWSKIE, 55-040, Poland\"",
//         "longitude": -76.99,
//         "latitude": 38.92
//     },
//     {
//         "id": 267,
//         "name": "CENTRUM OPTYCZNO OKULISTYCZNE",
//         "address": "\"UL. \u015aWI\u0118TOJA\u0143SKA 73, GDYNIA, 81-389, Poland\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 268,
//         "name": "OPTYK na Letniej",
//         "address": "\"UL. LETNIA 20 LOK.5, K\u0141ODZKO, 57-300, Poland\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 269,
//         "name": "O\u015aRODEK KOREKCJI WZROKU SP. Z O.O.",
//         "address": "\"UL. PR\u00d3CHNIKA 1, \u0141\u00d3D\u0179, 90-408, Poland\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 270,
//         "name": "OPTYK W RYNKU BARTOSZ BYTNER",
//         "address": "\"UL. RYNEK 7, G\u0141OG\u00d3WEK, 48-250, Poland\"",
//         "longitude": -77.638999,
//         "latitude": 39.2753346
//     },
//     {
//         "id": 271,
//         "name": "OPTICOS S.C.",
//         "address": "\"UL. M\u0141Y\u0143SKA 9, CHOJNICE, 89-600, Poland\"",
//         "longitude": -77.5340315,
//         "latitude": 38.9867355
//     },
//     {
//         "id": 272,
//         "name": "RADOS\u0141AW PRZYBYLSKI \"OPTYK\"",
//         "address": "\"UL. CIESZKOWSKIEGO 38, SWARZ\u0118DZ, 62-020, Poland\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 273,
//         "name": "OCULAR CANADA 2 SP. Z O.O.",
//         "address": "\"UL. PI\u0141SUDSKIEGO 44 \/ GAL. RZESZ\u00d3W, RZESZ\u00d3W, 35-001, Poland\"",
//         "longitude": -76.9800935,
//         "latitude": 38.9898804
//     },
//     {
//         "id": 274,
//         "name": "MARIUSZ GRONOWSKI OPTYK",
//         "address": "\"OS. ZGODY 7, KRAK\u00d3W, 31-949, Poland\"",
//         "longitude": 30.8147473,
//         "latitude": -29.8486293
//     },
//     {
//         "id": 275,
//         "name": "VISIONZ SP. Z O.O.",
//         "address": "\"Ul. Powsta\u0144c\u00f3w 22, Z\u0105bki, 05-091, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 276,
//         "name": "MA\u0141GORZATA ANZEL PRAKTYKA LEKARSKA",
//         "address": "\"UL. ZIELONA 34, NOWY S\u0104CZ, 33-300, Poland\"",
//         "longitude": 14.5829803,
//         "latitude": 50.0036532
//     },
//     {
//         "id": 277,
//         "name": "Jerzy Flak Zak\u0142ad Optyczny",
//         "address": "\"ul. 3 Maja 90, Proszowice, 32-100, Poland\"",
//         "longitude": -76.9758274,
//         "latitude": 39.1762439
//     },
//     {
//         "id": 278,
//         "name": "ANSWEAR.COM SP\u00d3\u0141KA AKCYJNA",
//         "address": "\"ALEJA POKOJU 18, KRAK\u00d3W, 31-564, Poland\"",
//         "longitude": 21.5687308,
//         "latitude": 48.3196929
//     },
//     {
//         "id": 279,
//         "name": "OPTALUX HONORATA CZEKA\u0141A",
//         "address": "\"UL. WOJCIECHA KOSSAKA 8\/2, PRUSZCZ GDA\u0143SKI, 83-000, Poland\"",
//         "longitude": 18.6466384,
//         "latitude": 54.3520252
//     },
//     {
//         "id": 280,
//         "name": "CH AVENIDA (FIRMA OPTYCZNA WIE\u0143CZYS",
//         "address": "\"ul.Matyi 2, Pozna\u0144, 61-586, Poland\"",
//         "longitude": 46.7022149,
//         "latitude": 24.6764021
//     },
//     {
//         "id": 281,
//         "name": "OPTYK POLAR SP\u00d3\u0141KA JAWNA",
//         "address": "\"UL. D\u0141UGA 75A, BI\u0141GORAJ, 23-400, Poland\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 282,
//         "name": "ZAK\u0141AD OPTYCZNY MILENA LEWALSKA",
//         "address": "\"UL. \u017bEROMSKIEGO 37C, SZCZYTNO, 12-100, Poland\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 283,
//         "name": "MAD MAX - MAKSYMILIAN CHIREK",
//         "address": "\"UL. CHYLO\u0143SKA 27, GDYNIA, 81-064, Poland\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 284,
//         "name": "OPTYK BRYLE MA\u0141GORZATA JATCZAK",
//         "address": "\"UL. BRZEZI\u0143SKA 16, KOLUSZKI, 95-040, Poland\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 285,
//         "name": "OPTYK LUCYNA TONDRYK",
//         "address": "\"UL. SIENKIEWICZA 2, PIASECZNO, 05-500, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 286,
//         "name": "WALDEMAR GOR\u0104CZKO CENTRUM OPTYKI",
//         "address": "\"UL. 3-GO MAJA 9, SANOK, 38-500, Poland\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 287,
//         "name": "OPTYK CENTER SALON OPTYCZNO",
//         "address": "\"UL. ODRODZENIA 35\/17, SZCZYTNO, 12-100, Poland\"",
//         "longitude": 30.8137581,
//         "latitude": -29.8536809
//     },
//     {
//         "id": 288,
//         "name": "FIRMA HANDLOWA \"K.E.J.\" K. KULIK",
//         "address": "\"UL. MAZURSKA 39, L\u0118BORK, 84-300, Poland\"",
//         "longitude": 20.702786,
//         "latitude": 53.8671117
//     },
//     {
//         "id": 289,
//         "name": "JOLANTA SIEMBAB OPTYK OKULISTA",
//         "address": "\"UL. ZAWIERCIA\u0143SKA 4\/2, KLUCZE, 32-310, Poland\"",
//         "longitude": -77.43,
//         "latitude": 39.01
//     },
//     {
//         "id": 290,
//         "name": "WOJCIECH KASPRZAK OPTYKA I",
//         "address": "\"UL. RYNEK 6, OSTR\u00d3W WIELKOPOLSKI, 63-400, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 291,
//         "name": "OPTYKA OKULAROWA M.GUTOWSKA,",
//         "address": "\"UL. BATALION\u00d3W CH\u0141OPSKICH 74 LOK. 4, WARSZAWA, 01-308, Poland\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 292,
//         "name": "ZAK\u0141AD OPTYCZNY ROBERT POLAK",
//         "address": "\"UL. WYCZ\u00d3\u0141KOWSKIEGO 8, RYKI, 08-500, Poland\"",
//         "longitude": 103.85765,
//         "latitude": 1.309114
//     },
//     {
//         "id": 293,
//         "name": "OPTYK Z PASJ\u0104 by Magdalena",
//         "address": "\"ul. J\u00f3zefa Pi\u0142sudskiego 18, lok. 7,, Siedlce, 08-110, Poland\"",
//         "longitude": -77.4587239,
//         "latitude": 38.8193117
//     },
//     {
//         "id": 294,
//         "name": "MONIKA DOMINIAK SALON OPTYCZNO-",
//         "address": "\"UL. KURCZAKI 24B LOK.21, \u0141\u00d3D\u0179, 93-322, Poland\"",
//         "longitude": 8.6249564,
//         "latitude": 50.2191832
//     },
//     {
//         "id": 295,
//         "name": "LYNX WESTFILED ARKADIA",
//         "address": "\"AL. JANA PAW\u0141A II 82, WARSZAWA, 00-175, Poland\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 296,
//         "name": "Optika Fr\u00f6hlich - RCS",
//         "address": "\"NC Bory Mall Lama\u010d 6780, Bratislava, 841 03, Slovak Republic\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 297,
//         "name": "Optic s.r.o. - OPTIKA CRYSTAL",
//         "address": "\"Nitrianska cesta \u010d.21, Nov\u00e9 Z\u00e1mky, 940 02, Slovak Republic\"",
//         "longitude": 20.1502916,
//         "latitude": 46.2845795
//     },
//     {
//         "id": 298,
//         "name": "ZOOM S.R.O.",
//         "address": "\"PODZ\u00c1MSKA 41, HLOHOVEC, 920 01, Slovak Republic\"",
//         "longitude": -76.9239727,
//         "latitude": 38.8370963
//     },
//     {
//         "id": 299,
//         "name": "M\u00c1RIA KUBA\u010cKOV\u00c1 OPTIKA MK",
//         "address": "\"S. CHALUPKU 4449\/3, TOPO\u013d\u010cANY, 955 01, Slovak Republic\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 300,
//         "name": "JOZEF ZELIESKA O\u010cN\u00c1 OPTIKA",
//         "address": "\"\u0160t\u00farova 296, Ilava, 019 01, Slovak Republic\"",
//         "longitude": -76.947022,
//         "latitude": 38.8910932
//     },
//     {
//         "id": 301,
//         "name": "MAURO SIMON, SPOL. S R.O.",
//         "address": "\"HRN\u010cIARSKA 34, PEZINOK, 902 01, Slovak Republic\"",
//         "longitude": -77.0391754,
//         "latitude": 39.0451663
//     },
//     {
//         "id": 302,
//         "name": "URUK S.R.O.",
//         "address": "\"KUTUZOVOVA 6475\/25, PRE\u0160OV, 080 05, Slovak Republic\"",
//         "longitude": 103.852861,
//         "latitude": 1.307018
//     },
//     {
//         "id": 303,
//         "name": "OPTIKA KLAUDIA, S.R.O.",
//         "address": "\"Hlavn\u00e1 ulica 918\/2, Galanta, 924 01, Slovak Republic\"",
//         "longitude": -77.015095,
//         "latitude": 39.0216936
//     },
//     {
//         "id": 304,
//         "name": "MANIA OPTIKA-AVION BA",
//         "address": "\"IV\u00c1NSKA CESTA 16 - AVION, BRATISLAVA, 821 04, Slovak Republic\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 305,
//         "name": "MANIA OPTIKA-AUPARK KE",
//         "address": "\"N\u00c1M.OSLOBODITE\u013dOV 3377\/1-AUPARK, KO\u0160ICE, 040 01, Slovak Republic\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 306,
//         "name": "OM-OPTIK",
//         "address": "\"Kupeck\u00e9ho 2, Pezinok, 902 01, Slovak Republic\"",
//         "longitude": -77.0391754,
//         "latitude": 39.0451663
//     },
//     {
//         "id": 307,
//         "name": "OPTICK\u00c9 CENTRUM EVERGREEN,",
//         "address": "\"SASINKOVA 1\/A, MALACKY, 901 01, Slovak Republic\"",
//         "longitude": 22.5941816,
//         "latitude": 51.2621626
//     },
//     {
//         "id": 308,
//         "name": "OPTIKA CENTRUM S.R.O.",
//         "address": "\"\u0160T\u00daROVA 2, KO\u0160ICE, 040 11, Slovak Republic\"",
//         "longitude": 100.6983518,
//         "latitude": 13.7474361
//     },
//     {
//         "id": 309,
//         "name": "ING. ZDENKO GERBOC",
//         "address": "\"\u0160TUDENTSK\u00c1 2997\/44, SNINA, 069 01, Slovak Republic\"",
//         "longitude": 22.1583022,
//         "latitude": 48.987657
//     },
//     {
//         "id": 310,
//         "name": "O\u010dn\u00e1 optika MANIA Original Nivy",
//         "address": "\"Mlynsk\u00e9 Nivy 16, Bratislava, 821 09, Slovak Republic\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 311,
//         "name": "CSABA OROS - OROS OPTIK",
//         "address": "\"HLAVN\u00c1 49, DOLN\u00dd \u0160T\u00c1L, 930 10, Slovak Republic\"",
//         "longitude": -77.0277275,
//         "latitude": 38.9003884
//     },
//     {
//         "id": 312,
//         "name": "Neov\u00edzia s.r.o.",
//         "address": "\"R\u00f6ntgenova 26, Bratislava - Petr\u017ealka, 851 01, Slovak Republic\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 313,
//         "name": "JOZEF ZELIESKA O\u010cN\u00c1 OPTIKA",
//         "address": "\"1. M\u00c1JA 870, P\u00daCHOV, 020 01, Slovak Republic\"",
//         "longitude": -76.9815877,
//         "latitude": 38.8641775
//     },
//     {
//         "id": 314,
//         "name": "Peter Vokel - OPTIKA VOKEL",
//         "address": "\"K. Salvu 1, Ru\u017eomberok, 034 01, Slovak Republic\"",
//         "longitude": 16.8993461,
//         "latitude": 52.4049097
//     },
//     {
//         "id": 315,
//         "name": "KOLCUN OPTIK, S.R.O.",
//         "address": "\"Hviezdoslavova 3808\/14, Bardejov, 085 01, Slovak Republic\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 316,
//         "name": "OPTIK MORVAY, S.R.O.",
//         "address": "\"Lichnerova 16, Senec, 903 01, Slovak Republic\"",
//         "longitude": 17.4000185,
//         "latitude": 48.2193052
//     },
//     {
//         "id": 317,
//         "name": "Progres o\u010dn\u00e1 optika, s. r. o.",
//         "address": "\"Koll\u00e1rova 4283\/32, Martin, 036 01, Slovak Republic\"",
//         "longitude": -77.0406147,
//         "latitude": 38.9080786
//     },
//     {
//         "id": 318,
//         "name": "MARKUS OPTIK s.r.o.",
//         "address": "\"N\u00e1m. A.H. \u0160kult\u00e9tyho 734\/6, Ve\u013ek\u00fd Krt\u00ed\u0161, 990 01, Slovak Republic\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 319,
//         "name": "Bal\u00e1zs optix s. r. o.",
//         "address": "\"Mierov\u00e1 28, Kol\u00e1rovo, 946 03, Slovak Republic\"",
//         "longitude": -76.8503754,
//         "latitude": 38.8813417
//     },
//     {
//         "id": 320,
//         "name": "MANIA OPTIKA-AUPARK ZA",
//         "address": "\"VE\u013dK\u00c1 OKRU\u017dN\u00c1 2733\/59 - AUPARK, \u017dILINA, 010 01, Slovak Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.7579835
//     },
//     {
//         "id": 321,
//         "name": "ADAM OPTIK - o\u010dn\u00e1 optika",
//         "address": "\"M. Martin\u010deka 4701\/2, Liptovsk\u00fd Mikul\u00e1\u0161, 031 01, Slovak Republic\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 322,
//         "name": "MANIA OPTIKA-GAL\u00c9RIA MLYNY NR",
//         "address": "\"\u0160TEF\u00c1NIKOVA 61 - OC MLYNY, NITRA, 949 01, Slovak Republic\"",
//         "longitude": 19.145136,
//         "latitude": 51.919438
//     },
//     {
//         "id": 323,
//         "name": "Optique, s.r.o.- O\u010cN\u00c1 OPTIKA",
//         "address": "\"N\u00e1m. Sv. Michala 16 (Dom slu\u017eieb), Hlohovec, 920 01, Slovak Republic\"",
//         "longitude": -76.9354979,
//         "latitude": 38.8178384
//     },
//     {
//         "id": 324,
//         "name": "Optify Optika",
//         "address": "\"A.Hlinku 58, Prievidza, 971 01, Slovak Republic\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 325,
//         "name": "OPTIKA ERIMA S.R.O.",
//         "address": "\"P.O.HVIEZDOSLAVA 34, ZVOLEN, 960 01, Slovak Republic\"",
//         "longitude": 14.6032241,
//         "latitude": 50.0121978
//     },
//     {
//         "id": 326,
//         "name": "OPTAL, s.r.o.",
//         "address": "\"Ul.Levick\u00e1 1288\/16, Vr\u00e1ble, 952 01, Slovak Republic\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 327,
//         "name": "OPTIKA KL\u00c1TIK (Michal Kl\u00e1tik)",
//         "address": "\"Mozartova 1, Trnava, 917 08, Slovak Republic\"",
//         "longitude": 17.0355288,
//         "latitude": 51.0559344
//     },
//     {
//         "id": 328,
//         "name": "Visual, s. r .o.",
//         "address": "\"Dlh\u00fd rad 30, Bardejov, 085 01, Slovak Republic\"",
//         "longitude": 50.717278,
//         "latitude": 35.821313
//     },
//     {
//         "id": 329,
//         "name": "OPTIKA OLYMPIA S.R.O.",
//         "address": "\"PRIBINOVA 31, MALACKY, 901 01, Slovak Republic\"",
//         "longitude": 17.0170015,
//         "latitude": 48.4410099
//     },
//     {
//         "id": 330,
//         "name": "ZSOLT OROS \u2013 OPTIKA OROS s.r.o.",
//         "address": "\"Bratislavsk\u00e1 100\/D, \u0160amor\u00edn, 931 01, Slovak Republic\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 331,
//         "name": "Optika Lux s.r.o.",
//         "address": "\"\u0160tef\u00e1nikova trieda 6, Nitra, 949 01, Slovak Republic\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 332,
//         "name": "LEXUM Slovakia s.r.o.",
//         "address": "\"Jilemnick\u00e9ho 532\/2, Tren\u010d\u00edn, 911 01, Slovak Republic\"",
//         "longitude": -76.5314817,
//         "latitude": 38.802157
//     },
//     {
//         "id": 333,
//         "name": "OPTIKA KOBRA S.R.O.",
//         "address": "\"N\u00c1MESTIE REPUBLIKY 10, LU\u010cENEC, 984 01, Slovak Republic\"",
//         "longitude": 98.8583309,
//         "latitude": 3.2792483
//     },
//     {
//         "id": 334,
//         "name": "ZRAKOV\u00c9 CENTRUM - VISION CARE",
//         "address": "\"Star\u00e9 Grunty 24, Bratislava - Karlova Ves, 841 04, Slovak Republic\"",
//         "longitude": 17.0518443,
//         "latitude": 48.1591643
//     },
//     {
//         "id": 335,
//         "name": "NEREA, S.R.O.",
//         "address": "\"AG\u00c1TOV\u00c1 1929\/6, SPI\u0160SK\u00c1 NOV\u00c1 VES, 052 01, Slovak Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 336,
//         "name": "GOOD OPTIK S.R.O.",
//         "address": "\"HLAVN\u00c1 69, PRE\u0160OV, 080 01, Slovak Republic\"",
//         "longitude": 19.4582535,
//         "latitude": 51.7605683
//     },
//     {
//         "id": 337,
//         "name": "O\u010dn\u00e1 optika V.S.O.P. s.r.o.",
//         "address": "\"N\u00e1mestie Matice Slovenskej 404\/0, \u017diar nad Hronom, 965 01, Slovak Republic\"",
//         "longitude": 14.995463,
//         "latitude": 46.151241
//     },
//     {
//         "id": 338,
//         "name": "OPTIK MORVAY, S.R.O.",
//         "address": "\"RADLINSK\u00c9HO 1, TRNAVA, 917 01, Slovak Republic\"",
//         "longitude": 30.8173157,
//         "latitude": -29.8470354
//     },
//     {
//         "id": 339,
//         "name": "MANIA OPTIKA-BORY MALL BA",
//         "address": "\"LAMA\u010c 6780 - BORY, BRATISLAVA, 840 02, Slovak Republic\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 340,
//         "name": "SO STONE OPTIK",
//         "address": "\"Osusk\u00e9ho 3784\/3B, Bratislava-Petr\u017ealka, 851 03, Slovak Republic\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 341,
//         "name": "Lajos Mikl\u00f3s-o\u010d. optik optometrista",
//         "address": "\"Hlavn\u00e1 1, Galanta, 924 01, Slovak Republic\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 342,
//         "name": "ING.MARTIN \u0160UPICA - OPTIKA PRIMA",
//         "address": "\"ZLIEVARENSK\u00c1 24, KOM\u00c1RNO, 945 01, Slovak Republic\"",
//         "longitude": -76.5083562,
//         "latitude": 38.8302686
//     },
//     {
//         "id": 343,
//         "name": "RS OPTIMA S.R.O. (o\u010dn\u00e1 optika)",
//         "address": "\"BUDOVATE\u013dSK\u00c1 546\/24, \u0160A\u013dA, 927 01, Slovak Republic\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 344,
//         "name": "INGRID \u017d\u00c1CKA",
//         "address": "\"TREN\u010cIANSKE BOHUSLAVICE 215, TREN\u010cIANSKE BOHUSLAVICE, 913 07, Slovak Republic\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 345,
//         "name": "OPTIKCENTRUM, S.R.O.",
//         "address": "\"\u0160tef\u00e1nikova 49, NITRA, 949 01, Slovak Republic\"",
//         "longitude": -77.79,
//         "latitude": 38.71
//     },
//     {
//         "id": 346,
//         "name": "MANIA OPTIKA-CITY ARENA TT",
//         "address": "\"KOLL\u00c1ROVA 20 - CITY ARENA, TRNAVA, 917 01, Slovak Republic\"",
//         "longitude": 103.861385,
//         "latitude": 1.311704
//     },
//     {
//         "id": 347,
//         "name": "MANIA ORIGINAL OPTIKA - NZ TESCO",
//         "address": "\"NITRIANSKA CESTA 111 - HM TESCO, NOV\u00c9 Z\u00c1MKY, 940 67, Slovak Republic\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 348,
//         "name": "OPTIK MORVAY, S.R.O.",
//         "address": "\"Po\u0161tov\u00e1 13, Sere\u010f, 926 01, Slovak Republic\"",
//         "longitude": 20.1502916,
//         "latitude": 46.2845795
//     },
//     {
//         "id": 349,
//         "name": "OPTIK TEO",
//         "address": "\"Mierov\u00e9 n\u00e1m. 29, Tren\u010d\u00edn, 911 01, Slovak Republic\"",
//         "longitude": -76.3405733,
//         "latitude": 37.9994332
//     },
//     {
//         "id": 350,
//         "name": "OFTEVA, S.R.O.",
//         "address": "\"Ivana Krasku 2483\/2, Sere\u010f, 926 01, Slovak Republic\"",
//         "longitude": -77.939597,
//         "latitude": 38.932314
//     },
//     {
//         "id": 351,
//         "name": "MANIA OPTIKA-TESCO BB",
//         "address": "\"ZVOLENSK\u00c1 CESTA 23 - TESCO, BANSK\u00c1 BYSTRICA, 974 05, Slovak Republic\"",
//         "longitude": 18.913334,
//         "latitude": 50.343834
//     },
//     {
//         "id": 352,
//         "name": "MANIA OPTIKA-TESCO\/GAL\u00c9RIA NR",
//         "address": "\"BRATISLAVSK\u00c1 5-OD TESCO\/GAL\u00c9RIA, NITRA, 949 01, Slovak Republic\"",
//         "longitude": 72.5612301,
//         "latitude": 23.0365716
//     },
//     {
//         "id": 353,
//         "name": "PF optik",
//         "address": "\"Partiz\u00e1nska 291\/17A, Myjava, 907 01, Slovak Republic\"",
//         "longitude": -76.7105632,
//         "latitude": 38.4157515
//     },
//     {
//         "id": 354,
//         "name": "MANIA OPTIKA-OC MAX PP",
//         "address": "\"DLH\u00c9 HONY 1 - OC MAX, POPRAD, 058 01, Slovak Republic\"",
//         "longitude": -76.99,
//         "latitude": 38.92
//     },
//     {
//         "id": 355,
//         "name": "MANIA OPTIKA-AUPARK BA",
//         "address": "\"EINSTEINOVA 3541\/18-AUPARK, BRATISLAVA-PETR\u017dALKA, 851 01, Slovak Republic\"",
//         "longitude": 17.1080538,
//         "latitude": 48.132927
//     },
//     {
//         "id": 356,
//         "name": "MANIA OPTIKA-ATRIUM DUBE\u0147 ZA",
//         "address": "\"UL.VYSOKO\u0160KOL\u00c1KOV 52-ATRIUM DUBE\u0147, \u017dILINA, 010 08, Slovak Republic\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 357,
//         "name": "G&D Optics s. r. o.",
//         "address": "\"Textiln\u00e1 ul.   10\/0, Levice, 934 01, Slovak Republic\"",
//         "longitude": 19.0701771,
//         "latitude": 47.6751122
//     },
//     {
//         "id": 358,
//         "name": "Optik JaS s.r.o.",
//         "address": "\"K\u00e1lov 5, \u017dilina, 010 01, Slovak Republic\"",
//         "longitude": -76.8317325,
//         "latitude": 38.7443023
//     },
//     {
//         "id": 359,
//         "name": "R\u00d3BERT VALENT OPTIKA A.D.",
//         "address": "\"\u0160TIAVNICK\u00c1 20, NITRA, 949 01, Slovak Republic\"",
//         "longitude": 19.4075666,
//         "latitude": 51.6473799
//     },
//     {
//         "id": 360,
//         "name": "Optika LENS s.r.o.",
//         "address": "\"Z\u00e1hradn\u00edcka 4\/975, \u0160t\u00farovo, 943 01, Slovak Republic\"",
//         "longitude": 38.2216614,
//         "latitude": 56.5932699
//     },
//     {
//         "id": 361,
//         "name": "MANIA OPTIKA-OC MAX TN",
//         "address": "\"M.R. \u0160TEF\u00c1NIKA 426 - OC MAX, TREN\u010c\u00cdN, 911 01, Slovak Republic\"",
//         "longitude": 19.145136,
//         "latitude": 51.919438
//     },
//     {
//         "id": 362,
//         "name": "ZAMAK s. r. o",
//         "address": "\"Mikov\u00edniho 406\/14, Nitra, 949 11, Slovak Republic\"",
//         "longitude": -76.5372625,
//         "latitude": 38.2818635
//     },
//     {
//         "id": 363,
//         "name": "Neov\u00edzia s.r.o.",
//         "address": "\"Dlh\u00e9 Hony 9, Poprad, 058 01, Slovak Republic\"",
//         "longitude": -76.99,
//         "latitude": 38.92
//     },
//     {
//         "id": 364,
//         "name": "REN\u00c1TA FRAJTKOV\u00c1 - EUROOPTIKA",
//         "address": "\"OSLOBODITE\u013dOV 59, MICHALOVCE, 071 01, Slovak Republic\"",
//         "longitude": -76.8028933,
//         "latitude": 38.6740691
//     },
//     {
//         "id": 365,
//         "name": "Zolt\u00e1n Oros OPTIKA",
//         "address": "\"Hlavn\u00e9 n\u00e1mestie 20\/20, \u0160AHY, 936 01, Slovak Republic\"",
//         "longitude": 69.2377724,
//         "latitude": 41.3583036
//     },
//     {
//         "id": 366,
//         "name": "OPTICAL S. R. O.",
//         "address": "\"Doln\u00fd \u0160ianec 1, TREN\u010c\u00cdN, 911 01, Slovak Republic\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 367,
//         "name": "MANIA ORIGINAL OPTIKA-OC VIVO BA",
//         "address": "\"VAJNORSK\u00c1 100 - VIVO!, BRATISLAVA, 831 04, Slovak Republic\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 368,
//         "name": "PRESSBURG OPTIK, S. R. O.",
//         "address": "\"HERLIANSKA 45, BRATISLAVA - RU\u017dINOV, 821 03, Slovak Republic\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 369,
//         "name": "OPTIKA \u017d\u00c1CKA S.R.O.",
//         "address": "\"MALINOVSK\u00c9HO   1238\/22, NOV\u00c9 MESTO NAD V\u00c1HOM, 915 01, Slovak Republic\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 370,
//         "name": "Eva T\u00f3thov\u00e1 - OPTIKA LUNETA",
//         "address": "\"R\u00e1bska 19, Kol\u00e1rovo, 946 03, Slovak Republic\"",
//         "longitude": 19.145136,
//         "latitude": 51.919438
//     },
//     {
//         "id": 371,
//         "name": "J\u00e1n Oros - STYLE OPTIK",
//         "address": "\"Po\u0161tov\u00e1 10, Torna\u013ea, 982 01, Slovak Republic\"",
//         "longitude": 14.4709678,
//         "latitude": 49.7660392
//     },
//     {
//         "id": 372,
//         "name": "MUDR.V.A. POPOV\u00c1, RAINBOW OPTIC",
//         "address": "\"HODSK\u00c1 3170\/111, GALANTA, 924 01, Slovak Republic\"",
//         "longitude": 30.817147,
//         "latitude": -29.851338
//     },
//     {
//         "id": 373,
//         "name": "IVIOPTIK S.R.O.",
//         "address": "\"CYRILA A METODA 34, MICHALOVCE, 071 01, Slovak Republic\"",
//         "longitude": -77.0401649,
//         "latitude": 38.8976744
//     },
//     {
//         "id": 374,
//         "name": "MANIA OPTIKA-OC FORUM PP",
//         "address": "\"N\u00c1M.SV.EG\u00cdDIA 3290\/124-OC FORUM, POPRAD, 058 01, Slovak Republic\"",
//         "longitude": 14.3590366,
//         "latitude": 62.0346245
//     },
//     {
//         "id": 375,
//         "name": "MANIA ORIGINAL OPTIKA - TESCO DS",
//         "address": "\"Hlavn\u00e1 75, Dunajsk\u00e1 Streda, 929 01, Slovak Republic\"",
//         "longitude": 15.6763285,
//         "latitude": 44.3193677
//     },
//     {
//         "id": 376,
//         "name": "ROMAN ZACHAR - OPTIKA POHODA",
//         "address": "\"\u0160T\u00daROVA 5, TOPO\u013d\u010cANY, 955 01, Slovak Republic\"",
//         "longitude": 21.0768288,
//         "latitude": 52.5373228
//     },
//     {
//         "id": 377,
//         "name": "pan-optik s.r.o. - O\u010dn\u00e1 Optika",
//         "address": "\"N\u00e1mestie SR \u010d.8, Byt\u010da, 014 01, Slovak Republic\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 378,
//         "name": "Optika Klob\u00fak",
//         "address": "\"Roko\u0161sk\u00e1 7, B\u00e1novce nad Bebravou, 957 01, Slovak Republic\"",
//         "longitude": -77.3381855,
//         "latitude": 38.9552797
//     },
//     {
//         "id": 379,
//         "name": "Optika EYEVIL (VILEYE s.r.o.)",
//         "address": "\"Trieda prof. Hlava\u010da 15-17, Michalovce, 071 01, Slovak Republic\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 380,
//         "name": "MANIA ORIGINAL OPTIKA-LV TESCO",
//         "address": "\"TURECK\u00dd RAD 5172\/7 - HM TESCO, LEVICE, 934 01, Slovak Republic\"",
//         "longitude": 22.5753078,
//         "latitude": 51.2471961
//     },
//     {
//         "id": 381,
//         "name": "pan-optik s.r.o. - O\u010dn\u00e1 Optika",
//         "address": "\"Ha\u0161kova 10, Nov\u00e9 Mesto nad V\u00e1hom, 915 01, Slovak Republic\"",
//         "longitude": 16.3231119,
//         "latitude": 52.2224818
//     },
//     {
//         "id": 382,
//         "name": "ANGEL OPTIC M & I s.r.o.",
//         "address": "\"Dvory 1933\/17, P\u00fachov, 020 01, Slovak Republic\"",
//         "longitude": -76.9815877,
//         "latitude": 38.8641775
//     },
//     {
//         "id": 383,
//         "name": "Anton Hauptman - D\u00e1lia Optika",
//         "address": "\"Hlavn\u00e1 459\/80, Streda nad Bodrogom, 076 31, Slovak Republic\"",
//         "longitude": 7.9131852,
//         "latitude": 52.2509777
//     },
//     {
//         "id": 384,
//         "name": "M\u00e1ria Varhan\u00edkov\u00e1 - O\u010cN\u00c1 OPTIKA, s.",
//         "address": "\"Bernol\u00e1kova 24, Zlat\u00e9 Moravce, 953 01, Slovak Republic\"",
//         "longitude": 80.3306379,
//         "latitude": 26.4741459
//     },
//     {
//         "id": 385,
//         "name": "O\u010dn\u00e1 optika OPTIQ",
//         "address": "\"N\u00e1m. Sv. Eg\u00eddia 20\/45, Poprad, 058 01, Slovak Republic\"",
//         "longitude": -97.5975927,
//         "latitude": 40.9408013
//     },
//     {
//         "id": 386,
//         "name": "iOptic s.r.o.",
//         "address": "\"B. N\u011bmcovej 28, OC Merk\u00far, Ko\u0161ice, 040 01, Slovak Republic\"",
//         "longitude": -76.9239727,
//         "latitude": 38.8370963
//     },
//     {
//         "id": 387,
//         "name": "KATAR\u00cdNA \u00c1GHOV\u00c1 - OPTIKA-HORIZONT",
//         "address": "\"NEMOCNI\u010cN\u00c1 1, \u0160ALA, 927 01, Slovak Republic\"",
//         "longitude": -76.9985069,
//         "latitude": 38.8741167
//     },
//     {
//         "id": 388,
//         "name": "\u013d. BEDN\u00c1RIKOV\u00c1-O\u010cN\u00c1 OPTIKA STIGMA",
//         "address": "\"N\u00e1m. Sv. Michala 16   DOM SLU\u017dIEB, HLOHOVEC, 920 01, Slovak Republic\"",
//         "longitude": -77.4744421,
//         "latitude": 39.0418129
//     },
//     {
//         "id": 389,
//         "name": "OPTIKA UHL\u00c1R(JUING SK, s.r.o.Poprad",
//         "address": "\"Francisciho 20, Poprad, 058 01, Slovak Republic\"",
//         "longitude": 13.4170302,
//         "latitude": 49.6963491
//     },
//     {
//         "id": 390,
//         "name": "AK OPTIK (AK OPTIK, S.R.O.)",
//         "address": "\"A. HLINKU 27 - POLIKLINIKA, HLOHOVEC, 920 01, Slovak Republic\"",
//         "longitude": 22.4101581,
//         "latitude": 52.0199321
//     },
//     {
//         "id": 391,
//         "name": "CRYSTAL OPTIK, S.R.O.",
//         "address": "\"\u0160PIT\u00c1LSKA 13, NITRA, 949 01, Slovak Republic\"",
//         "longitude": 18.913334,
//         "latitude": 50.343834
//     },
//     {
//         "id": 392,
//         "name": "OPTIKA VISUS S.R.O.",
//         "address": "\"HLAVN\u00c1 53, \u0160T\u00daROVO, 943 01, Slovak Republic\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 393,
//         "name": "SUNSHINE, TRGOVINA D.O.O.",
//         "address": "\"CELAR\u010cEVA ULICA 8, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 394,
//         "name": "O\u010cESNI CENTER PRESKAR NM",
//         "address": "\"KETTEJEV DREVORED 32, NOVO MESTO, 8000, Slovenia Country\"",
//         "longitude": -8.8864308,
//         "latitude": 39.1137424
//     },
//     {
//         "id": 395,
//         "name": "OPTIKA KRSTI\u010c PE LECLERC",
//         "address": "\"JUR\u010cKOVA CESTA 225, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 396,
//         "name": "OPTIKA PAKA D.O.O.",
//         "address": "\"RUDARSKA CESTA 1, VELENJE, 3320, Slovenia Country\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 397,
//         "name": "OPTIKA PLUS INTEROPTI D.O.O.",
//         "address": "\"C. STE MARIE AUX MINES 36, TR\u017dI\u010c, 4290, Slovenia Country\"",
//         "longitude": -76.99,
//         "latitude": 38.92
//     },
//     {
//         "id": 398,
//         "name": "O2 MED OPTIKA MORELA",
//         "address": "\"POBRE\u0160KA CESTA 18, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 399,
//         "name": "P.E. Optika Smole",
//         "address": "\"Pre\u0161ernova ul. 3, Celje, 3000, Slovenia Country\"",
//         "longitude": -77.6020569,
//         "latitude": 38.8090413
//     },
//     {
//         "id": 400,
//         "name": "OPTIKA KOTNIK, STORITVE D.O.O.",
//         "address": "\"RONKOVA ULICA 4, SLOVENJ GRADEC, 2380, Slovenia Country\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 401,
//         "name": "OPTIKA FENIKS, OPT.DEJAVNOST,",
//         "address": "\"ZAGREBA\u0160KA CESTA 034, PTUJ, 2250, Slovenia Country\"",
//         "longitude": -77.638999,
//         "latitude": 39.2753346
//     },
//     {
//         "id": 402,
//         "name": "EXPRESS OPTIKA MIRO KRSTI\u010c S.P.",
//         "address": "\"Tr\u017ea\u0161ka cesta 15, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": -77.5340315,
//         "latitude": 38.9867355
//     },
//     {
//         "id": 403,
//         "name": "OPTIKA BRANKO NOVAK S.P.",
//         "address": "\"PRE\u0160ERNOVA CESTA 5, GORNJA RADGONA, 9250, Slovenia Country\"",
//         "longitude": -77.0909809,
//         "latitude": 38.6551092
//     },
//     {
//         "id": 404,
//         "name": "O\u010cESNA OPTIKA LIKEB",
//         "address": "\"NA TRGU 5, MOZIRJE, 3330, Slovenia Country\"",
//         "longitude": -76.9800935,
//         "latitude": 38.9898804
//     },
//     {
//         "id": 405,
//         "name": "VOGRI\u010c IGOR S.P.",
//         "address": "\"PRELOV\u010cEVA ULICA 1, IDRIJA, 5280, Slovenia Country\"",
//         "longitude": 30.8147473,
//         "latitude": -29.8486293
//     },
//     {
//         "id": 406,
//         "name": "BRUMEN JANJA S.P.",
//         "address": "\"PRE\u017dIHOVA ULICA 13, RAVNE NA KORO\u0160KEM, 2390, Slovenia Country\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 407,
//         "name": "Anja Gorenjak s.p., OPTIKA",
//         "address": "\"Malahorna 26A, Oplotnica, 2317, Slovenia Country\"",
//         "longitude": 14.5829803,
//         "latitude": 50.0036532
//     },
//     {
//         "id": 408,
//         "name": "ART OPTIKA PE KRANJ",
//         "address": "\"BLEIWEISOVA 14, KRANJ, 4000, Slovenia Country\"",
//         "longitude": -76.9758274,
//         "latitude": 39.1762439
//     },
//     {
//         "id": 409,
//         "name": "OPTIKA FERRY,",
//         "address": "\"Portoro\u017e, Obala 33, Portoro\u017e, 6320, Slovenia Country\"",
//         "longitude": 21.5687308,
//         "latitude": 48.3196929
//     },
//     {
//         "id": 410,
//         "name": "MATEJ NOVAK S.P.",
//         "address": "\"MAISTROVA 32, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": 18.6466384,
//         "latitude": 54.3520252
//     },
//     {
//         "id": 411,
//         "name": "OPTIKA24 D.O.O.",
//         "address": "\"POLJSKA ULICA 2, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": 46.7022149,
//         "latitude": 24.6764021
//     },
//     {
//         "id": 412,
//         "name": "\"OPTIKA M\" - MARKO BOSILJEVAC S.P.",
//         "address": "\"NASELJE BORISA KIDRI\u010cA 9, METLIKA, 8330, Slovenia Country\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 413,
//         "name": "OPTIKA IRMAN, D.O.O.",
//         "address": "\"SAVINJSKA CESTA 2, \u017dALEC, 3310, Slovenia Country\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 414,
//         "name": "OPTIKA CLARUS BTC LJ",
//         "address": "\"Trg mladih 9, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 415,
//         "name": "MINUS 50, OPTIKA, D.O.O.",
//         "address": "\"TR\u017dA\u0160KA 67 A, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": 15.472962,
//         "latitude": 49.817492
//     },
//     {
//         "id": 416,
//         "name": "OPTIKA VID BENDA MILENA S.P.",
//         "address": "\"CESTA BORCEV 5, RADOMLJE, 1235, Slovenia Country\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 417,
//         "name": "OPTIKA \"SIMONA\", Simona Kodrin s.p.",
//         "address": "\"RIMSKA CESTA 35, \u0160EMPETER V SAV.DOLINI, 3311, Slovenia Country\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 418,
//         "name": "OPTIKA GLAS D.O.O.",
//         "address": "\"\u0160ALE\u0160KA CESTA 19A, VELENJE, 3320, Slovenia Country\"",
//         "longitude": 30.8137581,
//         "latitude": -29.8536809
//     },
//     {
//         "id": 419,
//         "name": "ART OPTIKA PE NOVA GORICA",
//         "address": "\"KIDRI\u010cEVA 20, NOVA GORICA, 5000, Slovenia Country\"",
//         "longitude": 20.702786,
//         "latitude": 53.8671117
//     },
//     {
//         "id": 420,
//         "name": "KEBER DAMJAN S.P.",
//         "address": "\"TRDINOVA ULICA 1, BRE\u017dICE, 8250, Slovenia Country\"",
//         "longitude": -77.43,
//         "latitude": 39.01
//     },
//     {
//         "id": 421,
//         "name": "OKOVID OPTIKA D.O.O.",
//         "address": "\"FRANCETOVA 16, SLOVENJ GRADEC, 2380, Slovenia Country\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 422,
//         "name": "OPTIKA \"D\" DRAGO SEVER S.P.",
//         "address": "\"DUNAJSKA 202, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 14.5091139,
//         "latitude": 46.0621615
//     },
//     {
//         "id": 423,
//         "name": "PLANETA trgovina in storitve, d.o.o",
//         "address": "\"Gr\u0161ka ulica 13, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 424,
//         "name": "OPTIKA PETRA",
//         "address": "\"DROFENIKOVA ULICA 16, \u0160ENTJUR, 3230, Slovenia Country\"",
//         "longitude": 103.85765,
//         "latitude": 1.309114
//     },
//     {
//         "id": 425,
//         "name": "ART OPTIKA PE CELJE",
//         "address": "\"LJUBLJANSKA CESTA 6, CELJE, 3000, Slovenia Country\"",
//         "longitude": -77.4587239,
//         "latitude": 38.8193117
//     },
//     {
//         "id": 426,
//         "name": "VIDIM OPTIKA D.O.O.",
//         "address": "\"MARIBORSKA CESTA 88, CELJE, 3000, Slovenia Country\"",
//         "longitude": 8.6249564,
//         "latitude": 50.2191832
//     },
//     {
//         "id": 427,
//         "name": "OPTIKA \u0160KOFIC",
//         "address": "\"LJUBLJANSKA 87, DOM\u017dALE, 1230, Slovenia Country\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 428,
//         "name": "LJUDSKA OPTIKA GLASMAHER",
//         "address": "\"PLETER\u0160NIKOVA ULICA 1, BRE\u017dICE, 8250, Slovenia Country\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 429,
//         "name": "HERGULA DAVORIN S.P.",
//         "address": "\"PTUJSKA CESTA 13, ORMO\u017d, 2270, Slovenia Country\"",
//         "longitude": 90.356331,
//         "latitude": 23.684994
//     },
//     {
//         "id": 430,
//         "name": "OPTIKA OFTALMOS",
//         "address": "\"ZDRAVILI\u0160KI TRG 25, ROGA\u0160KA SLATINA, 3250, Slovenia Country\"",
//         "longitude": 20.1502916,
//         "latitude": 46.2845795
//     },
//     {
//         "id": 431,
//         "name": "Optika Cornea Menge\u0161",
//         "address": "\"MURNOVA ULICA 1, MENGE\u0160, 1234, Slovenia Country\"",
//         "longitude": -76.9239727,
//         "latitude": 38.8370963
//     },
//     {
//         "id": 432,
//         "name": "O\u010desna Ordinacija in Optika",
//         "address": "\"Tr\u017ea\u0161ka 495, Brezovica, 1351, Slovenia Country\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 433,
//         "name": "OPTIKA KUHAR TRGOVINA IN",
//         "address": "\"VODNIKOVA ULICA 2, PTUJ, 2250, Slovenia Country\"",
//         "longitude": -76.947022,
//         "latitude": 38.8910932
//     },
//     {
//         "id": 434,
//         "name": "OPTIKA TINA MARTINA DER\u017dEK S.P",
//         "address": "\"TRG ZBORA ODPOSLANCEV 12A, KO\u010cEVJE, 1330, Slovenia Country\"",
//         "longitude": 100.6983518,
//         "latitude": 13.7474361
//     },
//     {
//         "id": 435,
//         "name": "O\u010cESNA OPTIKA BERCE D.O.O.",
//         "address": "\"\u017dELEZNI\u0160KA 7, LESCE, 4248, Slovenia Country\"",
//         "longitude": -77.0391754,
//         "latitude": 39.0451663
//     },
//     {
//         "id": 436,
//         "name": "OPTIKA SONJA JURE\u0160 D.O.O.",
//         "address": "\"ULICA STANETA ROZMANA 13, MURSKA SOBOTA, 9000, Slovenia Country\"",
//         "longitude": 14.995463,
//         "latitude": 46.151241
//     },
//     {
//         "id": 437,
//         "name": "OPTOVID D.O.O. LJUBLJANA",
//         "address": "\"DUNAJSKA CESTA 56, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 103.852861,
//         "latitude": 1.307018
//     },
//     {
//         "id": 438,
//         "name": "OPTIKA ARENA D.O.O.",
//         "address": "\"POHORSKA ULICA 21, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": -77.015095,
//         "latitude": 39.0216936
//     },
//     {
//         "id": 439,
//         "name": "OPTIKA IBIS D.O.O. AJDOV\u0160\u010cINA",
//         "address": "\"ULICA 5. MAJA 1, AJDOV\u0160\u010cINA, 5270, Slovenia Country\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 440,
//         "name": "PRIMO\u017d RO\u017dI\u010c S.P.",
//         "address": "\"GLEDALI\u0160KA ULICA 2, CELJE, 3000, Slovenia Country\"",
//         "longitude": -76.8893907,
//         "latitude": 38.853725
//     },
//     {
//         "id": 441,
//         "name": "ART OPTIKA PE VELENJE",
//         "address": "\"PRE\u0160ERNOVA 7B, VELENJE, 3320, Slovenia Country\"",
//         "longitude": 15.1106582,
//         "latitude": 46.3622743
//     },
//     {
//         "id": 442,
//         "name": "ART OPTIKA D.O.O.",
//         "address": "\"GORI\u0160KA CESTA 78, AJDOV\u0160\u010cINA, 5270, Slovenia Country\"",
//         "longitude": -77.0391754,
//         "latitude": 39.0451663
//     },
//     {
//         "id": 443,
//         "name": "OPTIKA KAMELEON CITY",
//         "address": "\"Ulica Vita Kraigherja 5, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": 22.5941816,
//         "latitude": 51.2621626
//     },
//     {
//         "id": 444,
//         "name": "OPTIKA CLARUS KOPER - PLANET KOPER",
//         "address": "\"ANKARANSKA CESTA 2, KOPER, 6000, Slovenia Country\"",
//         "longitude": 100.6983518,
//         "latitude": 13.7474361
//     },
//     {
//         "id": 445,
//         "name": "OPTIKA CLARUS ALEJA \u0160I\u0160KA",
//         "address": "\"Raku\u0161eva ulica 1, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 22.1583022,
//         "latitude": 48.987657
//     },
//     {
//         "id": 446,
//         "name": "OPTIKA CLARUS PTUJ SUPERNOVA",
//         "address": "\"ORMO\u0160KA CESTA 15, PTUJ, 2250, Slovenia Country\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 447,
//         "name": "OPTIKA CLARUS NOVO MESTO",
//         "address": "\"OTO\u0160KA CESTA 5, NOVO MESTO, 8000, Slovenia Country\"",
//         "longitude": -77.0277275,
//         "latitude": 38.9003884
//     },
//     {
//         "id": 448,
//         "name": "OPTIKA CLARUS KOPER",
//         "address": "\"ANKARANSKA CESTA 3A, KOPER, 6000, Slovenia Country\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 449,
//         "name": "OPTIKA CLARUS CELJE",
//         "address": "\"MARIBORSKA CESTA 100, CELJE, 3000, Slovenia Country\"",
//         "longitude": -76.9815877,
//         "latitude": 38.8641775
//     },
//     {
//         "id": 450,
//         "name": "OPTIKA LANA",
//         "address": "\"PRE\u0160ERNOVA 1, SEVNICA, 8290, Slovenia Country\"",
//         "longitude": 16.8993461,
//         "latitude": 52.4049097
//     },
//     {
//         "id": 451,
//         "name": "OPTIKA CLARUS KRANJ SUPERNOVA",
//         "address": "\"Cesta 1. maja 77, KRANJ, 4000, Slovenia Country\"",
//         "longitude": -77.4816693,
//         "latitude": 38.8629803
//     },
//     {
//         "id": 452,
//         "name": "OPTIKA ALFA PLUS",
//         "address": "\"TR\u017dA\u0160KA CESTA 14, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": 17.4000185,
//         "latitude": 48.2193052
//     },
//     {
//         "id": 453,
//         "name": "OPTIKA OCULUS PRIOR, D.O.O.",
//         "address": "\"OSOJNIKOVA ULICA 17, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": -77.0406147,
//         "latitude": 38.9080786
//     },
//     {
//         "id": 454,
//         "name": "OPTIKA CLARUS RUDNIK",
//         "address": "\"Jur\u010dkova cesta 223, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 455,
//         "name": "OPTIKA CLARUS SLOVENSKA",
//         "address": "\"Tav\u010darjeva ulica 1, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": -76.8503754,
//         "latitude": 38.8813417
//     },
//     {
//         "id": 456,
//         "name": "OPTIKA KAMELEON CANKARJEVA UL.",
//         "address": "\"CANKARJEVA ULICA 11, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": -76.9066829,
//         "latitude": 38.7579835
//     },
//     {
//         "id": 457,
//         "name": "OPTIKA SONJA JURE\u0160 D.O.O.",
//         "address": "\"ORMO\u0160KA CESTA 3\/A, LJUTOMER, 9240, Slovenia Country\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 458,
//         "name": "OPTIKA IBIS D.O.O. SE\u017dANA",
//         "address": "\"PARTIZANSKA CESTA 44, SE\u017dANA, 6210, Slovenia Country\"",
//         "longitude": 19.145136,
//         "latitude": 51.919438
//     },
//     {
//         "id": 459,
//         "name": "OPTIKA KIRALY D.O.O.",
//         "address": "\"MLINSKA ULICA 7, LENDAVA, 9220, Slovenia Country\"",
//         "longitude": -76.9354979,
//         "latitude": 38.8178384
//     },
//     {
//         "id": 460,
//         "name": "OPTIKA VIVID,DIJANA NOVAK S.P.",
//         "address": "\"TROJI\u0160KI TRG 8, SV. TROJICA V SLOV.GORICAH, 2235, Slovenia Country\"",
//         "longitude": 15.8262124,
//         "latitude": 46.5834424
//     },
//     {
//         "id": 461,
//         "name": "PARTNER,",
//         "address": "\"KORO\u0160KA CESTA 14, KRANJ, 4000, Slovenia Country\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 462,
//         "name": "O\u010cESNA OPTIKA BERCE D.O.O.",
//         "address": "\"CESTA CIRILA TAV\u010cARJA 6, JESENICE, 4270, Slovenia Country\"",
//         "longitude": 14.6032241,
//         "latitude": 50.0121978
//     },
//     {
//         "id": 463,
//         "name": "GREGOR MILO\u0160I\u010c S.P.",
//         "address": "\"MAISTROVA ULICA 18, LENART V SLOV. GORICAH, 2230, Slovenia Country\"",
//         "longitude": 14.995463,
//         "latitude": 46.151241
//     },
//     {
//         "id": 464,
//         "name": "RITM d.o.o.",
//         "address": "\"CESTA PRVIH BORCEV 32, BRE\u017dICE, 8250, Slovenia Country\"",
//         "longitude": 19.503304,
//         "latitude": 47.162494
//     },
//     {
//         "id": 465,
//         "name": "OPTIKA POGLED",
//         "address": "\"CELJSKA CESTA 9, \u0160MARJE PRI JEL\u0160AH, 3240, Slovenia Country\"",
//         "longitude": 17.0355288,
//         "latitude": 51.0559344
//     },
//     {
//         "id": 466,
//         "name": "TER\u017dAN D.O.O.",
//         "address": "\"MARIBORSKA CESTA 54, CELJE, 3000, Slovenia Country\"",
//         "longitude": 50.717278,
//         "latitude": 35.821313
//     },
//     {
//         "id": 467,
//         "name": "VOGRIN ZLATARSTVO IN",
//         "address": "\"MALGAJEVA ULICA 1, RADLJE OB DRAVI, 2360, Slovenia Country\"",
//         "longitude": 17.0170015,
//         "latitude": 48.4410099
//     },
//     {
//         "id": 468,
//         "name": "INMED D.O.O. TR\u017dA\u0160KA",
//         "address": "\"TR\u017dA\u0160KA 19A, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 469,
//         "name": "OPTRIS D.O.O.",
//         "address": "\"KOTARJEVA CESTA 8A, \u0160ENTJERNEJ, 8310, Slovenia Country\"",
//         "longitude": 103.85377,
//         "latitude": 1.304117
//     },
//     {
//         "id": 470,
//         "name": "ART OPTIKA PE PTUJ",
//         "address": "\"SLOM\u0160KOVA 24, PTUJ, 2250, Slovenia Country\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 471,
//         "name": "OPTIKA OFTALMOS CELJE",
//         "address": "\"MARIBORSKA CESTA 69, CELJE, 3000, Slovenia Country\"",
//         "longitude": -76.5314817,
//         "latitude": 38.802157
//     },
//     {
//         "id": 472,
//         "name": "GOBEC, turizem, optika,",
//         "address": "\"JANINA 1B, ROGA\u0160KA SLATINA, 3250, Slovenia Country\"",
//         "longitude": 98.8583309,
//         "latitude": 3.2792483
//     },
//     {
//         "id": 473,
//         "name": "K\u0160\u0160 SPROSTITVENI CENTER",
//         "address": "\"DEVINSKA ULICA 1, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 17.0518443,
//         "latitude": 48.1591643
//     },
//     {
//         "id": 474,
//         "name": "MEDVE\u0160EK MARKO S.P.",
//         "address": "\"RADVANJSKA 99, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": -76.9066829,
//         "latitude": 38.593093
//     },
//     {
//         "id": 475,
//         "name": "OPTIKA \u0160IMENC",
//         "address": "\"TRUBARJEVA CESTA 56, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 19.4582535,
//         "latitude": 51.7605683
//     },
//     {
//         "id": 476,
//         "name": "OPTIKA MAESTRO VISION",
//         "address": "\"TR\u0160KA CESTA 002, POD\u010cETRTEK, 3254, Slovenia Country\"",
//         "longitude": -76.9815877,
//         "latitude": 38.9052763
//     },
//     {
//         "id": 477,
//         "name": "OPTIKA RO\u017dI\u010c",
//         "address": "\"CELJSKA CESTA 24B, VOJNIK, 3212, Slovenia Country\"",
//         "longitude": 15.302058,
//         "latitude": 46.2920581
//     },
//     {
//         "id": 478,
//         "name": "OPTIKA OFTALMOS Ptuj",
//         "address": "\"PUHOVA ULICA 21, PTUJ, 2250, Slovenia Country\"",
//         "longitude": 14.995463,
//         "latitude": 46.151241
//     },
//     {
//         "id": 479,
//         "name": "OPTIKA CLARUS VELENJE",
//         "address": "\"CELJSKA CESTA 40, VELENJE, 3320, Slovenia Country\"",
//         "longitude": 30.8173157,
//         "latitude": -29.8470354
//     },
//     {
//         "id": 480,
//         "name": "OPTIKA CLARUS NOVA GORICA",
//         "address": "\"Prvomajska ulica 35, NOVA GORICA, 5000, Slovenia Country\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 481,
//         "name": "VIDERE D.O.O.",
//         "address": "\"VETRINJSKA ULICA 22, MARIBOR, 2000, Slovenia Country\"",
//         "longitude": 17.1077477,
//         "latitude": 48.1485965
//     },
//     {
//         "id": 482,
//         "name": "Optika Rimc PE Ljubljana",
//         "address": "\"Vodnikova cesta 157, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 483,
//         "name": "OPTIKA CLARUS KRANJ",
//         "address": "\"NAZORJEVA ULICA 3, KRANJ, 4000, Slovenia Country\"",
//         "longitude": -76.5083562,
//         "latitude": 38.8302686
//     },
//     {
//         "id": 484,
//         "name": "OPTIKA CLARUS VRHNIKA",
//         "address": "\"LJUBLJANSKA CESTA 29, VRHNIKA, 1360, Slovenia Country\"",
//         "longitude": 14.3021032,
//         "latitude": 45.9714891
//     },
//     {
//         "id": 485,
//         "name": "OPTIKA CLARUS MURSKA SOBOTA BTC",
//         "address": "\"Nem\u010davci 1D, MURSKA SOBOTA, 9000, Slovenia Country\"",
//         "longitude": 14.995463,
//         "latitude": 46.151241
//     },
//     {
//         "id": 486,
//         "name": "OPTIKA COLATIO",
//         "address": "\"STARI TRG 27, SLOVENJ GRADEC, 2380, Slovenia Country\"",
//         "longitude": 14.995463,
//         "latitude": 46.151241
//     },
//     {
//         "id": 487,
//         "name": "OPTIKA CLARUS POSTOJNA",
//         "address": "\"TR\u017dA\u0160KA CESTA 59, POSTOJNA, 6230, Slovenia Country\"",
//         "longitude": -8.7652054,
//         "latitude": 39.3162911
//     },
//     {
//         "id": 488,
//         "name": "OPTIKA CLARUS SLOVENSKA BISTRICA",
//         "address": "\"TRG ALFONSA \u0160ARHA 10, SLOVENSKA BISTRICA, 2310, Slovenia Country\"",
//         "longitude": 103.853204,
//         "latitude": 1.305285
//     },
//     {
//         "id": 489,
//         "name": "OPTIKA CLARUS CERKNICA",
//         "address": "\"Cesta 4. maja 17, CERKNICA, 1380, Slovenia Country\"",
//         "longitude": -76.5661622,
//         "latitude": 38.7548084
//     },
//     {
//         "id": 490,
//         "name": "OPTIKA CLARUS DOM\u017dALE",
//         "address": "\"Cesta talcev 4, DOM\u017dALE, 1230, Slovenia Country\"",
//         "longitude": 103.861385,
//         "latitude": 1.311704
//     },
//     {
//         "id": 491,
//         "name": "O\u010cESNA OPTIKA KRHLIKAR",
//         "address": "\"ZADOBROV\u0160KA CESTA 18, LJUBLJANA - POLJE, 1260, Slovenia Country\"",
//         "longitude": 20.151694,
//         "latitude": 46.28504
//     },
//     {
//         "id": 492,
//         "name": "OFTALMA, D.O.O.",
//         "address": "\"ULICA BRATOV U\u010cAKAR 58, LJUBLJANA, 1000, Slovenia Country\"",
//         "longitude": 20.1502916,
//         "latitude": 46.2845795
//     }
// ]