import { useLocation } from "react-router-dom";
import queryString from "query-string";
import SingleVto from './SingleVto'
import CatalogueVto from './CatalogueVto'
import PageNotFound from "./PageNotFound";
import SelectionPage from "./IntroPage";

function RouterQuery() {
  return <SelectionPage ></SelectionPage>

}

export default RouterQuery;