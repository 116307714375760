
import { useTranslation } from 'react-i18next';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const useTranslateText = () => {
    const { t } = useTranslation();

    return (text) => t(text.toLowerCase());
};


const storage = getStorage();


function generateRandomID() {
  return Math.random().toString(36).substr(2, 9);
}
//Test
// Function to upload base64 data to Firebase Storage
const uploadBase64 = async (base64Data, fileName) => {
  try {
    const fullPath = `MirrorImage/${fileName}-${generateRandomID()}.png`;

    const fileRef = ref(storage, fullPath);
    const fileBytes = Uint8Array.from(atob(removeBase64Prefix(base64Data)), (c) => c.charCodeAt(0));
    
    await uploadBytes(fileRef, fileBytes);
    
    // Return download URL
    const downloadURL = await getDownloadURL(fileRef);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export { uploadBase64 };

function removeBase64Prefix(base64String) {
    // Find the index of the comma after "base64"
    const index = base64String.indexOf(',') + 1;
  
    // If the comma is found, return the substring starting from that index
    if (index > 0) {
      return base64String.slice(index);
    }
  
    // If the comma is not present, return the original string
    return base64String;
  }